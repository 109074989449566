let CodiQAPIEndpoint = 'https://api.' + window.location.host + '/';
 
if (window.location.host == 'release.codiq.io'){
 CodiQAPIEndpoint = 'http://api.' + window.location.host + ':52252/';
}

//const CodiQAPIEndpoint = 'https://api.' + window.location.host + '/';

export const environment = {
    version: require('../../package.json').version,
    production: true,
    language: 'en',
    title: 'CodiQ Studio',
    exportType: 'machineEdition',
    api: CodiQAPIEndpoint
};