import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
    Route,
} from '@angular/router';

import { UserService } from '@app/modules/core/services/user.service';
import { UserRole } from '@app/modules/core/interfaces/role';

@Injectable()
export class AuthGuard implements CanActivateChild, CanActivate {
    constructor(private userService: UserService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.userService.isAuthenticated && state.url.indexOf('/dashboard') !== -1) {
            this.router.navigate(['/login']);
            return false;
        }

        const roles = route.data.roles as UserRole[];
        if (roles && !roles.includes(this.userService.roleUser)) {
            this.router.navigate(['/']);
            return false;
        }

        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.userService.isAuthenticated && state.url.indexOf('/dashboard') !== -1) {
            this.router.navigate(['/login']);
            return false;
        }
        return true;
    }

    canLoad(route: Route) {
        if (!this.userService.isAuthenticated) {
            this.router.navigate(['/login']);
            return false;
        }
        return true;
    }
}
