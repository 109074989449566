import { Component, Injector, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, RouterEvent, NavigationCancel, NavigationError, NavigationEnd } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { environment } from '@src/environments/environment';
import { SVG_ICONS } from '@src/assets/svg-icons/svg-icons';
import { MatIconRegistry } from '@angular/material/icon';
import { SVG_FOLDER_ICONS } from '@src/assets/svg-icons/svg-folder-icons';
import { NotificationService } from './modules/core/services/notification.service';
import { SVG_CANVAS_ICONS } from '@src/assets/svg-icons/svg_canvas_icons';
import { UserService } from './modules/core/services/user.service';
import {
    ListenTypeTitle,
    PROGRESS_BAR_ACTION
} from '@app/modules/dashboard/enums/progress-bar-notification.enum';
import { SVG_PROJECT_ICONS } from '@src/assets/svg-icons/svg-project-icons';
import { SpinnerColor } from '@app/modules/dashboard/enums/color.enums';
import { SVG_TOOLBAR_ICONS } from '@src/assets/svg-icons/svg_toolbar_icons';
import { SVG_LIBRARY_ICONS } from '@src/assets/svg-icons/svg_library_icons';
import { FeatureFlagService } from './modules/shared/feature-flag/feature-flag.service';
import { FeatureFlagNames } from './modules/shared/feature-flag/feature-flag.interface';
import * as Sentry from '@sentry/angular-ivy';
import { SVG_FOLDER_TREE_ICONS } from "@src/assets/svg-icons/svg-folder-tree-icons";
import { take } from "rxjs";
import { SVG_GRAPHIC_SIDE_PANEL_ICONS } from '@assets/svg-icons/svg_graphic_side_panel_icons';
import { SVG_GRAPHIC_PROPERTY_PANEL_ICONS } from '@src/assets/svg-icons/svg_graphic_property_panel_icons';


export let AppInjector: Injector;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    progressNotification = '';
    progressAction = '';
    percentageValue: number;
    isLoaded = false;
    rightSideLogo;
    leftSideLogo;
    constructor(
        private readonly spinner: NgxSpinnerService,
        private readonly router: Router,
        private readonly titleService: Title,
        private readonly iconRegistry: MatIconRegistry,
        private readonly sanitizer: DomSanitizer,
        private readonly injector: Injector,
        public readonly notificationService: NotificationService,
        private readonly userService: UserService,
        private readonly featureFlagsService: FeatureFlagService
    ) {
        AppInjector = this.injector;
        this.addCustomIcon();
        this.initMonitoring();
        router.events.subscribe((event: RouterEvent) => {
            this.resetProgressValues();
            if (
                event instanceof NavigationError ||
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel
            ) {
                spinner.hide();
            }
        });
    }

    ngOnInit() {
        this.spinner.show();
        const title = environment.title;
        this.titleService.setTitle(title);
        this.resetProgressValues();
        this.connectToNotificationService();
        this.rightSideLogo = this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.progress_bar_logo_right);
        this.leftSideLogo = this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.progress_bar_logo_left);
    }

    ngAfterContentChecked() {
        this.isLoaded = this.notificationService.isStartNotification;
    }

    private addCustomIcon() {
        this.iconRegistry.addSvgIconLiteral('add', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.add));
        this.iconRegistry.addSvgIconLiteral('edit', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.edit));
        this.iconRegistry.addSvgIconLiteral('delete', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.delete));
        this.iconRegistry.addSvgIconLiteral('copy', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.copy));
        this.iconRegistry.addSvgIconLiteral('move', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.move));
        this.iconRegistry.addSvgIconLiteral('rename', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.rename));
        this.iconRegistry.addSvgIconLiteral('search', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.search));
        this.iconRegistry.addSvgIconLiteral(
            'export-file',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.export_file)
        );
        this.iconRegistry.addSvgIconLiteral('dots', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.dots));
        this.iconRegistry.addSvgIconLiteral('chevron', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.chevron));
        this.iconRegistry.addSvgIconLiteral(
            'close_window',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.close_window)
        );
        this.iconRegistry.addSvgIconLiteral('close', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.close_button));
        this.iconRegistry.addSvgIconLiteral(
            'close_popup',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.close_popup)
        );
        this.iconRegistry.addSvgIconLiteral('upload_dwg', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.upload_dwg));
        this.iconRegistry.addSvgIconLiteral(
            'close_white',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.close_white)
        );
        this.iconRegistry.addSvgIconLiteral(
            'mat_select_arrow',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.mat_select_arrow)
        );
        this.iconRegistry.addSvgIconLiteral('list', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.list));
        this.iconRegistry.addSvgIconLiteral(
            'projects_navigation',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.projects_navigation)
        );
        this.iconRegistry.addSvgIconLiteral('eye_off', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.eye_off));
        this.iconRegistry.addSvgIconLiteral('eye', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.eye));
        this.iconRegistry.addSvgIconLiteral('folder', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.folder));
        this.iconRegistry.addSvgIconLiteral(
            'format_list',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.format_list)
        );
        this.iconRegistry.addSvgIconLiteral(
            'rotate_right',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.rotate_right)
        );
        this.iconRegistry.addSvgIconLiteral('clock', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.clock));
        this.iconRegistry.addSvgIconLiteral(
            'file_output',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.file_output)
        );
        this.iconRegistry.addSvgIconLiteral(
            'rotate_left',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.rotate_left)
        );
        this.iconRegistry.addSvgIconLiteral('import', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.import));
        this.iconRegistry.addSvgIconLiteral('wrench', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.wrench));
        this.iconRegistry.addSvgIconLiteral(
            'project_settings',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.project_settings)
        );
        this.iconRegistry.addSvgIconLiteral('go_to', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.go_to));
        this.iconRegistry.addSvgIconLiteral('go_back', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.go_back));
        this.iconRegistry.addSvgIconLiteral('image', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.image));
        this.iconRegistry.addSvgIconLiteral('refresh', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.refresh));
        this.iconRegistry.addSvgIconLiteral(
            'plus-square',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.plus_square)
        );
        this.iconRegistry.addSvgIconLiteral('label', this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_ICONS.label));
        this.iconRegistry.addSvgIconLiteral('x-square', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.x_square));
        this.iconRegistry.addSvgIconLiteral('alert', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.alert));
        this.iconRegistry.addSvgIconLiteral(
            'export-project',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.export_project)
        );
        this.iconRegistry.addSvgIconLiteral('fit-screen', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.fit_screen));
        this.iconRegistry.addSvgIconLiteral(
            'clear-diagram',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.clear_diagram)
        );
        this.iconRegistry.addSvgIconLiteral('point', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.point));
        this.iconRegistry.addSvgIconLiteral('shared', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.shared));
        this.iconRegistry.addSvgIconLiteral('david_star', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.david_star));
        this.iconRegistry.addSvgIconLiteral('star', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.star));
        this.iconRegistry.addSvgIconLiteral(
            'plus_folder',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.plus_folder)
        );
        this.iconRegistry.addSvgIconLiteral('file_text', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.file_text));
        this.iconRegistry.addSvgIconLiteral('arrow_down', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.arrow_down));
        this.iconRegistry.addSvgIconLiteral('filter', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.filter));
        this.iconRegistry.addSvgIconLiteral(
            'close-filter',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.close_filter)
        );
        this.iconRegistry.addSvgIconLiteral('monitor', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.monitor));
        this.iconRegistry.addSvgIconLiteral('undo', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.undo));
        this.iconRegistry.addSvgIconLiteral('redo', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.redo));
        this.iconRegistry.addSvgIconLiteral('eye_big', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.eye_big));
        this.iconRegistry.addSvgIconLiteral('parameter', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.parameter));
        this.iconRegistry.addSvgIconLiteral('valve', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.valve));
        this.iconRegistry.addSvgIconLiteral('plc', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.plc));
        this.iconRegistry.addSvgIconLiteral('collapse', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.collapse));
        this.iconRegistry.addSvgIconLiteral('expand', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.expand));
        this.iconRegistry.addSvgIconLiteral(
            'library_settings',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.library_settings)
        );
        this.iconRegistry.addSvgIconLiteral(
            'filter-list',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.filter_list)
        );
        this.iconRegistry.addSvgIconLiteral('transfer', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.transfer));
        this.iconRegistry.addSvgIconLiteral('stripes_filter', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.stripes_filter));
        this.iconRegistry.addSvgIconLiteral('check_active', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.check_active));
        this.iconRegistry.addSvgIconLiteral('eraser', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.eraser));
        this.iconRegistry.addSvgIconLiteral('left', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.left));
        this.iconRegistry.addSvgIconLiteral('right', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.right));
        this.iconRegistry.addSvgIconLiteral('up', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.up));
        this.iconRegistry.addSvgIconLiteral('down', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.down));
        this.iconRegistry.addSvgIconLiteral('data-base', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.data_base));
        this.iconRegistry.addSvgIconLiteral(
            'flow-direction',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.flow_direction)
        );
        this.iconRegistry.addSvgIconLiteral(
            'add-ot-param-set',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.add_ot_param_set)
        );
        this.iconRegistry.addSvgIconLiteral(
            'save-ot-param-set',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.save_ot_param_set)
        );
        this.iconRegistry.addSvgIconLiteral('error', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.error));
        this.iconRegistry.addSvgIconLiteral('info', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.info));
        this.iconRegistry.addSvgIconLiteral('done', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.done));
        this.iconRegistry.addSvgIconLiteral('done_35', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.done_35));
        this.iconRegistry.addSvgIconLiteral(
            'show_details',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.show_details)
        );
        this.iconRegistry.addSvgIconLiteral(
            'hide_details',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.hide_details)
        );
        this.iconRegistry.addSvgIconLiteral('checked', this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.checked));
        this.iconRegistry.addSvgIconLiteral(
            'transparent-box',
            this.sanitizer.bypassSecurityTrustHtml(SVG_ICONS.transparent_box)
        );

        this.iconRegistry.addSvgIconLiteral(
            'folder_open',
            this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_ICONS.folder_open)
        );
        this.iconRegistry.addSvgIconLiteral(
            'sync_to_base_red',
            this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_ICONS.sync_to_base_red)
        );
        this.iconRegistry.addSvgIconLiteral(
            'upload_red',
            this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_ICONS.upload_red)
        );
        this.iconRegistry.addSvgIconLiteral(
            SVG_FOLDER_TREE_ICONS.library_project_only_component.name,
            this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_TREE_ICONS.library_project_only_component.url)
        );
        this.iconRegistry.addSvgIconLiteral(
            SVG_FOLDER_TREE_ICONS.library_modified_component.name,
            this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_TREE_ICONS.library_modified_component.url)
        );
        this.iconRegistry.addSvgIconLiteral('file', this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_ICONS.file));
        this.iconRegistry.addSvgIconLiteral('grid', this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_ICONS.grid));
        this.iconRegistry.addSvgIconLiteral(
            'device_hub',
            this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_ICONS.device_hub)
        );
        this.iconRegistry.addSvgIconLiteral(
            'linear_scale',
            this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_ICONS.linear_scale)
        );

        this.iconRegistry.addSvgIconLiteral('linked_Element', this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_ICONS.linked_Element));

        this.iconRegistry.addSvgIconLiteral(
            'description',
            this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_ICONS.description)
        );
        this.iconRegistry.addSvgIconLiteral('layers', this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_ICONS.layers));
        this.iconRegistry.addSvgIconLiteral('route', this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_ICONS.route));
        this.iconRegistry.addSvgIconLiteral('dns', this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_ICONS.dns));
        this.iconRegistry.addSvgIconLiteral(
            'blur_linear',
            this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_ICONS.blur_linear)
        );
        this.iconRegistry.addSvgIconLiteral(
            'assignment',
            this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_ICONS.assignment)
        );
        this.iconRegistry.addSvgIconLiteral('style', this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_ICONS.style));
        this.iconRegistry.addSvgIconLiteral(
            'view_quilt',
            this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_ICONS.view_quilt)
        );

        this.iconRegistry.addSvgIconLiteral(
            'folder_close',
            this.sanitizer.bypassSecurityTrustHtml(SVG_FOLDER_ICONS.folder_close)
        );

        //canvas toolbar icons
        Object.values(SVG_CANVAS_ICONS).forEach(v => {
            this.iconRegistry.addSvgIcon(v.name, this.sanitizer.bypassSecurityTrustResourceUrl(v.url));
        });

        //toolbar
        Object.values(SVG_TOOLBAR_ICONS).forEach(v => {
            this.iconRegistry.addSvgIcon(v.name, this.sanitizer.bypassSecurityTrustResourceUrl(v.url));
        });

        //project icons
        Object.values(SVG_PROJECT_ICONS).forEach(v => {
            this.iconRegistry.addSvgIcon(v.name, this.sanitizer.bypassSecurityTrustResourceUrl(v.url));
        });

        //library icons
        Object.values(SVG_LIBRARY_ICONS).forEach(v => {
            this.iconRegistry.addSvgIcon(v.name, this.sanitizer.bypassSecurityTrustResourceUrl(v.url));
        });

        //folder-tree icons
        Object.values(SVG_FOLDER_TREE_ICONS).forEach(v => {
            this.iconRegistry.addSvgIcon(v.name, this.sanitizer.bypassSecurityTrustResourceUrl(v.url));
        });

        //graphic side panel icons
        Object.values(SVG_GRAPHIC_SIDE_PANEL_ICONS).forEach(v => {
            this.iconRegistry.addSvgIcon(v.name, this.sanitizer.bypassSecurityTrustResourceUrl(v.url));
        });

        //graphic side panel icons
        Object.values(SVG_GRAPHIC_PROPERTY_PANEL_ICONS).forEach(v => {
            this.iconRegistry.addSvgIcon(v.name, this.sanitizer.bypassSecurityTrustResourceUrl(v.url));
        });


    }

    private connectToNotificationService() {
        this.notificationService.connect();
        this.notificationService.connectionEstablished.subscribe(data => {
            if (data.destinationUserId === this.userService.userId && data.listenerType !== ListenTypeTitle.ListenExportProgress) {
                this.progressNotification = data.message;
                this.progressAction = this.setNotificationAction(data.listenerType);
                this.percentageValue = Math.round((100 * data.step) / data.stepsCount);
                if (data.isFinished) {
                    this.resetProgressValues();
                    this.spinner.hide();
                    this.notificationService.startNotification(false);
                    this.isLoaded = false;
                }
            } else {
                this.progressNotification = data.message;
                this.progressAction = data.listenerType;
            }
        });
    }

    setColorToSpinner() {
        return this.isLoaded ? SpinnerColor.Invisible : SpinnerColor.Default;
    }

    resetProgressValues() {
        this.progressNotification = '';
        this.progressAction = '';
        this.percentageValue = null;
    }

    setNotificationAction(listenerType): string {
        let action = '';
        Object.keys(PROGRESS_BAR_ACTION).forEach(key => {
            if (PROGRESS_BAR_ACTION[key] === listenerType) {
                action = key;
            }
        });

        return action;
    }

    private initMonitoring() {
        this.featureFlagsService.getFeatureFlags().pipe(take(1)).subscribe(() => {
            const sentryEnabled = this.featureFlagsService.isFeatureFlagEnabled(FeatureFlagNames.sentryMonitoring);
            console.log(`Sentry monitoring enabled = ${sentryEnabled}`);
            if (sentryEnabled) {
                Sentry.init({
                    dsn: 'https://5e0a8d45d5884e059b2fdd1b851eda8a@o4504768946438144.ingest.sentry.io/4504769285521408',
                    integrations: integrations => {
                        const customIntegrations = [Sentry.browserTracingIntegration()];
                        const newIntegrations = integrations.concat(customIntegrations);
                        return newIntegrations;
                    },

                    // Set tracesSampleRate to 1.0 to capture 100%
                    // of transactions for performance monitoring.
                    // We recommend adjusting this value in production
                    tracesSampleRate: 1.0,

                    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                    tracePropagationTargets: ['localhost', /^https:\/\/api\.[a-zA-Z0-9-]+\.codiq\.io\//],

                    // Capture Replay for 20% of all sessions,
                    // plus for 100% of sessions with an error
                    replaysSessionSampleRate: 0.2,
                    replaysOnErrorSampleRate: 1.0,
                });
            }
        });
    }
}
