import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { UserService } from '@app/modules/core/services/user.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private userService: UserService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authToken = this.userService.getAuthTokenFromStorage();

        if (!window.navigator.onLine) {
            throwError(
                new HttpErrorResponse({
                    error: 'Service Unavailable. Could you check your Internet connection.',
                    status: 503,
                    url: null,
                })
            );
        }

        if (window.location.pathname.includes('login')) {
            sessionStorage.setItem('projectId', '');
        }
        const projectId = sessionStorage.getItem('projectId') ?? '';
        const sessionId = this.getSessionId();

        req = req.clone({
            headers: req.headers
                .set('projectId', projectId)
                .set('sessionId', sessionId)
                .set('Authorization', `Bearer ${authToken}`),
            withCredentials: true,
        });

        return next.handle(req);
    }

    private getSessionId(): string {
        let sessionId = sessionStorage.getItem('sessionId');
        if (sessionId == null) {
            sessionId = Math.random().toString(36).substr(2);
            sessionStorage.setItem('sessionId', sessionId);
        }

        return sessionId;
    }
}
