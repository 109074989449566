import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Class, LayoutExecutor, License } from 'yfiles';
import licenseData from '../libs/yfiles/lib-dev/yWorks.yFilesHTML.DevelopmentLicense.json';
import prodLicenseData from '../libs/yfiles/lib/yWorks.yFilesHTML.License.json';
const license = environment.production ? prodLicenseData : licenseData;

License.value = license;
Class.ensure(LayoutExecutor);
if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
