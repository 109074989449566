export enum SpinnerColor {
    Default = '#F58345',
    Invisible = 'transparent'
}

export enum RoutesTableRowsColor {
    DefaultRowColor = '#F9FAFC',
    ProportionalCMRowColor = '#FFFBED'
}

