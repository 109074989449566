export const SVG_CANVAS_ICONS = {
    add_element: { url: 'assets/image/icons/canvas/add_element.svg', name: 'cvas_add_element' },
    add_eq_ot: { url: 'assets/image/icons/canvas/add_eq_ot.svg', name: 'cvas_add_eq_ot' },
    add_image: { url: 'assets/image/icons/canvas/add_image.svg', name: 'cvas_add_image' },
    add_ot: { url: 'assets/image/icons/canvas/add_ot.svg', name: 'cvas_add_ot' },
    add_route_criteria: { url: 'assets/image/icons/canvas/add_route_criteria.svg', name: 'cvas_add_route_criteria' },
    add_text: { url: 'assets/image/icons/canvas/add_text.svg', name: 'cvas_add_text' },
    align_bottom: { url: 'assets/image/icons/canvas/align_bottom.svg', name: 'cvas_align_bottom' },
    align_horizontally: { url: 'assets/image/icons/canvas/align_horizontally.svg', name: 'cvas_align_horizontally' },
    align_left: { url: 'assets/image/icons/canvas/align_left.svg', name: 'cvas_align_left' },
    align_right: { url: 'assets/image/icons/canvas/align_right.svg', name: 'cvas_align_right' },
    align_up: { url: 'assets/image/icons/canvas/align_up.svg', name: 'cvas_align_up' },
    align_verticaly: { url: 'assets/image/icons/canvas/align_verticaly.svg', name: 'cvas_align_verticaly' },
    arrow: { url: 'assets/image/icons/canvas/arrow.svg', name: 'cvas_arrow' },
    bring_to_front: { url: 'assets/image/icons/canvas/bring_to_front.svg', name: 'cvas_bring_to_front' },
    chevron_right: { url: 'assets/image/icons/canvas/chevron-right.svg', name: 'cvas_chevron_right' },
    connection_point: { url: 'assets/image/icons/canvas/connection_point.svg', name: 'cvas_connection_point' },
    distribute_horizontally: {
        url: 'assets/image/icons/canvas/distribute_horizontally.svg',
        name: 'cvas_distribute_horizontally',
    },
    distribute_verticaly: {
        url: 'assets/image/icons/canvas/distribute_verticaly.svg',
        name: 'cvas_distribute_verticaly',
    },
    ellipse: { url: 'assets/image/icons/canvas/ellipse.svg', name: 'cvas_ellipse' },
    end_point: { url: 'assets/image/icons/canvas/end_point.svg', name: 'cvas_end_point' },
    expand: { url: 'assets/image/icons/canvas/expand.svg', name: 'cvas_expand' },
    eye: { url: 'assets/image/icons/canvas/eye.svg', name: 'cvas_eye' },
    factory_symbols: { url: 'assets/image/icons/canvas/factory_symbols.svg', name: 'cvas_factory_symbols' },
    flow_direction: { url: 'assets/image/icons/canvas/flow_direction.svg', name: 'cvas_flow_direction' },
    frame: { url: 'assets/image/icons/canvas/frame.svg', name: 'cvas_frame' },
    goto_page: { url: 'assets/image/icons/canvas/goto_page.svg', name: 'cvas_goto_page' },
    grid_decrease: { url: 'assets/image/icons/canvas/grid_decrease.svg', name: 'cvas_grid_decrease' },
    grid_increase: { url: 'assets/image/icons/canvas/grid_increase.svg', name: 'cvas_grid_increase' },
    grid: { url: 'assets/image/icons/canvas/grid.svg', name: 'cvas_grid' },
    group: { url: 'assets/image/icons/canvas/group.svg', name: 'cvas_group' },
    hand_tool: { url: 'assets/image/icons/canvas/hand_tool.svg', name: 'cvas_hand_tool' },
    help_info: { url: 'assets/image/icons/canvas/help-info.svg', name: 'cvas_help_info' },
    line_manual: { url: 'assets/image/icons/canvas/line_manual.svg', name: 'cvas_line_manual' },
    line_optimized: { url: 'assets/image/icons/canvas/line_optimized.svg', name: 'cvas_line_optimized' },
    line_orthogonal: { url: 'assets/image/icons/canvas/line_orthogonal.svg', name: 'cvas_line_orthogonal' },
    line: { url: 'assets/image/icons/canvas/line.svg', name: 'cvas_line' },
    parallelogram: { url: 'assets/image/icons/canvas/parallelogram.svg', name: 'cvas_parallelogram' },
    pen_tool_drop: { url: 'assets/image/icons/canvas/pen_tool_drop.svg', name: 'cvas_pen_tool_drop' },
    pen_tool: { url: 'assets/image/icons/canvas/pen_tool.svg', name: 'cvas_pen_tool' },
    polygon: { url: 'assets/image/icons/canvas/polygon.svg', name: 'cvas_polygon' },
    properties: { url: 'assets/image/icons/canvas/properties.svg', name: 'cvas_properties' },
    rearrange: { url: 'assets/image/icons/canvas/rearrange.svg', name: 'cvas_rearrange' },
    rectangle_round: { url: 'assets/image/icons/canvas/rectangle_round.svg', name: 'cvas_rectangle_round' },
    rectangle: { url: 'assets/image/icons/canvas/rectangle.svg', name: 'cvas_rectangle' },
    redo: { url: 'assets/image/icons/canvas/redo.svg', name: 'cvas_redo' },
    reroute: { url: 'assets/image/icons/canvas/reroute.svg', name: 'cvas_reroute' },
    save: { url: 'assets/image/icons/canvas/save.svg', name: 'cvas_save' },
    search: { url: 'assets/image/icons/canvas/search.svg', name: 'cvas_search' },
    select_tool: { url: 'assets/image/icons/canvas/select_tool.svg', name: 'cvas_select_tool' },
    send_to_back: { url: 'assets/image/icons/canvas/send_to_back.svg', name: 'cvas_send_to_back' },
    show_ot: { url: 'assets/image/icons/canvas/show_ot.svg', name: 'cvas_show_ot' },
    show_routes: { url: 'assets/image/icons/canvas/show_routes.svg', name: 'cvas_show_routes' },
    star: { url: 'assets/image/icons/canvas/star.svg', name: 'cvas_star' },
    start_point: { url: 'assets/image/icons/canvas/start_point.svg', name: 'cvas_start_point' },
    trapez: { url: 'assets/image/icons/canvas/trapez.svg', name: 'cvas_trapez' },
    ungroup: { url: 'assets/image/icons/canvas/ungroup.svg', name: 'cvas_ungroup' },
    import: { url: 'assets/image/icons/canvas/import.svg', name: 'import' },
    undo: { url: 'assets/image/icons/canvas/undo.svg', name: 'cvas_undo' },
    zoomin: { url: 'assets/image/icons/canvas/zoomin.svg', name: 'cvas_zoomin' },
    zoomout: { url: 'assets/image/icons/canvas/zoomout.svg', name: 'cvas_zoomout' },
    verification_failed: { url: 'assets/image/icons/verification-failed.svg', name: 'cvas_verification_failed' },
    verification_note: { url: 'assets/image/icons/verification-note.svg', name: 'cvas_verification_note' },
    verification_start_in_dialog: {
        url: 'assets/image/icons/verification-start-in-dialog.svg',
        name: 'cvas_verification_start_in_dialog',
    },
    eye_open: { url: 'assets/image/icons/eye-open.svg', name: 'cvas_eye_open' },
    eye_close: { url: 'assets/image/icons/eye-close.svg', name: 'cvas_eye_close' },
};
