import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AuthGuard } from '@app/guards/auth.guard';

const routingConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always'
};

const routes: Routes = [
    { path: '', redirectTo: 'dashboard/entry', pathMatch: 'full' },
    { path: 'login', loadComponent: () => import('@app/pages/login/login-page.component').then(m => m.LoginPageComponent) },
    { path: 'registration', loadComponent: () => import('@app/pages/registration/registration-page.component').then(m => m.RegistrationPageComponent) },
    { path: 'forgot', loadComponent: () => import('@app/pages/forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent) },
    { path: 'reset', loadComponent: () => import('@app/pages/reset-password/reset-password.component').then(m => m.ResetPasswordComponent) },
    { path: 'svg-viewer', loadComponent: () => import('./modules/dashboard/graphic-page-module/components/graphic-side-panel/pid-panel/svg-viewer/svg-viewer.component').then(m => m.SvgViewerComponent) },
    {
        path: 'dashboard',
        loadChildren: () => import('@app/modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
    },
    {
        path: 'recipes',
        loadChildren: () =>
            import('@app/modules/recipe-definition/recipe-definition.module').then(m => m.RecipeDefinitionModule),
    },
    { path: '**', redirectTo: 'dashboard/entry', pathMatch: 'full' },
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forRoot(routes, routingConfiguration)],
    exports: [RouterModule],
    providers: [AuthGuard],
})
export class AppRoutingModule { }
