import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable()
export class AppConfig {

  static translations: any = {};

  constructor(private http: HttpClient) { }

  load() {
    return new Promise<{}>((resolve, reject) => {
        const currentLanguage = environment.language || 'en';
        const langPath = `assets/i18n/${currentLanguage.toLowerCase()}.json`;
        this.http.get<{}>(langPath).subscribe(
            translation => {
                AppConfig.translations = Object.assign({}, translation || {});
                resolve(AppConfig.translations);
            },
            (error) => {
                AppConfig.translations = {};
                resolve(AppConfig.translations);
            }
        );
    });
  }
}
