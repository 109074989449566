export enum ListenType {
    ProjectClone = 'CloneBackupRestore',
    ExportProgress = 'ExportProgress'
}

export const PROGRESS_BAR_ACTION = {
    'Cloning project': ListenType.ProjectClone,
    'Export progress': ListenType.ExportProgress
}

export enum ListenTypeTitle {
    ListenProjectClone = 'Cloning project',
    ListenExportProgress = 'Export progress'
}

