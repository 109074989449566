import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@src/environments/environment';
import { BehaviorSubject, Observable, of, take, tap } from 'rxjs';
import {
    VerificationMessage,
    VerificationStatus,
} from '@app/modules/dashboard/components/popup/verification-results/verification.interface';
import {
    VERIFICATION_MESSAGE_KEY,
    CURRENT_PROJECT_ID,
} from '@app/modules/dashboard/components/popup/verification-results/constans/verificationMessage';
import { VerificationStatuses } from '@app/modules/dashboard/components/popup/verification-results/constans/verificationStatuses';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class VerificationService {
    private api = environment.api + 'verification/';
    private isExportAllowedOnErrors = false;

    verificationStatus$: BehaviorSubject<VerificationStatus> = new BehaviorSubject<VerificationStatus>(null);

    constructor(private readonly http: HttpClient) {}

    getVerificationStatus(projectId: string): Observable<VerificationStatus> {
        const params = {
            projectId: projectId,
        };

        this.http
            .get<VerificationStatus>(this.api + 'status/', { params })
            .pipe(
                tap((status: VerificationStatus) => {
                    this.isExportAllowedOnErrors = status.isExportAllowedOnErrors;
                    sessionStorage.setItem(CURRENT_PROJECT_ID, JSON.stringify(projectId));
                    this.verificationStatus$.next(status);
                }),
                take(1),
                catchError(err => {
                    return of(err);
                })
            )
            .subscribe();

        return this.verificationStatus$.asObservable();
    }

    getVerificationResults(
        projectId: string,
        verificationStatusCorrelation: string
    ): Observable<VerificationMessage[]> {
        const params = {
            projectId: projectId,
        };

        return this.http.get<VerificationMessage[]>(this.api + 'results/', { params }).pipe(
            tap((messages: VerificationMessage[]) =>
                localStorage.setItem(
                    VERIFICATION_MESSAGE_KEY + projectId,
                    JSON.stringify({
                        correlation: verificationStatusCorrelation,
                        data: messages,
                    })
                )
            ),
            catchError(err => {
                return of(err);
            })
        );
    }

    verificationTrigger(projectId: string, key: string, entities: [] = []): Observable<VerificationMessage[]> {
        const body = {
            projectId: projectId,
            entities: entities,
        };

        return this.http.post<VerificationMessage[]>(this.api + 'trigger/', body).pipe(
            tap(() => {
                localStorage.removeItem(key);
            })
        );
    }

    verificationStart(projectId: string, correlation: string, startTime: Date) {
        if (this.isDiffProject(projectId)) {
            return;
        }

        const verificationStatus: VerificationStatus = {
            projectId,
            correlation,
            isExportAllowedOnErrors: this.isExportAllowedOnErrors,
            startTime,
            endTime: null,
            errorCount: null,
            status: VerificationStatuses.InProgress,
        };

        this.verificationStatus$.next(verificationStatus);
    }

    verificationFinish(projectId: string, correlation: string, startTime: Date, endTime: Date, errorCount: number) {
        if (this.isDiffProject(projectId)) {
            return;
        }

        const verificationStatus: VerificationStatus = {
            projectId,
            correlation,
            isExportAllowedOnErrors: this.isExportAllowedOnErrors,
            startTime,
            endTime,
            errorCount,
            status: VerificationStatuses.Finished,
        };
        this.verificationStatus$.next(verificationStatus);

        if (errorCount && projectId) {
            this.getVerificationResults(projectId, correlation).pipe(take(1)).subscribe();
        }
    }

    verificationFailed(
        projectId: string,
        correlation: string,
        startTime: Date,
        endTime: Date,
        errorCount: number,
        errorMessage: string
    ) {
        const verificationStatus: VerificationStatus = {
            projectId,
            correlation,
            isExportAllowedOnErrors: this.isExportAllowedOnErrors,
            startTime,
            endTime,
            errorCount,
            status: VerificationStatuses.Faulted,
        };
        this.verificationStatus$.next(verificationStatus);
        console.error(errorMessage);
    }

    private isDiffProject(projectId): boolean {
        const currentProjectId = JSON.parse(sessionStorage.getItem(CURRENT_PROJECT_ID));

        return currentProjectId !== projectId;
    }
}
