import {Injectable} from '@angular/core';
import {environment} from '@src/environments/environment';
import { Subject } from 'rxjs';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import {VerificationService} from '@app/modules/dashboard/components/popup/verification-results/verification.service';
import {ListenType, ListenTypeTitle} from "@app/modules/dashboard/enums/progress-bar-notification.enum";

@Injectable()
export class NotificationService {
    private api = environment.api + 'app-notifications';

    private connection: HubConnection;
    public connectionEstablished = new Subject<any>();
    isStartNotification: boolean;

    constructor(private readonly verificationService: VerificationService) {}

    connect() {
        if (!this.connection) {
            this.connection = new HubConnectionBuilder().withUrl(this.api).build();

            this.connection.start().then(() => {
                console.warn('NotificationService started', this.connection);
            });

            this.connection.on('changed', (actionType, objectType, parentId, obj) => {

                console.log({
                    "Action": actionType,
                    "ObjectType": objectType,
                    "ParentId": parentId,
                });
                console.log(obj, 'change');
            });

            this.connection.on('application', (type, message) => {
                this.connectionEstablished.next({
                    type,
                    message,
                });
            });

            this.connection.on('SendNotification', (type, message) => {
                this.connectionEstablished.next({
                    type,
                    message,
                });
            });
            this.connection.on('verificationStarted', (projectId: string, correlation: string, startTime: Date) => {
                this.verificationService.verificationStart(projectId, correlation, startTime);
            });
            this.connection.on(
                'verificationFinished',
                (projectId: string, correlation: string, startTime: Date, endTime: Date, errorCount: number) => {
                    this.verificationService.verificationFinish(projectId, correlation, startTime, endTime, errorCount);
                }
            );
            this.connection.on(
                'verificationError',
                (
                    projectId: string,
                    correlation: string,
                    startTime: Date,
                    endTime: Date,
                    errorCount: number,
                    errorMessage: string
                ) => {
                    this.verificationService.verificationFailed(
                        projectId,
                        correlation,
                        startTime,
                        endTime,
                        errorCount,
                        errorMessage
                    );
                }
            );

            if (
                this.connection.state === HubConnectionState.Disconnected ||
                this.connection.state === HubConnectionState.Disconnecting
            ) {
                this.connection
                    .start()
                    .then(() => {
                        console.log('Hub connection started');
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    .catch(() => {
                        console.log('Error while establishing connection');
                    });
            }
        }
    }

    addListener(listenerType: string, timeoutValue?: number) {
        if (listenerType === ListenType.ExportProgress){
            this.connection.on(listenerType, (projectId, message) => {
                this.connectionEstablished.next({
                    projectId,
                    message,
                    listenerType: ListenTypeTitle.ListenExportProgress
                });
            });
        } else {
            setTimeout(() => {
                this.connection.on(listenerType, data => {
                    data.listenerType = listenerType;
                    this.connectionEstablished.next(data);
                });
            }, timeoutValue ?? 0);
        }

    }

    startNotification(started: boolean) {
        this.isStartNotification = started;
    }
}
