import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
    ],
    declarations: [],
    providers: [{
        provide: MAT_DIALOG_DEFAULT_OPTIONS,
        useValue: {
            autoFocus: false,
            panelClass: 'my-forms-dialog-class'
        }
    }],
})
export class SharedModule {}
