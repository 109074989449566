export const SVG_ICONS = {
    projects_navigation: `
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.6665 7.99996C6.6665 7.26358 7.26346 6.66663
        7.99984 6.66663C8.73622 6.66663 9.33317 7.26358 9.33317 7.99996C9.33317 8.73634 8.73622 9.33329
        7.99984 9.33329C7.26346 9.33329 6.6665 8.73634 6.6665 7.99996Z" fill="white" fill-opacity="0.8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.6665 3.33333C6.6665 2.59695 7.26346 2 7.99984
        2C8.73622 2 9.33317 2.59695 9.33317 3.33333C9.33317 4.06971 8.73622 4.66667 7.99984 4.66667C7.26346
        4.66667 6.6665 4.06971 6.6665 3.33333Z" fill="white" fill-opacity="0.8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.6665 12.6667C6.6665 11.9303 7.26346
        11.3334 7.99984 11.3334C8.73622 11.3334 9.33317 11.9303 9.33317 12.6667C9.33317 13.4031 8.73622
        14 7.99984 14C7.26346 14 6.6665 13.4031 6.6665 12.6667Z" fill="white" fill-opacity="0.8"/>
        </svg>
    `,
    add: `
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 3.33337V12.6667" stroke="currentColor" stroke-opacity="0.8" stroke-width="1.16667" stroke-linejoin="round"/>
        <path d="M3.3335 8H12.6668" stroke="currentColor" stroke-opacity="0.8" stroke-width="1.16667" stroke-linejoin="round"/>
        </svg>
        `,
    edit: `
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1766 2.11434C11.0354 2.11434 10.8955 2.14587 10.7651 2.20713C10.6346
        2.26838 10.516 2.35817 10.4161 2.47136L2.58539 11.3462L2.01499 13.7165L4.10644 13.07L11.9372 4.19522C12.037 4.08203 12.1163
        3.94765 12.1703 3.79976C12.2244 3.65187 12.2522 3.49337 12.2522 3.33329C12.2522 3.17321 12.2244 3.01471 12.1703 2.86682C12.1163
        2.71893 12.037 2.58455 11.9372 2.47136C11.8373 2.35817 11.7187 2.26838 11.5882 2.20713C11.4578 2.14587 11.3179 2.11434 11.1766
        2.11434ZM10.3148 0.975287C10.5881 0.847022 10.8809 0.781006 11.1766 0.781006C11.4724 0.781006 11.7652 0.847023 12.0385
        0.975287C12.3117 1.10355 12.5599 1.29155 12.7691 1.52855C12.9782 1.76555 13.1441 2.04692 13.2572 2.35657C13.3704 2.66623
        13.4287 2.99812 13.4287 3.33329C13.4287 3.66846 13.3704 4.00035 13.2572 4.31001C13.1441 4.61967 12.9782 4.90103 12.7691
        5.13803L4.82788 14.138C4.75549 14.2201 4.66547 14.2793 4.5667 14.3098L1.3314 15.3098C1.12775 15.3727 0.909948 15.3072
        0.760683 15.138C0.611418 14.9689 0.553578 14.722 0.60912 14.4912L1.49147 10.8245C1.51841 10.7126 1.57065 10.6106 1.64304
        10.5286L9.58423 1.52855C9.79335 1.29155 10.0416 1.10355 10.3148 0.975287Z" fill="currentColor"/>
        </svg>
        `,
    delete: `
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.1665 3.49996C1.1665 3.17779 1.42767 2.91663 1.74984 2.91663H12.2498C12.572
        2.91663 12.8332 3.17779 12.8332 3.49996C12.8332 3.82213 12.572 4.08329 12.2498 4.08329H1.74984C1.42767 4.08329 1.1665 3.82213
        1.1665 3.49996Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.8335 1.75004C5.67879 1.75004 5.53041 1.8115 5.42102 1.92089C5.31162 2.03029
        5.25016 2.17866 5.25016 2.33337V2.91671H8.75016V2.33337C8.75016 2.17866 8.6887 2.03029 8.57931 1.92089C8.46991 1.8115 8.32154
        1.75004 8.16683 1.75004H5.8335ZM9.91683 2.91671V2.33337C9.91683 1.86924 9.73245 1.42413 9.40426 1.09594C9.07608 0.767748 8.63096
        0.583374 8.16683 0.583374H5.8335C5.36937 0.583374 4.92425 0.767748 4.59606 1.09594C4.26787 1.42413 4.0835 1.86924 4.0835
        2.33337V2.91671H2.91683C2.59466 2.91671 2.3335 3.17787 2.3335 3.50004V11.6667C2.3335 12.1308 2.51787 12.576 2.84606 12.9041C3.17425
        13.2323 3.61937 13.4167 4.0835 13.4167H9.91683C10.381 13.4167 10.8261 13.2323 11.1543 12.9041C11.4825 12.576 11.6668 12.1308 11.6668
        11.6667V3.50004C11.6668 3.17787 11.4057 2.91671 11.0835 2.91671H9.91683ZM3.50016 4.08337V11.6667C3.50016 11.8214 3.56162 11.9698
        3.67102 12.0792C3.78041 12.1886 3.92879 12.25 4.0835 12.25H9.91683C10.0715 12.25 10.2199 12.1886 10.3293 12.0792C10.4387 11.9698
        10.5002 11.8214 10.5002 11.6667V4.08337H3.50016Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83333 5.83337C6.1555 5.83337 6.41667 6.09454 6.41667 6.41671V9.91671C6.41667
        10.2389 6.1555 10.5 5.83333 10.5C5.51117 10.5 5.25 10.2389 5.25 9.91671V6.41671C5.25 6.09454 5.51117 5.83337 5.83333 5.83337Z"
        fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.16683 5.83337C8.489 5.83337 8.75016 6.09454 8.75016 6.41671V9.91671C8.75016
        10.2389 8.489 10.5 8.16683 10.5C7.84466 10.5 7.5835 10.2389 7.5835 9.91671V6.41671C7.5835 6.09454 7.84466 5.83337 8.16683 5.83337Z"
        fill="currentColor"/>
        </svg>
        `,
    copy: `
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.4165 5.83329C6.09434 5.83329 5.83317 6.09446 5.83317 6.41663V11.6666C5.83317
        11.9888 6.09434 12.25 6.4165 12.25H11.6665C11.9887 12.25 12.2498 11.9888 12.2498 11.6666V6.41663C12.2498 6.09446 11.9887 5.83329
        11.6665 5.83329H6.4165ZM4.6665 6.41663C4.6665 5.45013 5.45001 4.66663 6.4165 4.66663H11.6665C12.633 4.66663 13.4165 5.45013
        13.4165 6.41663V11.6666C13.4165 12.6331 12.633 13.4166 11.6665 13.4166H6.4165C5.45001 13.4166 4.6665 12.6331 4.6665 11.6666V6.41663Z"
        fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.3335 1.75004C2.17879 1.75004 2.03041 1.8115 1.92102 1.9209C1.81162 2.03029
        1.75016 2.17866 1.75016 2.33337V7.58337C1.75016 7.73808 1.81162 7.88646 1.92102 7.99585C2.03041 8.10525 2.17879 8.16671 2.3335
        8.16671H2.91683C3.239 8.16671 3.50016 8.42788 3.50016 8.75004C3.50016 9.07221 3.239 9.33337 2.91683 9.33337H2.3335C1.86937 9.33337
        1.42425 9.149 1.09606 8.82081C0.767871 8.49262 0.583496 8.0475 0.583496 7.58337V2.33337C0.583496 1.86925 0.76787 1.42413 1.09606
        1.09594C1.42425 0.767748 1.86937 0.583374 2.3335 0.583374H7.5835C8.04763 0.583374 8.49274 0.767748 8.82093 1.09594C9.14912 1.42413
        9.3335 1.86925 9.3335 2.33337V2.91671C9.3335 3.23887 9.07233 3.50004 8.75016 3.50004C8.428 3.50004 8.16683 3.23887 8.16683
        2.91671V2.33337C8.16683 2.17866 8.10537 2.03029 7.99598 1.9209C7.88658 1.8115 7.73821 1.75004 7.5835 1.75004H2.3335Z" fill="currentColor"/>
        </svg>
        `,
    rename: `
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.09606 2.26256C1.42425 1.93437 1.86937 1.75 2.3335 1.75H6.41683C6.739 1.75
        7.00016 2.01117 7.00016 2.33333C7.00016 2.6555 6.739 2.91667 6.41683 2.91667H2.3335C2.17879 2.91667 2.03041 2.97812 1.92102
        3.08752C1.81162 3.19692 1.75016 3.34529 1.75016 3.5V11.6667C1.75016 11.8214 1.81162 11.9697 1.92102 12.0791C2.03041 12.1885
        2.17879 12.25 2.3335 12.25H10.5002C10.6549 12.25 10.8032 12.1885 10.9126 12.0791C11.022 11.9697 11.0835 11.8214 11.0835
        11.6667V7.58333C11.0835 7.26117 11.3447 7 11.6668 7C11.989 7 12.2502 7.26117 12.2502 7.58333V11.6667C12.2502 12.1308 12.0658
        12.5759 11.7376 12.9041C11.4094 13.2323 10.9643 13.4167 10.5002 13.4167H2.3335C1.86937 13.4167 1.42425 13.2323 1.09606 12.9041C0.767871
        12.5759 0.583496 12.1308 0.583496 11.6667V3.5C0.583496 3.03587 0.76787 2.59075 1.09606 2.26256Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6668 1.67924C11.4934 1.67924 11.327 1.74815 11.2043 1.87082L5.7769
        7.29824L5.46856 8.53163L6.70195 8.22328L12.1294 2.79586C12.252 2.6732 12.3209 2.50682 12.3209 2.33334C12.3209 2.15986 12.252
        1.99349 12.1294 1.87082C12.0067 1.74815 11.8403 1.67924 11.6668 1.67924ZM10.3794 1.04586C10.7208 0.704404 11.1839 0.512573
        11.6668 0.512573C12.1497 0.512573 12.6129 0.704404 12.9543 1.04586C13.2958 1.38732 13.4876 1.85044 13.4876 2.33334C13.4876
        2.81624 13.2958 3.27936 12.9543 3.62082L7.41265 9.16249C7.33789 9.23725 7.24422 9.29029 7.14165 9.31593L4.80832 9.89926C4.60954
        9.94896 4.39925 9.89071 4.25436 9.74582C4.10947 9.60093 4.05123 9.39065 4.10093 9.19186L4.68426 6.85853C4.7099 6.75596 4.76294
        6.66229 4.8377 6.58753L10.3794 1.04586Z" fill="currentColor"/>
        </svg>
        `,
    move: `
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.32931 4.83748C3.55711 5.06529 3.55711 5.43463 3.32931 5.66244L1.99179
        6.99996L3.32931 8.33748C3.55711 8.56529 3.55711 8.93463 3.32931 9.16244C3.1015 9.39024 2.73216 9.39024 2.50435 9.16244L0.75435
        7.41244C0.526545 7.18463 0.526545 6.81529 0.75435 6.58748L2.50435 4.83748C2.73216 4.60967 3.1015 4.60967 3.32931 4.83748Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.58736 0.754228C6.81516 0.526423 7.18451 0.526423 7.41232 0.754228L9.16232
        2.50423C9.39012 2.73203 9.39012 3.10138 9.16232 3.32919C8.93451 3.55699 8.56516 3.55699 8.33736 3.32919L6.99984 1.99167L5.66232
        3.32919C5.43451 3.55699 5.06516 3.55699 4.83736 3.32919C4.60955 3.10138 4.60955 2.73203 4.83736 2.50423L6.58736 0.754228Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.83736 10.6709C5.06516 10.443 5.43451 10.443 5.66232 10.6709L6.99984
        12.0084L8.33736 10.6709C8.56516 10.443 8.93451 10.443 9.16232 10.6709C9.39012 10.8987 9.39012 11.268 9.16232 11.4958L7.41232
        13.2458C7.18451 13.4736 6.81516 13.4736 6.58736 13.2458L4.83736 11.4958C4.60955 11.268 4.60955 10.8987 4.83736 10.6709Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6709 4.83748C10.8987 4.60967 11.268 4.60967 11.4958 4.83748L13.2458
        6.58748C13.4736 6.81529 13.4736 7.18463 13.2458 7.41244L11.4958 9.16244C11.268 9.39024 10.8987 9.39024 10.6709 9.16244C10.443
        8.93463 10.443 8.56529 10.6709 8.33748L12.0084 6.99996L10.6709 5.66244C10.443 5.43463 10.443 5.06529 10.6709 4.83748Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.583496 6.99996C0.583496 6.67779 0.844663 6.41663 1.16683 6.41663H12.8335C13.1557
        6.41663 13.4168 6.67779 13.4168 6.99996C13.4168 7.32213 13.1557 7.58329 12.8335 7.58329H1.16683C0.844663 7.58329 0.583496 7.32213 0.583496 6.99996Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99984 0.583374C7.322 0.583374 7.58317 0.844541 7.58317 1.16671V12.8334C7.58317
        13.1555 7.322 13.4167 6.99984 13.4167C6.67767 13.4167 6.4165 13.1555 6.4165 12.8334V1.16671C6.4165 0.844541 6.67767 0.583374 6.99984
        0.583374Z" fill="currentColor"/>
        </svg>
        `,
    search: `
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.4165 2.33329C4.16134 2.33329 2.33317 4.16146 2.33317 6.41663C2.33317
        8.67179 4.16134 10.5 6.4165 10.5C8.67167 10.5 10.4998 8.67179 10.4998 6.41663C10.4998 4.16146 8.67167 2.33329 6.4165
        2.33329ZM1.1665 6.41663C1.1665 3.51713 3.51701 1.16663 6.4165 1.16663C9.316 1.16663 11.6665 3.51713 11.6665 6.41663C11.6665
        9.31612 9.316 11.6666 6.4165 11.6666C3.51701 11.6666 1.1665 9.31612 1.1665 6.41663Z" fill="#2E3B52"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.30025 9.3C9.52805 9.0722 9.8974 9.0722 10.1252 9.3L12.6627 11.8375C12.8905
        12.0653 12.8905 12.4347 12.6627 12.6625C12.4349 12.8903 12.0656 12.8903 11.8377 12.6625L9.30025 10.125C9.07244 9.89716 9.07244
        9.52781 9.30025 9.3Z" fill="#2E3B52"/>
        </svg>
        `,
    export_file: `
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 366.999 366.999" style="enable-background:new 0 0 366.999 366.999;" xml:space="preserve">
        <path id="XMLID_223_" d="M363.598,247.01c0.146-0.177,0.272-0.365,0.409-0.547c0.157-0.209,0.319-0.414,0.464-0.632
        c0.145-0.216,0.27-0.441,0.402-0.662c0.118-0.198,0.243-0.392,0.352-0.596c0.121-0.225,0.223-0.458,0.332-0.688
        c0.101-0.213,0.207-0.423,0.298-0.643c0.092-0.223,0.167-0.451,0.248-0.678c0.085-0.235,0.175-0.467,0.248-0.708
        c0.068-0.226,0.118-0.454,0.176-0.683c0.062-0.246,0.131-0.49,0.181-0.741c0.052-0.261,0.082-0.524,0.12-0.788
        c0.032-0.221,0.074-0.439,0.096-0.664c0.048-0.485,0.073-0.973,0.074-1.46c0-0.007,0.001-0.013,0.001-0.02
        c0-0.008-0.001-0.017-0.001-0.025c0-0.486-0.025-0.971-0.073-1.455c-0.022-0.225-0.064-0.442-0.096-0.664
        c-0.038-0.263-0.068-0.526-0.12-0.787c-0.05-0.253-0.12-0.499-0.182-0.747c-0.057-0.226-0.107-0.453-0.174-0.677
        c-0.073-0.242-0.164-0.476-0.25-0.713c-0.081-0.225-0.155-0.452-0.246-0.673c-0.092-0.221-0.199-0.432-0.3-0.647
        c-0.108-0.229-0.209-0.459-0.329-0.683c-0.11-0.206-0.236-0.401-0.355-0.6c-0.131-0.221-0.256-0.443-0.4-0.658
        c-0.147-0.219-0.31-0.424-0.467-0.635c-0.136-0.182-0.262-0.368-0.407-0.544c-0.299-0.365-0.616-0.714-0.948-1.049
        c-0.016-0.016-0.029-0.034-0.045-0.05l-37.499-37.501c-5.857-5.857-15.355-5.858-21.213-0.001c-5.858,5.858-5.858,15.355,0,21.213
        l11.894,11.895L270,222.501v-78.605c0.003-0.133,0.02-0.263,0.02-0.396c0-3.606-1.287-6.903-3.407-9.49
        c-0.021-0.026-0.042-0.053-0.064-0.079c-0.276-0.332-0.567-0.65-0.871-0.958c-0.043-0.044-0.087-0.089-0.131-0.133
        c-0.132-0.131-0.255-0.272-0.393-0.398L155.609,22.896c-0.005-0.004-0.01-0.009-0.015-0.014c-0.307-0.306-0.627-0.593-0.955-0.868
        c-0.104-0.087-0.212-0.169-0.318-0.253c-0.24-0.19-0.483-0.374-0.733-0.548c-0.125-0.088-0.251-0.174-0.379-0.258
        c-0.263-0.172-0.53-0.333-0.802-0.487c-0.112-0.063-0.22-0.132-0.334-0.193c-0.363-0.194-0.733-0.372-1.109-0.534
        c-0.154-0.067-0.311-0.124-0.467-0.186c-0.25-0.099-0.501-0.192-0.756-0.277c-0.175-0.058-0.35-0.114-0.527-0.166
        c-0.289-0.084-0.581-0.158-0.875-0.225c-0.131-0.029-0.259-0.066-0.392-0.093c-0.42-0.084-0.844-0.146-1.27-0.193
        c-0.13-0.015-0.262-0.023-0.393-0.035c-0.353-0.031-0.706-0.048-1.06-0.054C145.148,18.51,145.076,18.5,145,18.5H15
        c-8.284,0-15,6.716-15,15v300c0,8.284,6.716,15,15,15h240c8.284,0,15-6.716,15-15v-80.999l45.786,0.001l-11.893,11.893
        c-5.858,5.858-5.858,15.355,0,21.213c2.929,2.929,6.768,4.394,10.606,4.394s7.678-1.464,10.606-4.394l37.499-37.499
        c0.008-0.008,0.014-0.016,0.021-0.023C362.968,247.742,363.292,247.383,363.598,247.01z M160,69.713l58.787,58.787H160V69.713z
        M240,318.5H30v-270h100v95c0,8.284,6.716,15,15,15h95v64.001l-65.001-0.001c-8.284,0-15,6.716-15,15c0,8.284,6.716,15,15,15
        L240,252.501V318.5z"/>
        </svg>
    `,
    dots: `
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 7.5C6.25 6.80964 6.80964 6.25 7.5 6.25C8.19036 6.25 8.75
        6.80964 8.75 7.5C8.75 8.19036 8.19036 8.75 7.5 8.75C6.80964 8.75 6.25 8.19036 6.25 7.5Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 3.125C6.25 2.43464 6.80964 1.875 7.5 1.875C8.19036 1.875
        8.75 2.43464 8.75 3.125C8.75 3.81536 8.19036 4.375 7.5 4.375C6.80964 4.375 6.25 3.81536 6.25 3.125Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 11.875C6.25 11.1846 6.80964 10.625 7.5 10.625C8.19036
        10.625 8.75 11.1846 8.75 11.875C8.75 12.5654 8.19036 13.125 7.5 13.125C6.80964 13.125 6.25 12.5654 6.25 11.875Z" fill="currentColor"/>
        </svg>
    `,
    chevron: `
        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.54479 2.70529C6.70751 2.86801 6.70751 3.13183 6.54479 3.29455L4.33942
        5.49992L6.54479 7.70529C6.70751 7.86801 6.70751 8.13183 6.54479 8.29455C6.38207 8.45727 6.11825 8.45727 5.95554 8.29455L3.45553
        5.79455C3.29282 5.63183 3.29282 5.36801 3.45553 5.20529L5.95554 2.70529C6.11825 2.54257 6.38207 2.54257 6.54479 2.70529Z" fill="#2E3B52"/>
        </svg>
    `,
    close_window: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M12 4L4 12" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>\n' +
        '<path d="M4 4L12 12" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>\n' +
        '</svg>\n',
    close_button: `
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00002 5.29307L10.6465 0.646606L11.3536 1.35371L6.70713 6.00018L11.3536
            10.6466L10.6465 11.3537L6.00002 6.70728L1.35359 11.3537L0.646484 10.6466L5.29291 6.00018L0.646486 1.35379L1.35359 0.646682L6.00002
            5.29307Z" fill="currentColor" fill-opacity="0.8"/>
        </svg>
        `,
    close_popup: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.52859 3.52851C3.78894 3.26816 4.21105 3.26816 4.4714
            3.52851L8 7.05711L11.5286 3.52851C11.7889 3.26816 12.2111 3.26816 12.4714 3.52851C12.7317 3.78886 12.7317
            4.21097 12.4714 4.47132L8.9428 7.99992L12.4714 11.5285C12.7317 11.7889 12.7317 12.211 12.4714
            12.4713C12.2111 12.7317 11.7889 12.7317 11.5286 12.4713L8 8.94273L4.4714 12.4713C4.21105 12.7317 3.78894
            12.7317 3.52859 12.4713C3.26824 12.211 3.26824 11.7889 3.52859 11.5285L7.05719 7.99992L3.52859
            4.47132C3.26824 4.21097 3.26824 3.78886 3.52859 3.52851Z" fill="#606F89"/>
        </svg>`,
    close_white: `
       <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00002 5.29307L10.6465 0.646606L11.3536 1.35371L6.70713
            6.00018L11.3536 10.6466L10.6465 11.3537L6.00002 6.70728L1.35359 11.3537L0.646484 10.6466L5.29291 6.00018L0.646486
            1.35379L1.35359 0.646682L6.00002 5.29307Z" fill="white"/>
       </svg>
        `,
    project_settings: `
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.97869 3.84894L8.16436 4.41949L8.51196 4.30637L8.57085 3.9456L7.97869 3.84894ZM8.44371 1V0.4H7.93371L7.85155
        0.903343L8.44371 1ZM7.97869 16.1511L8.57085 16.0544L8.51196 15.6936L8.16436 15.5805L7.97869 16.1511ZM8.44371 19L7.85155
        19.0967L7.93371 19.6H8.44371V19ZM1.42773 13.152L1.04795 12.6875L0.653117 13.0103L0.908119 13.452L1.42773 13.152ZM2.98426
        15.848L2.46464 16.148L2.71964 16.5896L3.19663 16.4091L2.98426 15.848ZM2.98426 4.15199L3.19753 3.59117L2.72006 3.4096L2.46464
        3.85199L2.98426 4.15199ZM1.42773 6.84796L0.908119 6.54796L0.653117 6.98964L1.04794 7.31246L1.42773 6.84796ZM5.67101 5.17374L5.45774
        5.73455L5.79786 5.8639L6.06976 5.62207L5.67101 5.17374ZM3.64042 8.65712L4.22737 8.78159L4.30319 8.424L4.02021 8.19262L3.64042
        8.65712ZM3.64041 11.3428L4.0202 11.8073L4.30319 11.576L4.22736 11.2184L3.64041 11.3428ZM5.67487 14.8297L6.07326 14.381L5.8019
        14.1401L5.46249 14.2685L5.67487 14.8297ZM12.0218 16.1511L11.8361 15.5805L11.4885 15.6936L11.4296 16.0544L12.0218 16.1511ZM11.5568
        19V19.6H12.0668L12.1489 19.0967L11.5568 19ZM12.0218 3.84894L11.4296 3.9456L11.4885 4.30637L11.8361 4.41949L12.0218 3.84894ZM11.5568
        1L12.1489 0.903342L12.0668 0.4H11.5568V1ZM18.5728 6.84801L18.9525 7.31251L19.3474 6.98969L19.0924 6.54801L18.5728 6.84801ZM17.0162
        4.15204L17.5358 3.85204L17.2808 3.41036L16.8039 3.59088L17.0162 4.15204ZM17.0162 15.848L16.803 16.4088L17.2804 16.5904L17.5358
        16.148L17.0162 15.848ZM18.5728 13.152L19.0924 13.452L19.3474 13.0104L18.9525 12.6875L18.5728 13.152ZM14.3295 14.8263L14.5428
        14.2654L14.2026 14.1361L13.9307 14.3779L14.3295 14.8263ZM16.3601 11.3429L15.7731 11.2184L15.6973 11.576L15.9803 11.8074L16.3601
        11.3429ZM16.3601 8.65716L15.9803 8.19266L15.6973 8.42404L15.7731 8.78162L16.3601 8.65716ZM14.3256 5.17031L13.9272 5.61896L14.1986
        5.85992L14.538 5.73147L14.3256 5.17031ZM12.4002 10C12.4002 11.3255 11.3257 12.4 10.0002 12.4V13.6C11.9885 13.6 13.6002 11.9882 13.6002
        10H12.4002ZM10.0002 12.4C8.67476 12.4 7.60024 11.3255 7.60024 10H6.40024C6.40024 11.9882 8.01202 13.6 10.0002 13.6V12.4ZM7.60024
        10C7.60024 8.67452 8.67476 7.6 10.0002 7.6V6.4C8.01202 6.4 6.40024 8.01178 6.40024 10H7.60024ZM10.0002 7.6C11.3257 7.6 12.4002
        8.67452 12.4002 10H13.6002C13.6002 8.01178 11.9885 6.4 10.0002 6.4V7.6ZM8.57085 3.9456L9.03587 1.09666L7.85155 0.903343L7.38652
        3.75228L8.57085 3.9456ZM7.38652 16.2477L7.85155 19.0967L9.03587 18.9033L8.57085 16.0544L7.38652 16.2477ZM0.908119 13.452L2.46464
        16.148L3.50387 15.548L1.94735 12.852L0.908119 13.452ZM2.46464 3.85199L0.908119 6.54796L1.94735 7.14796L3.50387 4.45199L2.46464
        3.85199ZM6.06976 5.62207C6.67062 5.08767 7.38167 4.67419 8.16436 4.41949L7.79302 3.27839C6.85013 3.58523 5.99457 4.08299 5.27227
        4.7254L6.06976 5.62207ZM2.77098 4.71281L5.45774 5.73455L5.88428 4.61292L3.19753 3.59117L2.77098 4.71281ZM4.10023 9.99999C4.10023
        9.58164 4.14412 9.17414 4.22737 8.78159L3.05347 8.53266C2.95298 9.00655 2.90023 9.49746 2.90023 9.99999H4.10023ZM4.02021
        8.19262L1.80752 6.38346L1.04794 7.31246L3.26063 9.12162L4.02021 8.19262ZM3.26062 10.8783L1.04795 12.6875L1.80752 13.6165L4.0202
        11.8073L3.26062 10.8783ZM2.90023 9.99999C2.90023 10.5025 2.95298 10.9934 3.05346 11.4673L4.22736 11.2184C4.14412 10.8258 4.10023
        10.4183 4.10023 9.99999H2.90023ZM5.27648 15.2783C5.99789 15.9189 6.85196 16.4154 7.79302 16.7216L8.16436 15.5805C7.3832 15.3263
        6.67338 14.9139 6.07326 14.381L5.27648 15.2783ZM5.46249 14.2685L2.77188 15.2868L3.19663 16.4091L5.88724 15.3908L5.46249
        14.2685ZM11.4296 16.0544L10.9646 18.9033L12.1489 19.0967L12.614 16.2477L11.4296 16.0544ZM12.614 3.75228L12.1489 0.903342L10.9646
        1.09666L11.4296 3.9456L12.614 3.75228ZM19.0924 6.54801L17.5358 3.85204L16.4966 4.45204L18.0531 7.14801L19.0924 6.54801ZM17.5358
        16.148L19.0924 13.452L18.0531 12.852L16.4966 15.548L17.5358 16.148ZM13.9307 14.3779C13.3299 14.9123 12.6188 15.3258 11.8361
        15.5805L12.2075 16.7216C13.1504 16.4148 14.0059 15.917 14.7282 15.2746L13.9307 14.3779ZM17.2295 15.2872L14.5428 14.2654L14.1162
        15.3871L16.803 16.4088L17.2295 15.2872ZM15.9003 10C15.9003 10.4184 15.8564 10.8259 15.7731 11.2184L16.947 11.4673C17.0475 10.9935
        17.1003 10.5025 17.1003 10H15.9003ZM15.9803 11.8074L18.193 13.6165L18.9525 12.6875L16.7399 10.8784L15.9803 11.8074ZM16.7399
        9.12166L18.9525 7.31251L18.193 6.38351L15.9803 8.19266L16.7399 9.12166ZM17.1003 10C17.1003 9.49749 17.0475 9.00658 16.947
        8.5327L15.7731 8.78162C15.8564 9.17418 15.9003 9.58167 15.9003 10H17.1003ZM14.724 4.72167C14.0026 4.08106 13.1485 3.58463 12.2075
        3.27839L11.8361 4.41949C12.6173 4.6737 13.3271 5.08606 13.9272 5.61896L14.724 4.72167ZM14.538 5.73147L17.2286 4.71319L16.8039
        3.59088L14.1132 4.60916L14.538 5.73147ZM8.44371 1.6H11.5568V0.4H8.44371V1.6ZM8.44371 19.6H11.5568V18.4H8.44371V19.6Z" fill="white"/>
        </svg>
        `,
    mat_select_arrow: `
        <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.02827 0.528636C1.28862 0.268287 1.71073 0.268287 1.97108 0.528636L5.49967
        4.05723L9.02827 0.528636C9.28862 0.268287 9.71073 0.268287 9.97108 0.528636C10.2314 0.788986 10.2314 1.2111 9.97108 1.47145L5.97108
        5.47145C5.71073 5.73179 5.28862 5.73179 5.02827 5.47145L1.02827 1.47145C0.76792 1.2111 0.76792 0.788986 1.02827 0.528636Z" fill="#2E3B52"/>
        </svg>
        `,
    upload_dwg: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 14C3.55228 14 4 14.4477 4 15V19C4 19.2652 4.10536
        19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H19C19.2652 20 19.5196 19.8946 19.7071
        19.7071C19.8946 19.5196 20 19.2652 20 19V15C20 14.4477 20.4477 14 21 14C21.5523 14 22 14.4477
        22 15V19C22 19.7957 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7957 22 19 22H5C4.20435
        22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V15C2 14.4477 2.44772 14 3 14Z"
        fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2929 2.29289C11.6834 1.90237 12.3166
         1.90237 12.7071 2.29289L17.7071 7.29289C18.0976 7.68342 18.0976 8.31658 17.7071 8.70711C17.3166
         9.09763 16.6834 9.09763 16.2929 8.70711L12 4.41421L7.70711 8.70711C7.31658 9.09763 6.68342 9.09763
         6.29289 8.70711C5.90237 8.31658 5.90237 7.68342 6.29289 7.29289L11.2929 2.29289Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C12.5523 2 13 2.44772 13 3V15C13 15.5523 12.5523 16
        12 16C11.4477 16 11 15.5523 11 15V3C11 2.44772 11.4477 2 12 2Z" fill="currentColor"/>
        </svg>
        `,
    list: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.87868 1.87868C4.44129 1.31607 5.20435 1 6 1H14.5C14.7652 1 15.0196 1.10536
        15.2071 1.29289L20.7071 6.79289C20.8946 6.98043 21 7.23478 21 7.5V20C21 20.7957 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839
        18.7957 23 18 23H6C5.20435 23 4.44129 22.6839 3.87868 22.1213C3.31607 21.5587 3 20.7957 3 20V4C3 3.20435 3.31607 2.44129 3.87868
        1.87868ZM6 3C5.73478 3 5.48043 3.10536 5.29289 3.29289C5.10536 3.48043 5 3.73478 5 4V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043
        20.8946 5.73478 21 6 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V7.91421L14.0858 3H6Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1C14.5523 1 15 1.44772 15 2V7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9
        20 9H14C13.4477 9 13 8.55228 13 8V2C13 1.44772 13.4477 1 14 1Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 13C7 12.4477 7.44772 12 8 12H16C16.5523 12 17 12.4477 17 13C17 13.5523 16.5523
        14 16 14H8C7.44772 14 7 13.5523 7 13Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 17C7 16.4477 7.44772 16 8 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523
        18 16 18H8C7.44772 18 7 17.5523 7 17Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 9C7 8.44772 7.44772 8 8 8H10C10.5523 8 11 8.44772 11 9C11 9.55228 10.5523 10 10
        10H8C7.44772 10 7 9.55228 7 9Z" fill="currentColor"/>
        </svg>
        `,
    eye_off: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_254_14071)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.4531 5.26529C5.85123 4.96127 6.41289 4.99874 6.76711 5.35296L10.5832 9.16904C10.5855
        9.17134 10.5878 9.17366 10.5901 9.17599L14.824 13.4099C14.8264 13.4122 14.8287 13.4146 14.8311 13.4169L18.6471 17.233C18.8511 17.437
        18.9571 17.7192 18.9378 18.0071C18.9184 18.295 18.7757 18.5605 18.5462 18.7354C16.6671 20.1678 14.3789 20.9613 12.0163 20.9999L12
        21.0001C8.10049 21.0001 5.10448 18.7736 3.14546 16.684C2.15683 15.6295 1.40207 14.5788 0.894336 13.7926C0.63985 13.3986 0.445792 13.0684
        0.313971 12.8341C0.248023 12.7168 0.19754 12.6234 0.162753 12.5576C0.145357 12.5248 0.131875 12.4988 0.122338 12.4802L0.11099
        12.458L0.107539 12.4512L0.10637 12.4489C0.10637 12.4489 0.105573 12.4473 1 12.0001L0.105573 12.4473C-0.0397387 12.1567 -0.0347895 11.8136
        0.118844 11.5272C1.43015 9.08349 3.24891 6.94843 5.4531 5.26529ZM2.14257 12.0032C2.25165 12.1916 2.39592 12.4312 2.57441 12.7075C3.03543
        13.4214 3.71817 14.3707 4.60454 15.3161C6.39395 17.2248 8.89512 18.997 11.9918 19.0001C13.5373 18.9734 15.0437 18.5525 16.3714
        17.7857L14.0497 15.464C13.891 15.5635 13.7251 15.652 13.5531 15.7286C13.0625 15.9472 12.5328 16.0648 11.9957 16.0743C11.4586 16.0838
        10.9252 15.985 10.4271 15.7838C9.92902 15.5826 9.47657 15.2832 9.09674 14.9033C8.71691 14.5235 8.41747 14.071 8.21629 13.573C8.01511
        13.0749 7.91631 12.5414 7.92579 12.0043C7.93527 11.4673 8.05282 10.9376 8.27145 10.4469C8.34805 10.275 8.43652 10.1091 8.53604
        9.95032L5.9871 7.40138C4.45031 8.70144 3.14935 10.2582 2.14257 12.0032ZM10.0279 11.4422C9.96372 11.6346 9.92907 11.836 9.92548
        12.0396C9.92074 12.3082 9.97014 12.5749 10.0707 12.8239C10.1713 13.073 10.321 13.2992 10.511 13.4891C10.7009 13.679 10.9271 13.8287
        11.1761 13.9293C11.4252 14.0299 11.6919 14.0793 11.9604 14.0746C12.164 14.071 12.3655 14.0364 12.5579 13.9722L10.0279 11.4422Z"
        fill="currentColor"/>
        <path d="M11.9973 5.00002C11.3679 4.99854 10.7404 5.07025 10.1276 5.21371C9.5898 5.33958 9.05183 5.00569 8.92596 4.46794C8.80008
        3.93019 9.13398 3.39222 9.67173 3.26634C10.4351 3.08765 11.2167 2.99828 12.0008 3.00003C15.8997 3.00046 18.8954 5.22667 20.8542
        7.31608C21.8428 8.37062 22.5976 9.42134 23.1053 10.2075C23.3598 10.6015 23.5538 10.9317 23.6857 11.166L23.8941 11.5528C24.0391
        11.8429 24.0345 12.1854 23.8816 12.4714C23.2417 13.6684 22.4787 14.7953 21.6048 15.8339C21.2492 16.2565 20.6184 16.3108 20.1958
        15.9552C19.7732 15.5996 19.7189 14.9688 20.0745 14.5462C20.7435 13.7511 21.3402 12.8982 21.8576 11.9978C21.7485 11.8093 21.604
        11.5694 21.4252 11.2925C20.9642 10.5787 20.2815 9.62943 19.3951 8.68397C17.6041 6.77359 15.1001 5.00003 11.9996 5.00003L11.9973
        5.00002Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L23.7071
        22.2929C24.0976 22.6834 24.0976 23.3166 23.7071 23.7071C23.3166 24.0976 22.6834 24.0976 22.2929 23.7071L0.292893 1.70711C-0.0976311
        1.31658 -0.0976311 0.683417 0.292893 0.292893Z" fill="currentColor"/>
        </g>
        <defs>
        <clipPath id="clip0_254_14071">
        <rect width="24" height="24" fill="currentColor"/>
        </clipPath>
        </defs>
        </svg>
        `,
    eye: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.14074 12C2.25003 12.1889 2.39492 12.4296 2.57441 12.7075C3.03543 13.4213 3.71817
        14.3706 4.60454 15.3161C6.39552 17.2264 8.89951 19 12 19C15.1005 19 17.6045 17.2264 19.3955 15.3161C20.2818 14.3706 20.9646 13.4213
        21.4256 12.7075C21.6051 12.4296 21.75 12.1889 21.8593 12C21.75 11.8111 21.6051 11.5704 21.4256 11.2925C20.9646 10.5787 20.2818 9.6294
        19.3955 8.68394C17.6045 6.77356 15.1005 5 12 5C8.89951 5 6.39552 6.77356 4.60454 8.68394C3.71817 9.6294 3.03543 10.5787 2.57441
        11.2925C2.39492 11.5704 2.25003 11.8111 2.14074 12ZM23 12C23.8944 11.5528 23.8943 11.5524 23.8941 11.5521L23.8925 11.5489L23.889
        11.542L23.8777 11.5198C23.8681 11.5013 23.8546 11.4753 23.8372 11.4424C23.8025 11.3767 23.752 11.2832 23.686 11.166C23.5542 10.9316
        23.3601 10.6015 23.1057 10.2075C22.5979 9.42131 21.8432 8.3706 20.8545 7.31606C18.8955 5.22644 15.8995 3 12 3C8.10049 3 5.10448
        5.22644 3.14546 7.31606C2.15683 8.3706 1.40207 9.42131 0.894336 10.2075C0.63985 10.6015 0.445792 10.9316 0.313971 11.166C0.248023
        11.2832 0.19754 11.3767 0.162753 11.4424C0.145357 11.4753 0.131875 11.5013 0.122338 11.5198L0.11099 11.542L0.107539 11.5489L0.10637
        11.5512C0.106186 11.5516 0.105573 11.5528 1 12L0.105573 11.5528C-0.0351909 11.8343 -0.0351909 12.1657 0.105573 12.4472L1 12C0.105573
        12.4472 0.105389 12.4468 0.105573 12.4472L0.107539 12.4511L0.11099 12.458L0.122338 12.4802C0.131875 12.4987 0.145357 12.5247 0.162753
        12.5576C0.19754 12.6233 0.248023 12.7168 0.313971 12.834C0.445792 13.0684 0.63985 13.3985 0.894336 13.7925C1.40207 14.5787 2.15683
        15.6294 3.14546 16.6839C5.10448 18.7736 8.10049 21 12 21C15.8995 21 18.8955 18.7736 20.8545 16.6839C21.8432 15.6294 22.5979 14.5787
        23.1057 13.7925C23.3601 13.3985 23.5542 13.0684 23.686 12.834C23.752 12.7168 23.8025 12.6233 23.8372 12.5576C23.8546 12.5247 23.8681
        12.4987 23.8777 12.4802L23.889 12.458L23.8925 12.4511L23.8936 12.4488C23.8938 12.4484 23.8944 12.4472 23 12ZM23 12L23.8944
        12.4472C24.0352 12.1657 24.0348 11.8336 23.8941 11.5521L23 12Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046
        14 12C14 10.8954 13.1046 10 12 10ZM8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8
        14.2091 8 12Z" fill="currentColor"/>
        </svg>
        `,
    folder: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 4C3.73478 4 3.48043 4.10536 3.29289 4.29289C3.10536
        4.48043 3 4.73478 3 5V19C3 19.2652 3.10536 19.5196 3.29289 19.7071C3.48043 19.8946 3.73478 20 4 20H20C20.2652
        20 20.5196 19.8946 20.7071 19.7071C20.8946 19.5196 21 19.2652 21 19V8C21 7.73478 20.8946 7.48043 20.7071
        7.29289C20.5196 7.10536 20.2652 7 20 7H11C10.6656 7 10.3534 6.8329 10.1679 6.5547L8.46482 4H4ZM1.87868
        2.87868C2.44129 2.31607 3.20435 2 4 2H9C9.33435 2 9.64658 2.1671 9.83205 2.4453L11.5352 5H20C20.7957 5 21.5587
        5.31607 22.1213 5.87868C22.6839 6.44129 23 7.20435 23 8V19C23 19.7957 22.6839 20.5587 22.1213 21.1213C21.5587
        21.6839 20.7957 22 20 22H4C3.20435 22 2.44129 21.6839 1.87868 21.1213C1.31607 20.5587 1 19.7957 1 19V5C1
        4.20435 1.31607 3.44129 1.87868 2.87868Z" fill="currentColor"/>
        </svg>
        `,
    format_list: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 6C7 5.44772 7.44772 5 8 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21
        7H8C7.44772 7 7 6.55228 7 6Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 12C7 11.4477 7.44772 11 8 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21
        13H8C7.44772 13 7 12.5523 7 12Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 18C7 17.4477 7.44772 17 8 17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19
        21 19H8C7.44772 19 7 18.5523 7 18Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 6C2 5.44772 2.44772 5 3 5H3.01C3.56228 5 4.01 5.44772 4.01 6C4.01 6.55228 3.56228
        7 3.01 7H3C2.44772 7 2 6.55228 2 6Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 11.4477 2.44772 11 3 11H3.01C3.56228 11 4.01 11.4477 4.01 12C4.01 12.5523
        3.56228 13 3.01 13H3C2.44772 13 2 12.5523 2 12Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 18C2 17.4477 2.44772 17 3 17H3.01C3.56228 17 4.01 17.4477 4.01 18C4.01 18.5523
        3.56228 19 3.01 19H3C2.44772 19 2 18.5523 2 18Z" fill="currentColor"/>
        </svg>
        `,
    rotate_right: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21 1C21.5523 1 22 1.44772 22 2V8C22 8.55228 21.5523 9 21 9H15C14.4477 9 14 8.55228
        14 8C14 7.44772 14.4477 7 15 7H20V2C20 1.44772 20.4477 1 21 1Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4987 4.0651C10.7085 3.96449 8.93645 4.46818 7.46681 5.49535C5.99718 6.52252 4.91522
        8.01362 4.39454 9.72937C3.87387 11.4451 3.94467 13.286 4.59559 14.9567C5.24651 16.6274 6.4398 18.031 7.98399 18.9423C9.52817 19.8535
        11.3337 20.2196 13.1109 19.9818C14.8881 19.744 16.5338 18.9161 17.7841 17.6309C19.0343 16.3456 19.8166 14.6777 20.0054 12.8946C20.0635
        12.3454 20.5559 11.9473 21.1051 12.0055C21.6543 12.0636 22.0524 12.556 21.9942 13.1052C21.7583 15.334 20.7805 17.4189 19.2177
        19.0254C17.6548 20.6319 15.5976 21.6669 13.3762 21.9641C11.1547 22.2614 8.89776 21.8038 6.96753 20.6647C5.0373 19.5257 3.54568 17.7712
        2.73203 15.6828C1.91838 13.5944 1.82988 11.2933 2.48072 9.14859C3.13157 7.0039 4.48402 5.14003 6.32107 3.85606C8.15811 2.5721 10.3732
        1.94248 12.611 2.06825C14.8487 2.19402 16.9793 3.06789 18.6609 4.54961L18.6688 4.55657L18.6688 4.55661L21.6688 7.25661C22.0793 7.62607
        22.1126 8.25836 21.7431 8.66887C21.3736 9.07938 20.7413 9.11265 20.3308 8.7432L17.3349 6.04684C15.9902 4.86349 14.2872 4.16562 12.4987
        4.0651Z" fill="currentColor"/>
        </svg>
        `,
    clock: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21
        7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
        fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5C12.5523 5 13 5.44772 13 6V11H16.5C17.0523 11 17.5 11.4477 17.5 12C17.5 12.5523
        17.0523 13 16.5 13H12C11.4477 13 11 12.5523 11 12V6C11 5.44772 11.4477 5 12 5Z" fill="currentColor"/>
        </svg>
        `,
    file_output: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.87868 1.87868C4.44129 1.31607 5.20435 1 6 1H14.5C14.7652 1 15.0196 1.10536 15.2071
        1.29289L20.7071 6.79289C20.8946 6.98043 21 7.23478 21 7.5V20C21 20.7957 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7957 23 18
        23H4C3.44772 23 3 22.5523 3 22C3 21.4477 3.44772 21 4 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19
        20V7.91421L14.0858 3H6C5.73478 3 5.48043 3.10536 5.29289 3.29289C5.10536 3.48043 5 3.73478 5 4V8C5 8.55228 4.55228 9 4 9C3.44772 9 3
        8.55228 3 8V4C3 3.20435 3.31607 2.44129 3.87868 1.87868Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1C14.5523 1 15 1.44772 15 2V7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20
         9H14C13.4477 9 13 8.55228 13 8V2C13 1.44772 13.4477 1 14 1Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 15C1 14.4477 1.44772 14 2 14H12C12.5523 14 13 14.4477 13 15C13 15.5523 12.5523 16
        12 16H2C1.44772 16 1 15.5523 1 15Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.70711 11.2929C6.09763 11.6834 6.09763 12.3166 5.70711 12.7071L3.41421 15L5.70711
        17.2929C6.09763 17.6834 6.09763 18.3166 5.70711 18.7071C5.31658 19.0976 4.68342 19.0976 4.29289 18.7071L1.29289 15.7071C0.902369 15.3166
         0.902369 14.6834 1.29289 14.2929L4.29289 11.2929C4.68342 10.9024 5.31658 10.9024 5.70711 11.2929Z" fill="currentColor"/>
        </svg>
        `,
    rotate_left: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 1C3.55228 1 4 1.44772 4 2V7H9C9.55228 7 10 7.44772 10 8C10 8.55228 9.55228 9 9
        9H3C2.44772 9 2 8.55228 2 8V2C2 1.44772 2.44772 1 3 1Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3889 2.06825C13.6267 1.94248 15.8418 2.5721 17.6788 3.85606C19.5159 5.14003 20.8683
        7.0039 21.5192 9.14859C22.17 11.2933 22.0815 13.5944 21.2679 15.6828C20.4542 17.7712 18.9626 19.5257 17.0324 20.6647C15.1021 21.8038
        12.8452 22.2614 10.6237 21.9641C8.40225 21.6669 6.34505 20.6319 4.78223 19.0254C3.2194 17.4189 2.24157 15.334 2.00564 13.1052C1.9475
        12.556 2.3456 12.0636 2.89481 12.0055C3.44403 11.9473 3.93639 12.3454 3.99453 12.8946C4.18327 14.6777 4.96554 16.3456 6.2158
        17.6309C7.46606 18.9161 9.11182 19.744 10.889 19.9818C12.6662 20.2196 14.4717 19.8535 16.0159 18.9423C17.5601 18.031 18.7534 16.6274
        19.4043 14.9567C20.0552 13.286 20.126 11.4451 19.6053 9.72937C19.0847 8.01362 18.0027 6.52252 16.5331 5.49535C15.0634 4.46818 13.2913
        3.96449 11.5012 4.0651C9.71266 4.16562 8.0097 4.86348 6.66502 6.04682L3.66905 8.7432C3.25854 9.11265 2.62625 9.07938 2.25679
        8.66887C1.88733 8.25836 1.92061 7.62607 2.33112 7.25661L5.33893 4.54957C7.02053 3.06784 9.15118 2.19402 11.3889 2.06825Z" fill="currentColor"/>
        </svg>
        `,
    import: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C12.5523 2 13 2.44772 13 3V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523
        11 15V3C11 2.44772 11.4477 2 12 2Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.29289 10.2929C7.68342 9.90237 8.31658 9.90237 8.70711 10.2929L12 13.5858L15.2929
        10.2929C15.6834 9.90237 16.3166 9.90237 16.7071 10.2929C17.0976 10.6834 17.0976 11.3166 16.7071 11.7071L12.7071 15.7071C12.3166 16.0976
        11.6834 16.0976 11.2929 15.7071L7.29289 11.7071C6.90237 11.3166 6.90237 10.6834 7.29289 10.2929Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.87868 4.87868C2.44129 4.31607 3.20435 4 4 4H8C8.55228 4 9 4.44772 9 5C9 5.55228 8.55228
        6 8 6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7V17C3 17.2652 3.10536 17.5196 3.29289 17.7071C3.48043
        17.8946 3.73478 18 4 18H20C20.2652 18 20.5196 17.8946 20.7071 17.7071C20.8946 17.5196 21 17.2652 21 17V7C21 6.73478 20.8946 6.48043
        20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6H16C15.4477 6 15 5.55228 15 5C15 4.44772 15.4477 4 16 4H20C20.7957 4 21.5587 4.31607
        22.1213 4.87868C22.6839 5.44129 23 6.20435 23 7V17C23 17.7957 22.6839 18.5587 22.1213 19.1213C21.5587 19.6839 20.7957 20 20 20H4C3.20435
        20 2.44129 19.6839 1.87868 19.1213C1.31607 18.5587 1 17.7956 1 17V7C1 6.20435 1.31607 5.44129 1.87868 4.87868Z" fill="currentColor"/>
        </svg>
        `,
    wrench: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5581 3.02796C16.0752 2.97339 15.5841 2.9892 15.1004 3.07733C14.1005 3.25952
        13.1799 3.74211 12.4612 4.46081C11.7425 5.1795 11.2599 6.10016 11.0777 7.10009C10.8955 8.10001 11.0224 9.13172 11.4414 10.0577C11.613
        10.4369 11.5318 10.8828 11.2375 11.1771L4.32745 18.0871C4.11716 18.2974 3.99902 18.5826 3.99902 18.88C3.99902 19.1774 4.11716 19.4626
        4.32745 19.6729C4.53774 19.8832 4.82295 20.0013 5.12034 20.0013C5.41774 20.0013 5.70295 19.8832 5.91324 19.6729L12.8232 12.7629C13.1176
        12.4686 13.5634 12.3873 13.9426 12.5589C14.8686 12.978 15.9003 13.1048 16.9002 12.9226C17.9002 12.7404 18.8208 12.2578 19.5395
        11.5392C20.2582 10.8205 20.7408 9.8998 20.923 8.89987C21.0111 8.41622 21.0269 7.92514 20.9724 7.44218L18.4004 10.0142C18.0265 10.3806
        17.5238 10.5858 17.0003 10.5858C16.4768 10.5858 15.9742 10.3806 15.6003 10.0141L15.5932 10.0071L13.9862 8.40002C13.6197 8.02616 13.4145
        7.52349 13.4145 6.99998C13.4145 6.47647 13.6197 5.97384 13.9862 5.59998L13.9932 5.59284L16.5581 3.02796ZM14.7419 1.10972C16.1418 0.854655
        17.5862 1.03228 18.8826 1.61892C19.1817 1.75426 19.3953 2.02777 19.4541 2.35073C19.513 2.67369 19.4096 3.00496 19.1775 3.23709L15.4146
        6.99998L17.0003 8.58577L20.7632 4.82287C20.9954 4.59075 21.3266 4.48733 21.6496 4.54618C21.9726 4.60502 22.2461 4.81863 22.3814
        5.11771C22.968 6.41409 23.1457 7.85848 22.8906 9.25838C22.6355 10.6583 21.9599 11.9472 20.9537 12.9534C19.9476 13.9595 18.6586 14.6352
        17.2587 14.8902C16.0913 15.103 14.8929 15.0147 13.7752 14.6393L7.32745 21.0871C6.74209 21.6724 5.94817 22.0013 5.12034 22.0013C4.29252
        22.0013 3.4986 21.6724 2.91324 21.0871C2.32788 20.5017 1.99902 19.7078 1.99902 18.88C1.99902 18.0522 2.32788 17.2582 2.91324
        16.6729L9.361 10.2251C8.98558 9.10743 8.89737 7.90903 9.11008 6.74158C9.36515 5.34169 10.0408 4.05277 11.047 3.0466C12.0531 2.04042
        13.3421 1.36478 14.7419 1.10972Z" fill="currentColor"/>
        </svg>
        `,
    go_back: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21
        7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
        fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7071 7.29289C13.0976 7.68342 13.0976 8.31658 12.7071 8.70711L9.41421 12L12.7071
        15.2929C13.0976 15.6834 13.0976 16.3166 12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L7.29289 12.7071C6.90237 12.3166
        6.90237 11.6834 7.29289 11.2929L11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 12C7 11.4477 7.44772 11 8 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16
        13H8C7.44772 13 7 12.5523 7 12Z" fill="currentColor"/>
        </svg>
        `,
    go_to: `
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
        <path d="M7 4H3.11111C2.81643 4 2.53381 4.11706 2.32544 4.32544C2.11706 4.53381 2 4.81643 2 5.11111V12.8889C2 13.1836 2.11706 13.4662 2.32544 13.6746C2.53381 13.8829 2.81643 14 3.11111 14H10.8889C11.1836 14 11.4662 13.8829 11.6746 13.6746C11.8829 13.4662 12 13.1836 12 12.8889V9" stroke="black" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.5 2H14V6.5" stroke="black" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14 2L8 8" stroke="black" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        </svg>`,
    image: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20
        19V5C20 4.44772 19.5523 4 19 4H5ZM2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22
        2 20.6569 2 19V5Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 8C8.22386 8 8 8.22386 8 8.5C8 8.77614 8.22386 9 8.5 9C8.77614 9 9 8.77614 9 8.5C9
        8.22386 8.77614 8 8.5 8ZM6 8.5C6 7.11929 7.11929 6 8.5 6C9.88071 6 11 7.11929 11 8.5C11 9.88071 9.88071 11 8.5 11C7.11929 11 6 9.88071 6
        8.5Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2929 9.29289C15.6834 8.90237 16.3166 8.90237 16.7071 9.29289L21.7071 14.2929C22.0976
        14.6834 22.0976 15.3166 21.7071 15.7071C21.3166 16.0976 20.6834 16.0976 20.2929 15.7071L16 11.4142L5.70711 21.7071C5.31658 22.0976 4.68342
        22.0976 4.29289 21.7071C3.90237 21.3166 3.90237 20.6834 4.29289 20.2929L15.2929 9.29289Z" fill="currentColor"/>
        </svg>
        `,
    refresh: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 1C3.55228 1 4 1.44772 4 2V7H9C9.55228 7 10 7.44772 10 8C10 8.55228 9.55228 9 9
        9H3C2.44772 9 2 8.55228 2 8V2C2 1.44772 2.44772 1 3 1Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4579 2.12792C12.3645 1.83036 14.3165 2.09076 16.0783 2.87774C17.8402 3.66471 19.3368
        4.94463 20.3875 6.56313C21.4382 8.18162 21.9982 10.0695 22 11.9992C22.0005 12.5515 21.5532 12.9996 21.0009 13.0001C20.4486 13.0006 20.0005
         12.5533 20 12.001C19.9986 10.4573 19.5506 8.94695 18.71 7.65216C17.8694 6.35737 16.6722 5.33343 15.2627 4.70385C13.8532 4.07427 12.2916
         3.86595 10.7664 4.104C9.24159 4.34197 7.8182 5.01589 6.66777 6.04448C6.66741 6.04481 6.66704 6.04514 6.66668 6.04546L3.66897 8.7434C3.25846 9.11286 2.62617 9.07958 2.25671 8.66907C1.88726 8.25856 1.92053 7.62627 2.33104 7.25681L5.33334 4.55475C6.77161 3.26831 8.55137 2.42549 10.4579 2.12792Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14 16C14 15.4477 14.4477 15 15 15H21C21.5523 15 22 15.4477 22 16V22C22 22.5523 21.5523
        23 21 23C20.4477 23 20 22.5523 20 22V17H15C14.4477 17 14 16.5523 14 16Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.99909 11C3.55137 10.9995 3.9995 11.4468 4 11.9991C4.00141 13.5428 4.44942 15.0532
        5.29 16.3479C6.13059 17.6427 7.32784 18.6667 8.73734 19.2963C10.1468 19.9258 11.7084 20.1342 13.2337 19.8961C14.7584 19.6581 16.1818
        18.9842 17.3322 17.9556C17.3326 17.9553 17.333 17.955 17.3333 17.9546L20.331 15.2567C20.7415 14.8872 21.3738 14.9205 21.7433
        15.331C22.1128 15.7415 22.0795 16.3738 21.669 16.7433L18.669 19.4433L18.6667 19.4454C17.2284 20.7318 15.4486 21.5746 13.5421
        21.8722C11.6355 22.1698 9.68356 21.9093 7.92168 21.1224C6.1598 20.3354 4.66324 19.0555 3.61251 17.437C2.56178 15.8185 2.00176 13.9306
        2 12.0009C1.9995 11.4486 2.4468 11.0005 2.99909 11Z" fill="currentColor"/>
        </svg>
        `,
    plus_square: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20
        19V5C20 4.44772 19.5523 4 19 4H5ZM2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315
        22 2 20.6569 2 19V5Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 7C12.5523 7 13 7.44772 13 8V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523
        11 16V8C11 7.44772 11.4477 7 12 7Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 12C7 11.4477 7.44772 11 8 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13
        16 13H8C7.44772 13 7 12.5523 7 12Z" fill="currentColor"/>
        </svg>
        `,
    x_square: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20
        19V5C20 4.44772 19.5523 4 19 4H5ZM2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22
        2 20.6569 2 19V5Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.29289 8.29289C8.68342 7.90237 9.31658 7.90237 9.70711 8.29289L15.7071 14.2929C16.0976
        14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L8.29289 9.70711C7.90237 9.31658 7.90237 8.68342
         8.29289 8.29289Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L9.70711 15.7071C9.31658
         16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L14.2929 8.29289C14.6834 7.90237 15.3166 7.90237
         15.7071 8.29289Z" fill="currentColor"/>
        </svg>
        `,
    alert: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.528 2.2833C10.9773 2.03035 11.4842 1.89746 11.9998 1.89746C12.5154
        1.89746 13.0223 2.03035 13.4716 2.2833C13.9209 2.53626 14.2974 2.90074 14.5648 3.34158L14.5677 3.34633L23.0376 17.4863L23.0458
        17.5001C23.3077 17.9538 23.4463 18.4681 23.4478 18.9919C23.4493 19.5158 23.3135 20.0308 23.0541 20.4859C22.7947 20.941 22.4207
        21.3203 21.9692 21.5859C21.5177 21.8516 21.0046 21.9944 20.4808 22.0001L20.4698 22.0003L3.51879 22.0002C2.99498 21.9944 2.48182
        21.8516 2.03035 21.5859C1.57887 21.3203 1.20483 20.941 0.945426 20.4859C0.686022 20.0308 0.550303 19.5158 0.55177 18.9919C0.553236
        18.4681 0.691839 17.9538 0.953786 17.5001L0.961909 17.4863L9.43191 3.34633L10.2898 3.86019L9.43478 3.34158C9.70218 2.90074 10.0787
        2.53626 10.528 2.2833ZM11.146 4.37684L2.68246 18.506C2.59729 18.6558 2.55224 18.8251 2.55176 18.9975C2.55127 19.1721 2.59651 19.3438
        2.68298 19.4955C2.76945 19.6472 2.89413 19.7736 3.04462 19.8622C3.1938 19.95 3.36317 19.9975 3.53617 20.0002H20.4634C20.6364 19.9975
        20.8058 19.95 20.9549 19.8622C21.1054 19.7736 21.2301 19.6472 21.3166 19.4955C21.403 19.3438 21.4483 19.1721 21.4478 18.9975C21.4473
        18.8252 21.4023 18.6559 21.3171 18.5061L12.8548 4.37883C12.8544 4.37817 12.854 4.37751 12.8536 4.37684C12.7645 4.23079 12.6395 4.11001
        12.4904 4.02607C12.3406 3.94176 12.1716 3.89746 11.9998 3.89746C11.8279 3.89746 11.6589 3.94176 11.5092 4.02607C11.3601 4.11001 11.235
        4.23079 11.146 4.37684Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 8C12.5523 8 13 8.44772 13 9V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523
        11 13V9C11 8.44772 11.4477 8 12 8Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11 17C11 16.4477 11.4477 16 12 16H12.01C12.5623 16 13.01 16.4477 13.01 17C13.01
        17.5523 12.5623 18 12.01 18H12C11.4477 18 11 17.5523 11 17Z" fill="currentColor"/>
        </svg>
    `,
    export_project: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21
        7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
        fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.29289 11.2929C7.68342 10.9024 8.31658 10.9024 8.70711 11.2929L12 14.5858L15.2929
        11.2929C15.6834 10.9024 16.3166 10.9024 16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L12.7071 16.7071C12.3166 17.0976
        11.6834 17.0976 11.2929 16.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 7C12.5523 7 13 7.44772 13 8V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523
        11 16V8C11 7.44772 11.4477 7 12 7Z" fill="currentColor"/>
        </svg>
    `,
    fit_screen: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2929 14.2929C14.6834 13.9024 15.3166 13.9024 15.7071 14.2929L20 18.5858V16.2C20
        15.6477 20.4477 15.2 21 15.2C21.5523 15.2 22 15.6477 22 16.2V21C22 21.1356 21.973 21.2649 21.9241 21.3828C21.8757 21.4999 21.804
        21.6096 21.7092 21.705C21.7078 21.7064 21.7064 21.7078 21.705 21.7092C21.6096 21.804 21.4999 21.8757 21.3828 21.9241C21.2649 21.973
        21.1356 22 21 22H16.2C15.6477 22 15.2 21.5523 15.2 21C15.2 20.4477 15.6477 20 16.2 20H18.5858L14.2929 15.7071C13.9024 15.3166 13.9024
        14.6834 14.2929 14.2929Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70711 14.2929C10.0976 14.6834 10.0976 15.3166 9.70711 15.7071L5.41421 20H7.8C8.35228
        20 8.8 20.4477 8.8 21C8.8 21.5523 8.35228 22 7.8 22H3C2.86441 22 2.73512 21.973 2.61722 21.9241C2.50014 21.8757 2.3904 21.804 2.29502
        21.7092C2.2936 21.7078 2.29219 21.7064 2.29078 21.705C2.19595 21.6096 2.12432 21.4999 2.07588 21.3828C2.02699 21.2649 2 21.1356 2 21C2
        20.9999 2 20.9999 2 20.9998V16.2C2 15.6477 2.44772 15.2 3 15.2C3.55228 15.2 4 15.6477 4 16.2V18.5858L8.29289 14.2929C8.68342 13.9024
        9.31658 13.9024 9.70711 14.2929Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2 3C15.2 2.44772 15.6477 2 16.2 2H20.9998C20.9999 2 20.9999 2 21 2C21.1356 2 21.2649
        2.02699 21.3828 2.07588C21.4999 2.12432 21.6096 2.19595 21.705 2.29078C21.7064 2.29219 21.7078 2.2936 21.7092 2.29502C21.804 2.3904
        21.8757 2.50014 21.9241 2.61722C21.973 2.73512 22 2.86441 22 3V7.8C22 8.35228 21.5523 8.8 21 8.8C20.4477 8.8 20 8.35228 20
        7.8V5.41421L15.7071 9.70711C15.3166 10.0976 14.6834 10.0976 14.2929 9.70711C13.9024 9.31658 13.9024 8.68342 14.2929 8.29289L18.5858
        4H16.2C15.6477 4 15.2 3.55228 15.2 3Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.41421 4L9.70711 8.29289C10.0976 8.68342 10.0976 9.31658 9.70711 9.70711C9.31658
        10.0976 8.68342 10.0976 8.29289 9.70711L4 5.41421V7.8C4 8.35228 3.55228 8.8 3 8.8C2.44772 8.8 2 8.35228 2 7.8V3.00017C2 3.00012 2
        3.00006 2 3C2 2.74408 2.09763 2.48816 2.29289 2.29289C2.38877 2.19702 2.49927 2.12468 2.61722 2.07588C2.73512 2.02699 2.86441 2 3
        2C3.00006 2 3.00012 2 3.00017 2H7.8C8.35228 2 8.8 2.44772 8.8 3C8.8 3.55228 8.35228 4 7.8 4H5.41421Z" fill="currentColor"/>
        </svg>
    `,
    clear_diagram: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20
        19V5C20 4.44772 19.5523 4 19 4H5ZM2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2
        20.6569 2 19V5Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.29289 8.29289C8.68342 7.90237 9.31658 7.90237 9.70711 8.29289L15.7071 14.2929C16.0976
        14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L8.29289 9.70711C7.90237 9.31658 7.90237 8.68342
        8.29289 8.29289Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L9.70711 15.7071C9.31658
        16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L14.2929 8.29289C14.6834 7.90237 15.3166 7.90237
        15.7071 8.29289Z" fill="currentColor"/>
        </svg>
    `,
    point: `<svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(0 -1028.4)">
          <path  d="m12 0c-4.4183 2.3685e-15 -8 3.5817-8 8 0 1.421 0.3816 2.75 1.0312 3.906 0.1079 0.192 0.221 0.381 0.3438 0.563l6.625
          11.531 6.625-11.531c0.102-0.151 0.19-0.311 0.281-0.469l0.063-0.094c0.649-1.156 1.031-2.485 1.031-3.906 0-4.4183-3.582-8-8-8zm0
          4c2.209 0 4 1.7909 4 4 0 2.209-1.791 4-4 4-2.2091 0-4-1.791-4-4 0-2.2091 1.7909-4 4-4z" fill="currentColor" transform="translate(0 1028.4)"/>
           <path d="m12 3c-2.7614 0-5 2.2386-5 5 0 2.761 2.2386 5 5 5 2.761 0 5-2.239 5-5 0-2.7614-2.239-5-5-5zm0 2c1.657 0 3 1.3431
           3 3s-1.343 3-3 3-3-1.3431-3-3 1.343-3 3-3z" fill="currentColor" transform="translate(0 1028.4)"/>
         </g>
        </svg>`,

    shared: `<svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><title/><g id="teamwork">
        <path d="M58.22,30.79A5,5,0,0,0,56,22.11,26.06,26.06,0,0,0,37,6.49a5,5,0,0,0-9.9,0A26.06,26.06,0,0,0,8,22.11a5,5,
        0,0,0-2.18,8.68A7,7,0,0,0,2,37v4a1,1,0,0,0,1,1H8A25.93,25.93,0,0,0,25,57v4a1,1,0,0,0,1,1H38a1,1,0,0,0,1-1V57A25.93,
        25.93,0,0,0,56,42h5a1,1,0,0,0,1-1V37A7,7,0,0,0,58.22,30.79ZM58,27a3,3,0,1,1-3-3A3,3,0,0,1,58,27ZM32,4a3,3,0,1,1-3,
        3A3,3,0,0,1,32,4ZM6,27a3,3,0,1,1,3,3A3,3,0,0,1,6,27ZM29,47a3,3,0,1,1,3,3A3,3,0,0,1,29,47Zm9.72,8a7,7,0,0,0-3.5-4.25,
        5,5,0,1,0-6.44,0A7,7,0,0,0,25.28,55a24,24,0,0,1-15.1-13H15a1,1,0,0,0,1-1V37a7,7,0,0,0-3.78-6.21,5,5,0,0,0-2.1-8.65A24.05,
        24.05,0,0,1,27.25,8.48a5,5,0,0,0,1.53,2.31A7,7,0,0,0,25,17v4a1,1,0,0,0,1,1H38a1,1,0,0,0,1-1V17a7,7,0,0,0-3.78-6.21,5,5,
        0,0,0,1.53-2.31A24.08,24.08,0,0,1,53.88,22.14a5,5,0,0,0-2.1,8.65A7,7,0,0,0,48,37v4a1,1,0,0,0,1,1h4.82A24,24,0,0,1,38.72,55Z"/>
        </g></svg>
        `,
    david_star: `<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M 16 2.0625 L 15.15625 3.46875 L 11.78125 9
        L 3.3125 9 L 4.21875 10.53125 L 7.53125 16 L 4.21875 21.46875 L 3.3125 23 L 11.78125 23 L 15.15625 28.53125 L 16 29.9375
        L 16.84375 28.53125 L 20.21875 23 L 28.78125 23 L 27.84375 21.46875 L 24.5 16 L 27.84375 10.53125 L 28.78125 9 L 20.21875
        9 L 16.84375 3.46875 Z M 16 5.90625 L 17.875 9 L 14.125 9 Z M 6.875 11 L 10.59375 11 L 8.71875 14.0625 Z M 12.90625 11
        L 19.125 11 L 22.15625 16 L 19.125 21 L 12.90625 21 L 9.90625 16 Z M 21.4375 11 L 25.21875 11 L 23.34375 14.09375 Z M
        23.34375 17.90625 L 25.21875 21 L 21.4375 21 Z M 8.71875 17.9375 L 10.59375 21 L 6.875 21 Z M 14.125 23 L 17.875 23 L 16 26.09375 Z"/>
        </svg>`,
    star: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C12.3806 1 12.7283 1.21607 12.8967 1.55738L15.7543 7.34647L22.1447
         8.28051C22.5212 8.33555 22.8339 8.59956 22.9513 8.96157C23.0687 9.32358 22.9704 9.72083 22.6978 9.98636L18.0746 14.4894L19.1656
         20.851C19.23 21.2261 19.0757 21.6053 18.7678 21.8291C18.4598 22.0528 18.0515 22.0823 17.7146 21.9051L12 18.8998L6.28548
         21.9051C5.94856 22.0823 5.54027 22.0528 5.2323 21.8291C4.92432 21.6053 4.77007 21.2261 4.83442 20.851L5.92551 14.4894L1.3023
         9.98636C1.02968 9.72083 0.931405 9.32358 1.04878 8.96157C1.16616 8.59956 1.47884 8.33555 1.8554 8.28051L8.24577 7.34647L11.1033
         1.55738C11.2718 1.21607 11.6194 1 12 1ZM12 4.25925L9.80674 8.70262C9.6612 8.99747 9.38001 9.20193 9.05466 9.24949L4.14844
         9.9666L7.69776 13.4236C7.93364 13.6534 8.0413 13.9845 7.98564 14.309L7.14821 19.1917L11.5346 16.8849C11.826 16.7317 12.1741
         16.7317 12.4655 16.8849L16.8518 19.1917L16.0144 14.309C15.9588 13.9845 16.0664 13.6534 16.3023 13.4236L19.8516 9.9666L14.9454
         9.24949C14.62 9.20193 14.3389 8.99747 14.1933 8.70262L12 4.25925Z" fill="currentColor"/>
         </svg>
        `,
    plus_folder: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path fill-rule="evenodd" clip-rule="evenodd" d="M4 4C3.73478 4 3.48043 4.10536 3.29289 4.29289C3.10536 4.48043 3 4.73478 3
             5V19C3 19.2652 3.10536 19.5196 3.29289 19.7071C3.48043 19.8946 3.73478 20 4 20H20C20.2652 20 20.5196 19.8946 20.7071
             19.7071C20.8946 19.5196 21 19.2652 21 19V8C21 7.73478 20.8946 7.48043 20.7071 7.29289C20.5196 7.10536 20.2652 7 20 7H11C10.6656
             7 10.3534 6.8329 10.1679 6.5547L8.46482 4H4ZM1.87868 2.87868C2.44129 2.31607 3.20435 2 4 2H9C9.33435 2 9.64658 2.1671 9.83205
             2.4453L11.5352 5H20C20.7957 5 21.5587 5.31607 22.1213 5.87868C22.6839 6.44129 23 7.20435 23 8V19C23 19.7957 22.6839 20.5587
             22.1213 21.1213C21.5587 21.6839 20.7957 22 20 22H4C3.20435 22 2.44129 21.6839 1.87868 21.1213C1.31607 20.5587 1 19.7957 1 19V5C1
             4.20435 1.31607 3.44129 1.87868 2.87868Z" fill="currentColor"/>
             <path fill-rule="evenodd" clip-rule="evenodd" d="M12 10C12.5523 10 13 10.4477 13 11V17C13 17.5523 12.5523 18 12 18C11.4477
             18 11 17.5523 11 17V11C11 10.4477 11.4477 10 12 10Z" fill="currentColor"/>
             <path fill-rule="evenodd" clip-rule="evenodd" d="M8 14C8 13.4477 8.44772 13 9 13H15C15.5523 13 16 13.4477 16 14C16 14.5523
             15.5523 15 15 15H9C8.44772 15 8 14.5523 8 14Z" fill="currentColor"/>
        </svg>
        `,
    file_text: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.87868 1.87868C4.44129 1.31607 5.20435 1 6 1H14.5C14.7652 1 15.0196 1.10536 15.2071
        1.29289L20.7071 6.79289C20.8946 6.98043 21 7.23478 21 7.5V20C21 20.7957 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7957 23 18
        23H6C5.20435 23 4.44129 22.6839 3.87868 22.1213C3.31607 21.5587 3 20.7957 3 20V4C3 3.20435 3.31607 2.44129 3.87868 1.87868ZM6 3C5.73478
        3 5.48043 3.10536 5.29289 3.29289C5.10536 3.48043 5 3.73478 5 4V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043 20.8946 5.73478 21
        6 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V7.91421L14.0858 3H6Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1C14.5523 1 15 1.44772 15 2V7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20
        9H14C13.4477 9 13 8.55228 13 8V2C13 1.44772 13.4477 1 14 1Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 13C7 12.4477 7.44772 12 8 12H16C16.5523 12 17 12.4477 17 13C17 13.5523 16.5523 14 16
        14H8C7.44772 14 7 13.5523 7 13Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 17C7 16.4477 7.44772 16 8 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16
        18H8C7.44772 18 7 17.5523 7 17Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 9C7 8.44772 7.44772 8 8 8H10C10.5523 8 11 8.44772 11 9C11 9.55228 10.5523 10 10
        10H8C7.44772 10 7 9.55228 7 9Z" fill="currentColor"/>
        </svg>
        `,
    arrow_down: `<svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M1.58887 1.48462L7.60496 7.50071L13.621 1.48462" stroke="#606F89" stroke-width="1.80483" stroke-linecap="round"
         stroke-linejoin="round"/>
        </svg>
        `,
    filter: `<svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.959944 2.44604C1.05994 2.58444 6.69957 10.25 6.69957 10.25V18.6141C6.69957 19.1996 7.14954 19.6787 7.7095
            19.6787H9.71937C10.2693 19.6787 10.7293 19.1996 10.7293 18.6141V10.2393C10.7293 10.2393 16.2189 2.76543 16.4789 2.42475C16.7389
            2.08406 16.7089 1.74337 16.7089 1.74337C16.7089 1.15781 16.2589 0.678711 15.699 0.678711H1.71989C1.10993 0.678711 0.709961 1.18975
            0.709961 1.74337C0.709961 1.9563 0.769957 2.21181 0.959944 2.44604Z" fill="currentColor"/>
        </svg>
        `,
    close_filter: `<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5708 4.57172C5.28184 4.7735 5.11214 5.10906 5.11214 5.46159C5.11214 5.67826
        5.17714 5.93826 5.38298 6.17659C5.49131 6.31742 11.6013 14.1174 11.6013 14.1174V20.6283C11.6013 21.2241 12.0888 21.7116 12.6955
        21.7116H14.873C15.4688 21.7116 15.9671 21.2241 15.9671 20.6283V16.2165L5.5708 4.57172ZM16.9361 12.8679C18.6336 10.6979 21.9865
        6.41312 22.1963 6.15492C22.478 5.80826 22.4455 5.46159 22.4455 5.46159C22.4455 4.86576 21.958 4.37826 21.3513 4.37826H9.35661L16.9361
        12.8679Z" fill="currentColor"/>
        <rect x="3.69287" y="2.46582" width="1.52482" height="26.8222" transform="rotate(-41.7581 3.69287 2.46582)" fill="currentColor"/>
        </svg>
        `,
    monitor: `<svg xmlns="http://www.w3.org/2000/svg"
         width="474.000000pt" height="474.000000pt" viewBox="0 0 474.000000 474.000000"
         preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,474.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M1193 3685 c-68 -34 -88 -59 -163 -202 -34 -65 -95 -179 -135 -253
        -64 -120 -176 -331 -313 -590 -57 -108 -106 -200 -170 -320 -29 -55 -64 -105
        -81 -118 -42 -31 -72 -93 -73 -150 0 -41 123 -736 153 -861 14 -59 61 -123
        112 -152 l42 -24 755 -3 c853 -3 808 -7 879 74 54 62 61 103 61 369 l0 225
        110 0 110 0 0 -232 c0 -135 5 -250 11 -273 17 -63 56 -112 112 -140 l51 -25
        750 0 c707 0 753 2 791 19 85 39 124 101 149 236 9 50 43 235 76 411 61 329
        70 411 46 453 -8 14 -12 31 -9 38 3 7 -42 101 -100 210 -183 346 -368 696
        -407 768 -20 39 -84 157 -140 264 -57 107 -116 209 -132 226 -43 50 -118 78
        -194 73 -52 -3 -84 -15 -198 -71 -175 -86 -175 -86 -115 -209 64 -131 64 -131
        198 -70 79 36 113 47 119 38 5 -6 98 -180 207 -386 109 -206 245 -463 302
        -570 62 -118 99 -197 92 -200 -5 -4 -79 -10 -162 -13 -678 -28 -1268 -59
        -1296 -68 -64 -21 -117 -84 -145 -172 -5 -15 -19 -17 -116 -17 -97 0 -111 2
        -116 18 -28 87 -81 150 -145 170 -19 6 -187 18 -374 27 -187 8 -476 22 -642
        30 -166 8 -322 15 -347 15 -26 0 -46 4 -46 8 0 4 68 135 150 291 83 156 221
        417 307 580 86 163 157 297 158 299 2 1 51 -20 111 -48 91 -43 113 -49 132
        -41 23 11 112 178 112 211 0 27 -38 52 -169 115 -106 51 -134 60 -190 63 -56
        3 -74 -1 -118 -23z m-60 -1761 c303 -14 615 -28 692 -32 l140 -7 3 -292 2
        -293 -644 0 -644 0 -7 43 c-9 53 -103 554 -110 585 -3 12 -1 22 5 22 5 0 259
        -11 563 -26z m3041 11 c-1 -5 -28 -151 -60 -322 l-58 -313 -643 0 -643 0 2
        293 3 292 270 13 c149 7 439 21 645 31 409 20 486 21 484 6z" fill="currentColor"/>
        </g>
        </svg>
        `,
    undo: `<svg height="512px" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512"
        width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g><path d="M447.9,368.2c0-16.8,3.6-83.1-48.7-135.7c-35.2-35.4-80.3-53.4-143.3-56.2V96L64,224l192,128v-79.8
        c40,1.1,62.4,9.1,86.7,20c30.9,13.8,55.3,44,75.8,76.6l19.2,31.2H448C448,389.9,447.9,377.1,447.9,368.2z"/></g></svg>`,
    redo: `<svg height="512px" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512"
        width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g><path d="M64,400h10.3l19.2-31.2c20.5-32.7,44.9-62.8,75.8-76.6c24.4-10.9,46.7-18.9,86.7-20V352l192-128L256,96v80.3
        c-63,2.8-108.1,20.7-143.3,56.2c-52.3,52.7-48.7,119-48.7,135.7C64.1,377.1,64,389.9,64,400z"/></g></svg>`,

    eye_big: `<svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.45459 2.27884C12.4694 2.27884 15.158 3.97315 16.4705 6.65384C15.158 9.33452 12.4694 11.0288 9.45459
        11.0288C6.43982 11.0288 3.75118 9.33452 2.43868 6.65384C3.75118 3.97315 6.43982 2.27884 9.45459 2.27884ZM9.45459
        0.687927C5.47732 0.687927 2.08073 3.16179 0.70459 6.65384C2.08073 10.1459 5.47732 12.6197 9.45459 12.6197C13.4319
        12.6197 16.8285 10.1459 18.2046 6.65384C16.8285 3.16179 13.4319 0.687927 9.45459 0.687927ZM9.45459 4.6652C10.5523
        4.6652 11.4432 5.55611 11.4432 6.65384C11.4432 7.75156 10.5523 8.64247 9.45459 8.64247C8.35686 8.64247 7.46595
        7.75156 7.46595 6.65384C7.46595 5.55611 8.35686 4.6652 9.45459 4.6652ZM9.45459 3.07429C7.48186 3.07429 5.87504
        4.68111 5.87504 6.65384C5.87504 8.62656 7.48186 10.2334 9.45459 10.2334C11.4273 10.2334 13.0341 8.62656 13.0341
        6.65384C13.0341 4.68111 11.4273 3.07429 9.45459 3.07429Z" fill="currentColor"/>
    </svg>`,

    parameter: `<svg xmlns="http://www.w3.org/2000/svg"
         width="273.000000pt" height="248.000000pt" viewBox="0 0 273.000000 248.000000"
         preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,248.000000) scale(0.100000,-0.100000)"
        fill="currentColor" stroke="none">
        <path d="M691 2020 c-103 -22 -206 -100 -248 -191 -33 -71 -43 -148 -43 -341
        l0 -178 -74 0 c-91 0 -134 -24 -154 -87 -11 -35 -11 -46 2 -77 23 -54 67 -76
        154 -76 l72 0 0 -266 0 -266 -28 -28 c-19 -19 -47 -32 -85 -39 -70 -15 -104
        -41 -117 -92 -15 -55 10 -107 64 -133 34 -16 46 -18 95 -8 151 28 259 124 297
        264 10 39 14 122 14 311 l0 257 71 0 c87 0 132 19 153 65 20 41 20 66 2 109
        -19 46 -67 66 -155 66 l-71 0 0 195 c0 270 6 279 185 287 118 6 149 20 167 79
        15 51 -3 112 -39 136 -31 20 -189 28 -262 13z"/>
        <path d="M1263 1539 c-82 -24 -201 -277 -239 -504 -19 -121 -14 -361 11 -474
        24 -109 70 -228 124 -322 56 -99 83 -122 145 -123 38 -1 51 4 77 30 20 20 32
        44 36 70 4 35 -2 52 -46 129 -28 48 -64 129 -81 179 -88 266 -59 547 81 791
        44 77 50 94 46 129 -10 74 -81 117 -154 95z"/>
        <path d="M2210 1532 c-73 -36 -81 -109 -21 -210 91 -152 131 -303 131 -492 0
        -189 -40 -340 -131 -492 -25 -44 -41 -82 -41 -102 0 -49 28 -95 69 -112 71
        -30 129 6 192 119 103 188 144 355 144 587 0 237 -41 402 -150 597 -38 68 -54
        86 -88 103 -50 24 -61 25 -105 2z"/>
        <path d="M1489 1121 c-25 -25 -29 -37 -29 -81 0 -52 1 -53 77 -131 l76 -79
        -76 -79 c-76 -78 -77 -79 -77 -131 0 -44 4 -56 29 -81 25 -25 37 -29 84 -29
        l55 0 76 77 76 77 79 -77 c75 -73 81 -77 124 -77 61 0 93 16 112 55 31 67 19
        98 -75 193 l-72 72 72 73 c94 94 106 125 75 192 -19 39 -51 55 -112 55 -43 0
        -49 -4 -124 -77 l-79 -77 -76 77 -76 77 -55 0 c-47 0 -59 -4 -84 -29z"/>
        </g>
        </svg>`,

    valve: `<svg xmlns="http://www.w3.org/2000/svg"
         width="384.000000pt" height="290.000000pt" viewBox="0 0 384.000000 290.000000"
         preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,290.000000) scale(0.100000,-0.100000)"
        fill="currentColor" stroke="none">
        <path d="M1758 2672 c-51 -24 -76 -49 -83 -83 -4 -16 -11 -29 -16 -29 -5 0 -9
        -23 -9 -51 l0 -50 -84 3 c-54 2 -82 -1 -79 -7 3 -5 0 -4 -8 3 -7 6 -34 12 -60
        12 -26 0 -50 4 -53 9 -3 5 -62 12 -131 16 -69 4 -128 11 -131 16 -17 29 -124
        -21 -124 -57 0 -8 -4 -13 -8 -10 -5 3 -9 -51 -9 -119 l0 -124 29 -28 c29 -27
        32 -28 126 -25 53 1 101 7 108 13 6 5 36 9 65 9 30 -1 86 4 124 9 39 6 91 12
        116 14 25 2 52 8 60 13 19 11 460 11 479 -1 8 -5 35 -10 60 -11 25 -1 52 -4
        60 -7 19 -8 131 -17 193 -16 27 0 55 -5 62 -11 8 -7 37 -10 69 -8 30 1 52 -1
        49 -6 -10 -16 97 1 116 18 17 15 19 33 20 159 1 78 -2 147 -6 152 -27 37 -113
        60 -128 35 -4 -6 -28 -10 -53 -10 -67 2 -196 -12 -202 -21 -3 -4 -69 -11 -148
        -15 l-142 -7 0 39 c0 41 -24 119 -42 142 -20 24 -96 52 -140 52 -24 -1 -60 -8
        -80 -18z m-571 -188 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
        m501 -261 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m335 0 c-7
        -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-886 -59 c-3 -3 -12 -4
        -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1366 -1 c-7 -2 -19 -2 -25 0 -7 3 -2
        5 12 5 14 0 19 -2 13 -5z"/>
        <path d="M1820 2080 c0 -6 -28 -10 -62 -10 -113 0 -108 5 -108 -126 l0 -114
        -31 0 c-17 0 -28 -4 -24 -10 4 -6 -35 -9 -112 -8 -94 1 -107 3 -63 8 37 5 10
        7 -85 7 -118 -1 -127 -2 -60 -7 76 -6 73 -6 -47 -8 -84 -1 -128 2 -128 8 0 7
        -24 10 -62 8 l-63 -3 -7 -47 c-5 -25 -7 -340 -6 -700 l3 -653 870 -2 c494 -2
        864 1 856 6 -9 6 -10 10 -1 13 9 3 11 160 10 650 0 465 3 653 11 671 9 21 8
        28 -10 46 -18 18 -33 21 -107 21 -54 0 -83 -4 -79 -10 3 -5 -3 -10 -14 -10
        -12 0 -21 5 -21 10 0 6 -79 10 -217 10 -154 0 -214 -3 -203 -11 12 -8 11 -9
        -5 -6 -11 2 -19 9 -18 15 2 7 -2 12 -9 12 -8 0 -9 28 -5 100 4 65 3 100 -4
        100 -6 0 -8 4 -5 9 7 11 -43 21 -105 21 -27 0 -49 5 -49 10 0 6 -9 10 -20 10
        -11 0 -20 -4 -20 -10z m803 -1647 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0
        35 -2 23 -4z"/>
        <path d="M2993 1997 c-24 -6 -43 -14 -43 -18 0 -5 -6 -9 -14 -9 -7 0 -30 -18
        -50 -40 l-36 -40 0 -763 c0 -711 1 -764 18 -789 24 -37 70 -71 83 -62 7 4 9 3
        6 -4 -10 -15 62 -25 119 -16 38 5 55 15 95 56 31 32 46 55 44 68 -2 11 0 22 5
        25 12 7 12 1433 0 1440 -4 3 -7 13 -6 22 2 10 -5 26 -15 37 -10 10 -16 22 -14
        26 5 8 -17 28 -52 47 -38 21 -96 29 -140 20z"/>
        <path d="M593 1993 c4 -6 -3 -8 -18 -5 -15 2 -25 0 -25 -7 0 -6 -6 -11 -13
        -11 -15 0 -46 -34 -69 -77 -17 -31 -18 -85 -18 -777 0 -515 3 -747 11 -751 5
        -4 14 -17 19 -30 5 -13 25 -36 45 -51 30 -23 47 -28 93 -28 32 0 67 2 79 5 44
        10 121 95 115 126 -1 6 2 14 8 18 14 9 14 1300 0 1398 -6 40 -16 83 -22 97 -6
        14 -11 28 -10 32 1 3 -5 12 -13 18 -8 7 -15 9 -15 5 0 -5 -9 2 -19 14 -14 17
        -34 23 -86 28 -45 4 -66 3 -62 -4z"/>
        </g>
        </svg>`,

    plc: `<svg xmlns="http://www.w3.org/2000/svg"
     width="405.000000pt" height="351.000000pt" viewBox="0 0 405.000000 351.000000"
     preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,351.000000) scale(0.100000,-0.100000)"
    fill="currentColor" stroke="none">
    <path d="M1924 3109 c-3 -5 -10 -7 -15 -4 -5 4 -9 1 -9 -4 0 -6 -5 -11 -12
    -11 -6 0 -20 -8 -31 -17 -15 -13 -18 -14 -12 -3 5 10 1 8 -13 -4 -12 -10 -21
    -21 -20 -25 2 -20 -4 -31 -14 -25 -7 4 -8 3 -4 -5 4 -6 2 -18 -4 -26 -10 -12
    -10 -79 1 -142 8 -49 180 -144 200 -111 7 10 9 10 9 1 0 -9 20 -13 65 -13 36
    0 65 -4 65 -9 0 -5 17 -9 38 -9 20 0 46 -3 57 -5 11 -3 34 -6 50 -6 24 -1 27
    -3 15 -12 -12 -9 -11 -10 8 -6 12 3 22 2 22 -3 0 -4 20 -11 45 -14 25 -4 45
    -11 45 -17 0 -5 7 -9 15 -9 8 0 15 -5 15 -10 0 -6 3 -10 8 -9 15 2 52 -14 52
    -23 0 -5 4 -7 8 -4 5 3 19 -4 30 -15 12 -11 22 -17 22 -13 0 4 9 -2 20 -14 23
    -24 35 -29 25 -9 -4 6 10 -6 30 -28 20 -22 40 -39 44 -38 10 3 96 -87 96 -101
    1 -6 7 -10 14 -8 7 1 10 -2 6 -8 -3 -5 1 -13 9 -16 14 -5 76 -87 76 -100 0 -2
    9 -13 20 -24 11 -11 20 -27 20 -36 0 -8 11 -32 25 -52 14 -20 25 -47 25 -59 0
    -12 5 -25 10 -28 6 -4 8 -10 5 -15 -3 -4 2 -20 10 -36 7 -15 12 -31 9 -36 -3
    -4 0 -8 7 -8 7 0 9 -10 7 -25 -3 -13 -1 -22 3 -19 5 3 9 -17 9 -45 0 -27 4
    -52 8 -55 9 -5 6 -270 -4 -348 -3 -27 -9 -48 -14 -48 -4 0 -5 -11 -2 -25 2
    -14 0 -25 -5 -25 -5 0 -10 -12 -11 -27 -1 -16 -6 -29 -11 -31 -5 -2 -7 -12 -4
    -22 3 -11 0 -20 -6 -20 -6 0 -11 -7 -11 -15 0 -8 -5 -15 -10 -15 -6 0 -9 -3
    -8 -7 1 -5 -8 -27 -20 -50 -12 -24 -19 -43 -15 -43 5 0 0 -7 -10 -14 -10 -8
    -18 -17 -18 -20 3 -20 -4 -32 -49 -83 -82 -94 -153 -166 -187 -189 -18 -12
    -29 -24 -26 -28 4 -3 -1 -6 -10 -6 -8 0 -22 -6 -29 -13 -7 -7 -24 -18 -38 -24
    -14 -7 -48 -26 -77 -42 -28 -16 -54 -27 -57 -24 -3 4 -6 1 -6 -5 0 -7 -8 -12
    -18 -12 -10 0 -22 -3 -26 -7 -4 -5 -11 -8 -14 -9 -4 0 -14 -3 -22 -6 -65 -22
    -84 -27 -91 -22 -5 3 -9 0 -9 -5 0 -6 -21 -11 -50 -11 -27 0 -50 -4 -50 -9 0
    -6 -10 -8 -22 -6 -12 3 -26 -1 -30 -8 -6 -9 -8 -9 -8 1 0 20 -111 7 -170 -21
    -31 -14 -103 -79 -110 -98 -8 -24 -3 -161 7 -168 4 -3 16 -18 26 -33 10 -16
    24 -28 32 -28 7 0 20 -6 27 -12 7 -7 24 -15 38 -16 14 -2 29 -8 35 -13 12 -12
    78 -12 91 1 5 5 34 10 64 11 30 1 60 6 67 11 7 5 14 5 17 -1 7 -10 46 -3 46 9
    0 4 6 8 13 7 6 0 28 1 47 2 27 1 31 4 20 11 -9 6 -10 10 -3 10 6 0 15 -5 18
    -11 5 -7 14 -4 28 8 11 10 17 12 13 6 -11 -19 24 -16 59 5 30 19 83 37 98 34
    4 -1 7 3 7 9 0 6 6 9 12 7 7 -3 21 4 32 14 11 10 27 18 37 18 9 0 22 7 29 15
    7 9 19 14 27 11 7 -3 13 -1 13 4 0 6 6 10 14 10 8 0 16 7 20 15 3 8 14 15 25
    15 10 0 21 7 25 15 3 8 12 15 21 15 8 0 15 5 15 10 0 6 7 10 17 10 9 0 23 6
    32 14 9 8 41 37 72 65 31 28 60 51 65 51 11 0 39 30 41 44 1 8 66 83 123 143
    14 15 18 23 10 18 -11 -6 -10 -3 3 12 9 11 17 25 17 32 0 7 5 9 10 6 6 -3 10
    1 10 9 0 8 8 19 18 25 9 5 15 12 13 16 -6 11 21 65 30 59 5 -3 9 4 9 15 0 12
    5 21 11 21 5 0 7 4 4 10 -3 5 -1 10 5 10 6 0 9 4 5 9 -3 5 2 16 10 24 14 14
    22 41 24 75 1 7 6 10 13 6 8 -5 9 -2 5 9 -3 9 -2 19 4 22 5 4 7 10 4 15 -3 5
    1 15 9 23 8 8 13 17 10 20 -4 3 -2 12 2 19 5 7 10 24 12 38 2 14 7 26 13 28 5
    2 6 15 3 28 -3 13 -2 30 3 37 5 6 11 32 12 57 2 25 6 47 9 50 11 8 14 251 4
    302 -5 26 -5 45 -1 42 12 -7 11 9 0 22 -8 8 -23 94 -23 134 0 15 -8 45 -24 85
    -4 11 -9 25 -9 30 -1 6 -8 24 -15 40 -8 17 -14 35 -15 40 0 6 -4 16 -8 22 -5
    7 -10 23 -12 35 -3 12 -8 24 -13 27 -4 3 -16 26 -27 51 -10 25 -32 64 -47 87
    -15 23 -28 49 -28 58 0 8 -7 18 -15 21 -8 4 -15 10 -15 14 0 5 -13 24 -30 42
    -16 18 -30 36 -30 41 0 5 -11 18 -25 29 -14 11 -25 25 -25 32 0 16 -120 136
    -136 136 -7 0 -22 12 -34 27 -11 15 -29 28 -38 29 -9 2 -21 10 -26 18 -9 13
    -12 12 -24 -2 -7 -9 -11 -11 -7 -5 7 16 -14 47 -35 51 -8 2 -19 8 -25 15 -5 7
    -28 21 -50 32 -22 11 -44 25 -48 32 -4 7 -13 13 -18 13 -6 0 -8 -4 -5 -9 3 -4
    -10 -1 -29 7 -19 9 -32 19 -29 24 3 4 -4 8 -15 8 -12 0 -21 5 -21 11 0 6 -9 9
    -20 6 -10 -3 -20 -1 -22 4 -2 5 -22 10 -45 12 -26 1 -43 7 -43 15 0 6 -10 12
    -23 12 -13 0 -27 4 -33 9 -15 14 -84 23 -84 11 0 -5 -5 -10 -11 -10 -5 0 -7 5
    -4 10 3 6 -4 10 -17 10 -13 0 -28 4 -33 9 -6 5 -31 6 -57 2 -36 -6 -48 -5 -48
    5 0 15 -167 18 -176 3z"/>
    <path d="M1680 2480 c0 -5 -13 -10 -30 -10 -16 0 -30 -5 -30 -11 0 -5 -4 -8
    -8 -5 -10 6 -72 -57 -72 -73 0 -6 -4 -11 -9 -11 -5 0 -9 -38 -9 -85 0 -47 3
    -85 7 -85 4 0 14 -12 21 -27 19 -38 51 -73 66 -73 7 0 30 -20 52 -45 22 -25
    45 -45 51 -45 6 0 11 -7 11 -16 0 -8 5 -12 10 -9 6 3 10 1 10 -5 0 -6 25 -36
    55 -67 30 -31 55 -57 55 -59 0 -1 -9 -4 -20 -7 -11 -3 -18 -1 -14 4 3 5 0 9
    -5 9 -6 0 -11 -3 -11 -7 0 -5 -253 -8 -562 -9 -560 -1 -618 -2 -618 -16 0 -5
    -9 -8 -20 -8 -11 0 -20 -3 -20 -8 0 -4 -10 -18 -23 -32 -22 -24 -38 -53 -42
    -75 0 -5 -5 -22 -9 -38 -5 -18 -4 -32 4 -42 7 -8 10 -22 6 -30 -3 -9 0 -23 7
    -30 32 -38 77 -83 77 -78 0 3 7 -1 16 -8 22 -18 34 -20 154 -23 92 -3 96 -2
    30 3 -65 6 -57 7 60 8 82 0 121 -3 100 -7 l-35 -7 38 -2 c20 0 37 4 37 9 0 6
    16 10 36 10 21 0 33 -4 29 -10 -4 -7 122 -10 372 -9 208 1 365 5 348 9 l-30 8
    36 1 c40 1 45 -13 20 -59 -14 -26 -51 -65 -51 -55 0 9 -24 -16 -44 -47 -10
    -16 -25 -28 -32 -28 -8 0 -14 -4 -14 -8 0 -11 -139 -150 -144 -144 -3 2 -2 -6
    1 -19 4 -16 3 -20 -6 -15 -9 6 -12 -13 -10 -78 1 -69 5 -89 20 -102 10 -9 16
    -22 12 -28 -3 -6 -3 -8 2 -4 4 4 21 -4 37 -17 16 -14 40 -25 54 -25 13 0 24
    -4 24 -9 0 -6 18 -9 40 -9 22 0 40 5 40 10 0 6 11 8 25 6 13 -3 22 -1 19 3 -3
    5 3 9 13 9 18 0 241 212 270 257 9 13 19 23 24 23 10 0 249 242 249 252 0 5 7
    8 15 8 8 0 15 6 15 13 0 6 13 21 29 32 16 11 45 40 65 65 20 25 41 51 46 58 6
    6 8 12 4 12 -3 0 -2 6 4 13 12 16 14 147 1 147 -6 0 -9 6 -7 13 1 6 -157 171
    -352 366 -194 195 -350 349 -346 343 5 -8 2 -10 -8 -6 -9 3 -16 10 -16 15 0 5
    -11 9 -24 9 -14 0 -28 5 -31 10 -3 6 -19 10 -36 10 -16 0 -29 -4 -29 -10z m73
    -1017 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z"/>
    </g>
    </svg>`,
    collapse: `<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.2426 5.74264L6 1.5L1.75736 5.74264" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
    expand: `<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.75736 1.25736L6 5.5L10.2426 1.25736" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
    library_settings: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.24 23H11.8C11 23 10.25 22.69 9.68005 22.12C9.11005 21.55 8.80005 20.8 8.80005
        20V19.82C8.80005 19.65 8.75005 19.47 8.67005 19.32C8.58005 19.17 8.46005 19.04 8.30005
        18.95L7.87005 18.7C7.57005 18.53 7.18005 18.53 6.87005 18.7L6.69005 18.8C6.03005 19.18 5.22005 19.29 4.45005 19.08C3.69005
        18.87 3.02005 18.37 2.63005 17.68L2.40005 17.31C2.00005 16.61 1.89005 15.81 2.10005 15.03C2.31005 14.26 2.80005 13.61
        3.49005 13.21L3.62005 13.13C3.79005 13.03 3.91005 12.91 4.00005 12.75C4.09005 12.6 4.13005 12.42 4.13005 12.25V11.73C4.13005
        11.56 4.08005 11.39 4.00005 11.24C3.93005 11.1 3.80005 10.97 3.65005 10.89L3.45005 10.76C2.80005 10.38 2.31005 9.74 2.10005
        8.96C1.90005 8.19 2.00005 7.38 2.40005 6.69L2.62005 6.31C3.01005 5.63 3.68005 5.12 4.44005 4.92C5.21005 4.71 6.02005 4.82 6.71005
        5.22L6.83005 5.28C7.20005 5.49 7.56005 5.47 7.87005 5.29L8.30005 5.05C8.45005 4.96 8.58005 4.83 8.67005 4.68C8.75005 4.53 8.80005
        4.35 8.80005 4.18V4C8.80005 3.2 9.11005 2.45 9.68005 1.88C10.2401 1.31 11 1 11.8 1H12.24C13.04 1 13.79 1.31 14.36 1.88C14.93
        2.45 15.24 3.2 15.24 4V4.18C15.24 4.35 15.29 4.53 15.37 4.68C15.46 4.83 15.58 4.96 15.74 5.04L16.17 5.29C16.47 5.46 16.86 5.46
        17.17 5.29L17.35 5.19C18.01 4.81 18.83 4.7 19.6 4.91C20.3701 5.12 21.0201 5.61 21.42 6.3L21.64 6.68C22.04 7.37 22.15 8.18 21.9401
        8.95C21.7301 9.72 21.24 10.37 20.55 10.77L20.4 10.87C20.23 10.97 20.1101 11.09 20.0201 11.24C19.93 11.39 19.88 11.57 19.89
        11.74V12.24C19.89 12.42 19.9401 12.59 20.0201 12.75C20.1101 12.9 20.23 13.03 20.39 13.12L20.51 13.18C21.23 13.6 21.7301 14.25
        21.9401 15.02C22.15 15.79 22.04 16.6 21.64 17.29L21.43 17.67C21.0201 18.37 20.3801 18.87 19.6 19.07C18.84 19.27 18.01 19.16 17.33
        18.77L17.21 18.71C16.85 18.5 16.4901 18.52 16.18 18.69L15.75 18.94C15.59 19.03 15.47 19.16 15.38 19.31C15.29 19.46 15.25 19.63
        15.25 19.81V20C15.25 20.8 14.94 21.55 14.37 22.12C13.79 22.69 13.04 23 12.24 23ZM7.37005 16.57C7.90005 16.57 8.41005 16.71 8.87005
        16.97L9.30005 17.22C9.75005 17.48 10.13 17.86 10.39 18.31C10.6501 18.77 10.79 19.28 10.79 19.81V20C10.79 20.27 10.89 20.52 11.08
        20.71C11.28 20.9 11.53 21 11.8 21H12.24C12.51 21 12.76 20.9 12.95 20.71C13.14 20.52 13.24 20.27 13.24 20V19.82C13.24 19.3 13.38
        18.78 13.64 18.32C13.9001 17.86 14.28 17.49 14.74 17.22L15.17 16.97C16.08 16.44 17.26 16.44 18.17 16.97L18.2901 17.03C18.5401
        17.18 18.82 17.21 19.08 17.14C19.34 17.07 19.5501 16.91 19.6901 16.68L19.9 16.3C20.04 16.06 20.07 15.79 20.01 15.53C19.9401 15.28
        19.7701 15.05 19.5401 14.92L19.42 14.86C18.93 14.58 18.5501 14.2 18.2901 13.74C18.0301 13.28 17.89 12.76 17.89 12.24V11.74C17.89
        11.22 18.0301 10.69 18.2901 10.24C18.5501 9.78 18.93 9.4 19.39 9.14L19.5301 9.06C19.7701 8.92 19.93 8.7 20 8.45C20.07 8.2 20.03
        7.92 19.9 7.69L19.68 7.31C19.5501 7.08 19.33 6.92 19.07 6.85C18.82 6.78 18.54 6.82 18.31 6.95L18.13 7.05C17.28 7.55 16.07 7.56
        15.16 7.03L14.73 6.78C14.28 6.52 13.9001 6.14 13.64 5.69C13.38 5.23 13.24 4.71 13.24 4.19V4C13.24 3.74 13.13 3.48 12.95 3.29C12.76
        3.11 12.5 3 12.24 3H11.8C11.54 3 11.28 3.11 11.09 3.29C10.91 3.48 10.8 3.74 10.8 4V4.18C10.8 4.71 10.66 5.22 10.4 5.68C10.14 6.13
        9.76005 6.51 9.30005 6.78L8.87005 7.02C7.95005 7.55 6.77005 7.55 5.87005 7.02L5.75005 6.96C5.49005 6.81 5.22005 6.78 4.96005
        6.85C4.71005 6.91 4.49005 7.08 4.36005 7.31L4.13005 7.69C4.00005 7.92 3.97005 8.19 4.03005 8.45C4.10005 8.71 4.27005 8.92 4.50005
        9.05L4.70005 9.18C5.10005 9.41 5.48005 9.78 5.74005 10.24C6.00005 10.7 6.15005 11.21 6.15005 11.73V12.25C6.15005 12.77 6.02005
        13.29 5.75005 13.75C5.49005 14.21 5.11005 14.59 4.65005 14.85L4.52005 14.93C4.27005 15.07 4.11005 15.29 4.04005 15.55C3.97005 15.81
        4.01005 16.08 4.14005 16.31L4.36005 16.69C4.49005 16.92 4.71005 17.09 4.97005 17.16C5.23005 17.23 5.50005 17.19 5.73005 17.06L5.91005
        16.96C6.32005 16.71 6.84005 16.57 7.37005 16.57Z" fill="currentColor"/>
        <path d="M12.02 16C9.81002 16 8.02002 14.21 8.02002 12C8.02002 9.79 9.81002 8 12.02 8C14.23 8 16.02 9.79 16.02 12C16.02
        14.21 14.22 16 12.02 16ZM12.02 10C10.92 10 10.02 10.9 10.02 12C10.02 13.1 10.92 14 12.02 14C13.12 14 14.02 13.1 14.02
        12C14.02 10.9 13.12 10 12.02 10Z" fill="currentColor"/>
        </svg>
        `,

    filter_list: `
        <svg width="800px" height="800px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="System" transform="translate(-528.000000, -240.000000)" fill-rule="nonzero">
                    <g id="fliter_2_fill" transform="translate(528.000000, 240.000000)">
                        <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439
                        L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662
                        12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994
                        L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931
                        L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831
                        C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862
                         L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114
                         L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389
                         C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331
                         C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072
                         C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639
                         C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931
                         L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176
                         L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z" id="MingCute" fill-rule="nonzero"></path>
                        <path d="M14,16.5 C14.8284,16.5 15.5,17.1716 15.5,18 C15.5,18.7796706 14.9050879,19.4204457 14.1444558,19.4931332
                        L14,19.5 L10,19.5 C9.17157,19.5 8.5,18.8284 8.5,18 C8.5,17.2203294 9.09488554,16.5795543 9.85553954,16.5068668 L10,16.5
                        L14,16.5 Z M17,10.5 C17.8284,10.5 18.5,11.1716 18.5,12 C18.5,12.8284 17.8284,13.5 17,13.5 L7,13.5 C6.17157,13.5
                        5.5,12.8284 5.5,12 C5.5,11.1716 6.17157,10.5 7,10.5 L17,10.5 Z M20,4.5 C20.8284,4.5 21.5,5.17157 21.5,6 C21.5,6.82843
                        20.8284,7.5 20,7.5 L4,7.5 C3.17157,7.5 2.5,6.82843 2.5,6 C2.5,5.17157 3.17157,4.5 4,4.5 L20,4.5 Z" fill="currentColor"></path>
                    </g>
                </g>
            </g>
        </svg>`,

    transfer: `
       <svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512.008 512.008" xml:space="preserve"><g><g>
            <path d="M106.669,0.005H64.003c-23.531,0-42.667,19.136-42.667,42.667v42.667c0,23.531,19.136,42.667,42.667,42.667v21.333
            c0,11.776,9.536,21.333,21.333,21.333s21.333-9.557,21.333-21.333v-21.333c23.531,0,42.667-19.136,42.667-42.667V42.672
            C149.336,19.141,130.2,0.005,106.669,0.005z"/>
            </g></g><g><g>
            <path d="M271.086,411.588l-64.001-63.999c-8.341-8.341-21.824-8.341-30.165,0s-8.341,21.824,0,30.165l27.584,27.584h-18.603
            c-11.797,0-21.333,9.557-21.333,21.333s9.536,21.333,21.333,21.333h18.603l-27.584,27.584c-8.341,8.341-8.341,21.824,0,30.165
            c4.16,4.16,9.621,6.251,15.083,6.251c5.461,0,10.923-2.091,15.083-6.251l64-64C279.427,433.412,279.427,419.929,271.086,411.588z"
            /></g></g><g><g>
            <path d="M123.797,384.949c-7.297-7.809-12.524-17.43-15.169-27.755c-2.923-11.435-14.528-18.325-25.941-15.424
            c-11.413,2.923-18.304,14.528-15.381,25.941c4.373,17.259,13.12,33.28,25.28,46.336c4.181,4.501,9.899,6.784,15.595,6.784
            c5.227,0,10.453-1.899,14.549-5.717C131.349,407.071,131.839,393.589,123.797,384.949z"/>
            </g></g><g><g>
            <path d="M85.336,213.339c-11.797,0-21.333,9.557-21.333,21.333v42.667c0,11.776,9.536,21.333,21.333,21.333
            s21.333-9.557,21.333-21.333v-42.667C106.669,222.896,97.133,213.339,85.336,213.339z"/>
            </g></g><g><g>
            <path d="M448.003,384.005V170.672c0-58.816-47.851-106.667-106.667-106.667h-33.835l27.584-27.584
            c8.341-8.341,8.341-21.824,0-30.165s-21.824-8.341-30.165,0l-63.979,63.979c-1.984,1.984-3.541,4.331-4.629,6.955
            c-2.155,5.205-2.155,11.093,0,16.299c1.088,2.624,2.645,4.971,4.629,6.955l63.979,63.979c4.16,4.16,9.621,6.251,15.083,6.251
            c5.462,0,10.923-2.091,15.083-6.251c8.341-8.341,8.341-21.824,0-30.165l-27.584-27.584h33.835c35.285,0,64,28.715,64,64v213.333
            c-23.531,0-42.667,19.136-42.667,42.667v42.667c0,23.531,19.136,42.667,42.667,42.667h42.667
            c23.531,0,42.667-19.136,42.667-42.667v-42.667C490.669,403.141,471.533,384.005,448.003,384.005z"/>
            </g></g>
        </svg>`,
    eraser: `<svg id="svg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="400"
        height="371.42857142857144" viewBox="0, 0, 400,371.42857142857144"><g id="svgg"><path id="path0" d="M265.613 0.723 C 265.204 0.888,264.737
        0.945,264.575 0.851 C 264.412 0.757,264.032 0.885,263.729 1.136 C 263.427 1.387,263.073 1.487,262.943 1.357 C 262.814 1.228,262.525
        1.266,262.302 1.442 C 262.079 1.618,261.227 1.971,260.409 2.227 C 254.544 4.063,255.748 2.999,226.005 32.632 C 211.990 46.595,200.379
        57.888,200.202 57.727 C 200.025 57.567,199.982 57.613,200.105 57.830 C 200.397 58.344,171.257 87.481,170.835 87.096 C 170.658 86.935,170.614
        86.982,170.739 87.201 C 171.031 87.715,163.448 95.285,163.026 94.901 C 162.850 94.741,162.807 94.787,162.930 95.004 C 163.222 95.519,126.276
        132.463,125.854 132.078 C 125.674 131.915,125.662 132.009,125.825 132.291 C 126.208 132.953,71.597 187.541,70.951 187.142 C 70.680 186.974,70.617
        187.036,70.784 187.306 C 70.950 187.574,58.948 199.871,39.073 219.798 C 21.485 237.431,6.525 252.614,5.827 253.538 C 4.747 254.969,2.244
        259.463,2.212 260.030 C 2.206 260.130,1.944 260.926,1.630 261.798 C 0.085 266.085,-0.396 271.512,0.403 275.651 C 0.600 276.673,0.781
        277.760,0.803 278.067 C 0.826 278.374,1.004 278.826,1.198 279.073 C 1.392 279.320,1.443 279.696,1.311 279.909 C 1.179 280.123,1.266
        280.297,1.505 280.297 C 1.743 280.297,1.842 280.548,1.724 280.855 C 1.607 281.162,1.636 281.413,1.789 281.413 C 2.173 281.413,2.996
        283.678,2.754 284.069 C 2.647 284.244,2.745 284.387,2.974 284.387 C 3.203 284.387,3.295 284.540,3.179 284.727 C 3.064 284.914,3.096
        285.123,3.250 285.191 C 3.405 285.260,4.190 286.320,4.995 287.546 C 6.417 289.714,65.243 349.116,65.810 348.956 C 66.030 348.894,70.662
        352.166,72.613 353.762 C 72.987 354.068,74.158 354.844,75.215 355.486 C 76.273 356.129,77.260 356.830,77.411 357.045 C 77.561 357.259,77.686
        357.312,77.689 357.161 C 77.693 357.010,78.573 357.439,79.647 358.113 C 80.720 358.788,83.857 360.409,86.617 361.716 C 89.377 363.022,91.719
        364.183,91.822 364.295 C 91.924 364.407,92.175 364.503,92.379 364.510 C 93.347 364.539,95.167 365.150,95.167 365.445 C 95.167 365.627,95.418
        365.680,95.725 365.562 C 96.032 365.444,96.283 365.528,96.283 365.748 C 96.283 365.968,96.533 366.051,96.840 365.934 C 97.147 365.816,97.398
        365.877,97.398 366.069 C 97.398 366.262,98.987 366.818,100.929 367.306 C 102.872 367.793,104.461 368.307,104.461 368.448 C 104.461
        368.589,104.796 368.616,105.204 368.509 C 105.613 368.402,105.948 368.486,105.948 368.695 C 105.948 368.904,106.264 368.993,106.649 368.892
        C 107.035 368.791,107.464 368.891,107.602 369.114 C 107.739 369.337,108.242 369.422,108.718 369.302 C 109.195 369.183,109.692 369.258,109.823
        369.470 C 109.954 369.682,110.309 369.761,110.611 369.645 C 110.914 369.529,111.369 369.605,111.622 369.814 C 111.875 370.023,112.500
        370.206,113.011 370.220 C 113.522 370.234,114.526 370.327,115.242 370.426 C 120.496 371.153,123.335 371.335,129.368 371.335 C 136.016
        371.335,138.966 371.111,146.097 370.063 C 146.812 369.958,147.732 369.832,148.141 369.785 C 148.550 369.737,149.094 369.563,149.349 369.399
        C 149.605 369.235,149.989 369.209,150.202 369.341 C 150.416 369.473,150.832 369.380,151.127 369.135 C 151.422 368.890,151.911 368.785,152.214
        368.901 C 152.517 369.017,152.867 368.946,152.993 368.742 C 153.119 368.538,153.543 368.473,153.935 368.597 C 154.363 368.733,154.647
        368.648,154.647 368.383 C 154.647 368.126,154.956 368.024,155.390 368.137 C 155.799 368.244,156.134 368.161,156.134 367.952 C 156.134
        367.742,156.468 367.659,156.877 367.766 C 157.286 367.873,157.621 367.822,157.621 367.653 C 157.621 367.484,158.164 367.229,158.829 367.087
        C 159.493 366.945,160.218 366.684,160.438 366.507 C 160.659 366.330,161.244 366.111,161.739 366.020 C 162.234 365.929,162.681 365.821,162.732
        365.780 C 162.783 365.740,162.951 365.675,163.104 365.637 C 163.257 365.599,164.028 365.287,164.816 364.943 C 165.605 364.599,166.400
        364.410,166.582 364.523 C 166.765 364.635,166.914 364.551,166.914 364.334 C 166.914 364.118,167.089 363.941,167.302 363.941 C 167.514
        363.941,169.044 363.276,170.700 362.464 C 172.357 361.652,173.855 361.077,174.031 361.185 C 174.206 361.294,174.349 361.195,174.349 360.967
        C 174.349 360.738,174.517 360.654,174.721 360.781 C 174.926 360.907,175.093 360.875,175.094 360.710 C 175.094 360.544,176.056 359.913,177.231
        359.308 C 178.407 358.702,179.452 358.108,179.554 357.989 C 180.029 357.432,183.200 355.815,183.408 356.023 C 183.537 356.152,183.643
        356.090,183.643 355.886 C 183.643 355.681,184.602 354.859,185.774 354.058 C 193.375 348.866,196.373 345.933,294.473 247.733 C 348.171
        193.980,392.596 149.414,393.196 148.699 C 393.795 147.983,394.685 146.812,395.173 146.097 C 395.661 145.381,396.209 144.693,396.389 144.568
        C 396.570 144.443,396.702 144.192,396.682 144.010 C 396.614 143.385,397.917 140.149,398.237 140.149 C 398.413 140.149,398.452 139.979,398.324
        139.772 C 398.196 139.565,398.346 138.886,398.657 138.262 C 400.317 134.934,400.329 126.155,398.679 121.433 C 398.168 119.970,397.738
        118.690,397.724 118.587 C 397.348 115.892,393.737 111.301,385.357 102.862 C 381.130 98.607,378.153 95.328,378.300 95.091 C 378.455 94.840,378.383
        94.786,378.113 94.953 C 377.565 95.291,340.806 58.433,341.145 57.884 C 341.274 57.676,341.205 57.613,340.993 57.744 C 340.545 58.022,303.853
        21.349,304.005 20.775 C 304.062 20.564,303.967 20.478,303.795 20.584 C 303.623 20.690,300.817 18.095,297.559 14.817 C 291.650 8.872,287.145
        5.055,284.610 3.846 C 283.874 3.495,283.271 3.062,283.271 2.883 C 283.271 2.705,283.122 2.651,282.939 2.764 C 282.756 2.877,281.962
        2.688,281.173 2.344 C 280.385 2.000,279.614 1.687,279.461 1.649 C 279.308 1.611,279.140 1.547,279.089 1.507 C 279.038 1.467,278.345
        1.355,277.548 1.259 C 276.751 1.162,275.800 0.923,275.435 0.728 C 274.638 0.301,266.671 0.298,265.613 0.723 M273.234 23.518 C 275.797
        24.121,278.703 26.917,325.941 74.224 C 373.979 122.333,376.518 124.967,376.725 126.909 C 376.782 127.444,376.940 127.993,377.076 128.129
        C 377.473 128.526,377.361 131.160,376.937 131.422 C 376.724 131.553,376.651 131.982,376.776 132.373 C 376.902 132.771,376.827 133.086,376.605
        133.086 C 376.387 133.086,376.243 133.211,376.285 133.364 C 376.327 133.518,376.059 134.229,375.690 134.944 C 374.988 136.303,215.341
        296.283,214.686 296.283 C 214.225 296.283,104.089 186.187,104.089 185.726 C 104.089 185.544,140.098 149.366,184.108 105.329 C 262.638
        26.752,265.539 23.924,267.971 23.552 C 268.245 23.510,268.528 23.418,268.599 23.347 C 268.752 23.193,272.433 23.329,273.234 23.518 M91.562
        205.324 C 91.437 205.526,91.496 205.591,91.694 205.469 C 92.177 205.171,171.113 284.146,170.740 284.555 C 170.578 284.732,170.624 284.777,170.841
        284.653 C 171.058 284.530,177.499 290.699,185.153 298.361 L 199.071 312.293 189.405 321.876 C 180.997 330.212,174.338 336.059,173.254 336.059
        C 173.073 336.059,172.869 336.195,172.800 336.360 C 172.440 337.233,159.771 343.990,158.494 343.990 C 158.218 343.990,157.993 344.157,157.993
        344.362 C 157.993 344.566,157.882 344.624,157.748 344.489 C 157.613 344.354,157.321 344.388,157.097 344.563 C 156.366 345.138,151.700
        346.529,151.310 346.288 C 151.101 346.159,150.929 346.241,150.929 346.472 C 150.929 346.721,150.629 346.795,150.186 346.654 C 149.777
        346.524,149.442 346.582,149.442 346.782 C 149.442 346.983,148.731 347.264,147.862 347.407 C 146.993 347.550,146.081 347.830,145.834 348.029
        C 145.588 348.228,145.204 348.279,144.981 348.141 C 144.759 348.004,144.342 348.087,144.054 348.326 C 143.766 348.565,143.202 348.656,142.800
        348.529 C 142.399 348.401,141.963 348.470,141.832 348.682 C 141.597 349.062,140.974 349.122,137.384 349.104 C 136.374 349.100,135.370
        349.234,135.153 349.402 C 134.620 349.815,123.078 349.791,122.822 349.376 C 122.710 349.196,122.047 349.155,121.349 349.286 C 120.651
        349.417,119.786 349.396,119.427 349.239 C 119.067 349.083,118.271 348.909,117.658 348.852 C 116.194 348.718,113.188 348.187,111.710 347.802
        C 111.301 347.695,109.837 347.338,108.457 347.008 C 107.077 346.678,105.948 346.270,105.948 346.101 C 105.948 345.932,105.613 345.882,105.204
        345.989 C 104.796 346.096,104.461 346.002,104.461 345.780 C 104.461 345.558,104.210 345.473,103.903 345.590 C 103.597 345.708,103.346
        345.609,103.346 345.371 C 103.346 345.133,103.174 345.044,102.964 345.174 C 102.754 345.303,101.875 345.085,101.012 344.689 C 100.149
        344.293,97.811 343.246,95.818 342.363 C 93.824 341.481,92.193 340.621,92.193 340.453 C 92.193 340.286,92.068 340.186,91.914 340.232 C
        91.477 340.362,86.361 337.275,83.086 334.905 C 78.704 331.735,24.818 277.652,24.139 275.743 C 23.866 274.977,23.498 274.349,23.320 274.349
        C 23.143 274.349,23.100 274.029,23.224 273.637 C 23.349 273.246,23.276 272.817,23.063 272.686 C 22.563 272.377,22.559 269.633,23.058
        269.325 C 23.268 269.195,23.401 268.892,23.354 268.652 C 23.000 266.837,25.171 264.509,56.487 233.128 L 88.163 201.387 89.976 203.172
        C 90.972 204.154,91.686 205.123,91.562 205.324 " stroke="none" fill="currentColor" fill-rule="evenodd"></path><path id="path1" d="M256.413
        2.999 C 255.646 3.404,255.019 3.815,255.019 3.912 C 255.019 4.304,256.115 4.055,256.621 3.549 C 256.918 3.252,257.264 3.113,257.391 3.240
        C 257.517 3.366,257.621 3.265,257.621 3.014 C 257.621 2.763,257.788 2.662,257.993 2.788 C 258.197 2.914,258.364 2.841,258.364 2.624 C
        258.364 2.082,258.028 2.147,256.413 2.999 M183.902 106.089 L 104.185 185.785 159.437 241.036 L 214.689 296.286 294.964 215.998 C 339.115
        171.839,375.144 135.615,375.028 135.499 C 374.912 135.383,346.817 163.321,312.595 197.582 C 219.030 291.254,214.918 295.357,214.651 295.307
        C 214.375 295.255,209.093 289.987,148.234 229.057 C 124.568 205.364,105.207 185.829,105.210 185.647 C 105.213 185.465,111.695 178.841,119.615
        170.927 C 176.674 113.910,263.995 26.394,263.825 26.394 C 263.712 26.394,227.747 62.257,183.902 106.089 M395.911 114.339 C 395.911
        114.456,396.162 114.802,396.468 115.109 C 397.074 115.714,397.149 115.624,396.800 114.714 C 396.588 114.162,395.911 113.876,395.911
        114.339 M374.349 124.175 C 374.349 124.386,374.594 124.652,374.893 124.767 C 375.192 124.882,375.542 125.307,375.670 125.713 C 375.799
        126.118,375.985 126.369,376.084 126.271 C 376.353 126.002,375.130 123.792,374.712 123.792 C 374.512 123.792,374.349 123.964,374.349
        124.175 M295.743 246.858 C 242.416 300.226,198.863 343.969,198.960 344.065 C 199.056 344.162,207.029 336.336,216.677 326.674 C 226.326
        317.013,269.952 273.343,313.625 229.631 C 357.297 185.919,392.956 150.081,392.866 149.991 C 392.775 149.901,349.070 193.491,295.743 246.858
        M56.892 233.077 C 39.828 250.208,25.804 264.410,25.728 264.637 C 25.653 264.864,39.740 250.891,57.033 233.586 C 74.327 216.282,88.476
        202.064,88.476 201.991 C 88.476 201.449,84.459 205.402,56.892 233.077 M6.320 251.487 C 6.057 251.804,5.926 252.146,6.028 252.248 C 6.130
        252.351,6.429 252.175,6.691 251.859 C 6.954 251.542,7.086 251.200,6.983 251.097 C 6.881 250.995,6.582 251.170,6.320 251.487 M3.930 254.859
        C 3.296 255.493,3.159 256.134,3.658 256.134 C 3.829 256.134,4.160 255.716,4.393 255.204 C 4.882 254.131,4.754 254.035,3.930 254.859 M26.923
        278.828 C 29.957 282.186,79.794 332.044,80.483 332.410 C 80.892 332.627,68.766 320.322,53.536 305.064 C 38.306 289.807,25.781 277.323,25.704
        277.323 C 25.627 277.323,26.176 278.001,26.923 278.828 M36.088 320.632 C 51.918 336.477,65.037 349.442,65.241 349.442 C 65.445 349.442,52.648
        336.478,36.803 320.632 C 20.958 304.786,7.839 291.822,7.650 291.822 C 7.460 291.822,20.257 304.786,36.088 320.632 M189.591 320.709 C 184.991
        325.341,181.729 328.776,182.342 328.344 C 183.850 327.282,198.789 312.265,198.324 312.278 C 198.121 312.284,194.191 316.078,189.591 320.709
        M179.024 330.903 C 178.631 331.286,178.485 331.599,178.700 331.599 C 179.129 331.599,180.120 330.544,179.886 330.336 C 179.805 330.265,179.418
        330.520,179.024 330.903 M174.885 333.991 C 174.171 334.520,174.171 334.524,174.887 334.424 C 175.285 334.368,175.675 334.127,175.754 333.889 C
        175.943 333.323,175.759 333.344,174.885 333.991 M83.271 334.492 C 83.271 334.653,83.815 335.098,84.480 335.481 L 85.688 336.178 84.646 335.189
        C 83.554 334.153,83.271 334.009,83.271 334.492 M86.989 337.175 C 86.989 337.379,87.226 337.546,87.515 337.546 C 87.805 337.546,88.117
        337.756,88.208 338.011 C 88.299 338.267,88.510 338.349,88.677 338.193 C 88.843 338.038,88.657 337.667,88.263 337.369 C 87.415 336.727,86.989
        336.663,86.989 337.175 M165.056 340.335 C 164.545 340.627,164.294 340.866,164.498 340.866 C 164.703 340.866,165.288 340.627,165.799 340.335 C
        166.310 340.042,166.561 339.803,166.357 339.803 C 166.152 339.803,165.567 340.042,165.056 340.335 M161.349 341.993 C 161.048 342.183,160.946
        342.473,161.121 342.638 C 161.297 342.802,161.515 342.727,161.607 342.470 C 161.699 342.213,161.927 342.097,162.114 342.213 C 162.301
        342.329,162.454 342.246,162.454 342.029 C 162.454 341.548,162.073 341.535,161.349 341.993 M96.654 342.339 C 96.654 342.752,97.798 343.218,98.091
        342.925 C 98.209 342.807,98.060 342.555,97.759 342.365 C 97.064 341.925,96.654 341.916,96.654 342.339 M195.242 346.914 C 194.531 347.625,194.725
        348.006,195.539 347.497 C 196.290 347.028,196.533 346.468,195.985 346.468 C 195.822 346.468,195.487 346.669,195.242 346.914 M131.320 349.327 C
        131.678 349.420,132.263 349.420,132.621 349.327 C 132.979 349.233,132.686 349.157,131.970 349.157 C 131.255 349.157,130.962 349.233,131.320
        349.327 M188.156 352.800 C 187.016 353.750,187.632 353.739,188.848 352.788 C 189.359 352.388,189.610 352.063,189.405 352.065 C 189.201
        352.067,188.639 352.398,188.156 352.800 M133.178 371.632 C 133.536 371.725,134.122 371.725,134.480 371.632 C 134.837 371.538,134.545
        371.462,133.829 371.462 C 133.113 371.462,132.821 371.538,133.178 371.632" stroke="none" fill="currentColor" fill-rule="evenodd"></path>
        <path id="path2" d="M186.506 71.338 C 151.604 106.267,123.048 134.992,123.048 135.172 C 123.048 135.352,115.228 143.362,105.670 152.973
        C 96.111 162.583,93.823 164.926,100.585 158.178 C 146.494 112.370,250.283 8.152,250.139 8.007 C 250.042 7.911,221.407 36.409,186.506
        71.338 M293.309 10.595 C 294.002 11.310,294.653 11.896,294.756 11.896 C 294.858 11.896,294.374 11.310,293.680 10.595 C 292.987 9.879,292.336
        9.294,292.233 9.294 C 292.131 9.294,292.615 9.879,293.309 10.595 M297.212 14.498 C 298.010 15.316,298.748 15.985,298.850 15.985 C 298.952
        15.985,298.382 15.316,297.584 14.498 C 296.785 13.680,296.048 13.011,295.946 13.011 C 295.844 13.011,296.413 13.680,297.212 14.498 M301.487
        18.773 C 302.181 19.489,302.832 20.074,302.934 20.074 C 303.036 20.074,302.552 19.489,301.859 18.773 C 301.165 18.058,300.514 17.472,300.412
        17.472 C 300.310 17.472,300.793 18.058,301.487 18.773 M305.094 22.455 C 305.462 22.947,306.097 23.552,306.506 23.801 C 306.914 24.050,306.673
        23.648,305.968 22.907 C 304.489 21.354,304.134 21.170,305.094 22.455 M276.208 24.956 C 276.310 25.039,278.067 26.689,280.112 28.623 L 283.829
        32.140 280.669 28.870 C 278.931 27.071,277.175 25.421,276.766 25.203 C 276.357 24.985,276.106 24.874,276.208 24.956 M244.051 45.446 L 223.978
        65.613 244.145 45.540 C 262.891 26.882,264.479 25.279,264.218 25.279 C 264.166 25.279,255.091 34.354,244.051 45.446 M309.665 26.952 C 310.359
        27.667,311.010 28.253,311.112 28.253 C 311.215 28.253,310.731 27.667,310.037 26.952 C 309.344 26.236,308.692 25.651,308.590 25.651 C 308.488
        25.651,308.972 26.236,309.665 26.952 M313.448 30.809 C 313.923 31.398,314.647 32.086,315.056 32.340 C 315.465 32.594,315.138 32.112,314.330
        31.270 C 312.622 29.492,312.207 29.274,313.448 30.809 M285.688 34.201 C 286.695 35.223,287.602 36.059,287.704 36.059 C 287.807 36.059,287.066
        35.223,286.059 34.201 C 285.053 33.178,284.145 32.342,284.043 32.342 C 283.941 32.342,284.681 33.178,285.688 34.201 M317.323 34.665 C 317.433
        34.921,318.055 35.537,318.706 36.034 L 319.888 36.937 318.607 35.569 C 317.227 34.094,317.014 33.944,317.323 34.665 M289.777 38.290 C 290.784
        39.312,291.691 40.149,291.794 40.149 C 291.896 40.149,291.156 39.312,290.149 38.290 C 289.142 37.268,288.234 36.431,288.132 36.431 C 288.030
        36.431,288.770 37.268,289.777 38.290 M321.594 38.941 C 321.820 39.298,322.448 39.910,322.991 40.300 C 323.773 40.862,323.712 40.728,322.697
        39.650 C 321.288 38.154,320.968 37.949,321.594 38.941 M294.037 42.472 C 294.948 43.443,295.743 44.238,295.802 44.238 C 296.081 44.238,295.786
        43.904,294.145 42.364 L 292.379 40.706 294.037 42.472 M325.683 43.030 C 325.909 43.388,326.537 43.999,327.080 44.389 C 327.862 44.952,327.801
        44.817,326.786 43.739 C 325.377 42.243,325.057 42.038,325.683 43.030 M298.327 46.840 C 299.334 47.862,300.242 48.699,300.344 48.699 C 300.446
        48.699,299.706 47.862,298.699 46.840 C 297.692 45.818,296.784 44.981,296.682 44.981 C 296.580 44.981,297.320 45.818,298.327 46.840 M329.870
        47.246 C 330.170 47.674,330.807 48.286,331.286 48.606 C 331.861 48.990,331.722 48.727,330.875 47.828 C 329.440 46.305,329.055 46.082,329.870
        47.246 M302.416 50.929 C 303.423 51.952,304.331 52.788,304.433 52.788 C 304.535 52.788,303.795 51.952,302.788 50.929 C 301.781 49.907,300.874
        49.071,300.771 49.071 C 300.669 49.071,301.409 49.907,302.416 50.929 M334.246 51.655 C 334.692 52.258,335.325 52.854,335.651 52.979 C 335.978
        53.103,335.669 52.610,334.964 51.882 C 333.405 50.270,333.167 50.195,334.246 51.655 M306.506 55.019 C 307.513 56.041,308.420 56.877,308.522
        56.877 C 308.624 56.877,307.884 56.041,306.877 55.019 C 305.870 53.996,304.963 53.160,304.861 53.160 C 304.758 53.160,305.499 53.996,306.506
        55.019 M338.285 55.754 C 338.502 56.158,339.051 56.681,339.507 56.917 C 340.197 57.274,340.153 57.152,339.243 56.182 C 337.978 54.834,337.735
        54.726,338.285 55.754 M310.595 59.108 C 311.393 59.926,312.130 60.595,312.233 60.595 C 312.335 60.595,311.765 59.926,310.967 59.108 C 310.168
        58.290,309.431 57.621,309.329 57.621 C 309.226 57.621,309.796 58.290,310.595 59.108 M342.375 59.843 C 342.591 60.247,343.141 60.771,343.596
        61.006 C 344.286 61.363,344.242 61.241,343.332 60.271 C 342.067 58.923,341.825 58.815,342.375 59.843 M314.684 63.197 C 315.483 64.015,316.220
        64.684,316.322 64.684 C 316.424 64.684,315.854 64.015,315.056 63.197 C 314.257 62.379,313.520 61.710,313.418 61.710 C 313.316 61.710,313.885
        62.379,314.684 63.197 M346.440 63.888 C 346.644 64.268,347.277 64.876,347.847 65.238 C 348.798 65.843,348.778 65.786,347.604 64.548 C 346.165
        63.030,345.902 62.881,346.440 63.888 M209.478 80.019 L 195.353 94.238 209.572 80.113 C 217.393 72.345,223.792 65.946,223.792 65.894 C 223.792
        65.632,222.605 66.803,209.478 80.019 M318.959 67.472 C 319.653 68.188,320.304 68.773,320.406 68.773 C 320.508 68.773,320.024 68.188,319.331
        67.472 C 318.637 66.757,317.986 66.171,317.884 66.171 C 317.782 66.171,318.266 66.757,318.959 67.472 M350.529 67.977 C 350.733 68.357,351.366
        68.965,351.937 69.328 C 352.888 69.932,352.867 69.875,351.693 68.637 C 350.254 67.120,349.991 66.971,350.529 67.977 M323.234 71.747 C 324.033
        72.565,324.770 73.234,324.872 73.234 C 324.974 73.234,324.405 72.565,323.606 71.747 C 322.807 70.929,322.070 70.260,321.968 70.260 C 321.866
        70.260,322.436 70.929,323.234 71.747 M354.722 72.185 C 355.017 72.630,355.665 73.247,356.161 73.557 C 356.850 73.987,356.760 73.796,355.782
        72.748 C 354.339 71.202,353.922 70.981,354.722 72.185 M327.323 75.836 C 328.122 76.654,328.859 77.323,328.961 77.323 C 329.064 77.323,328.494
        76.654,327.695 75.836 C 326.897 75.019,326.160 74.349,326.057 74.349 C 325.955 74.349,326.525 75.019,327.323 75.836 M359.039 76.425 C 359.503
        77.322,360.534 78.252,360.786 78.000 C 360.881 77.905,360.437 77.337,359.799 76.739 C 358.920 75.914,358.736 75.838,359.039 76.425 M331.413
        79.926 C 332.211 80.743,332.948 81.413,333.051 81.413 C 333.153 81.413,332.583 80.743,331.784 79.926 C 330.986 79.108,330.249 78.439,330.147
        78.439 C 330.044 78.439,330.614 79.108,331.413 79.926 M363.356 80.989 C 363.954 81.627,364.522 82.071,364.617 81.975 C 364.869 81.723,363.939
        80.692,363.042 80.229 C 362.455 79.926,362.531 80.110,363.356 80.989 M335.502 84.015 C 336.300 84.833,337.038 85.502,337.140 85.502 C 337.242
        85.502,336.672 84.833,335.874 84.015 C 335.075 83.197,334.338 82.528,334.236 82.528 C 334.133 82.528,334.703 83.197,335.502 84.015 M367.638
        85.244 C 368.343 85.999,368.990 86.617,369.077 86.617 C 369.467 86.617,368.072 84.943,367.259 84.435 C 366.571 84.005,366.660 84.197,367.638
        85.244 M339.777 88.290 C 340.471 89.006,341.122 89.591,341.224 89.591 C 341.326 89.591,340.842 89.006,340.149 88.290 C 339.455 87.574,338.804
        86.989,338.702 86.989 C 338.600 86.989,339.083 87.574,339.777 88.290 M371.916 89.546 C 372.724 90.388,373.446 91.078,373.521 91.078 C 373.910
        91.078,371.950 88.786,371.257 88.430 C 370.742 88.165,370.982 88.572,371.916 89.546 M342.751 91.194 C 342.751 91.258,343.295 91.802,343.959
        92.403 L 345.167 93.494 344.075 92.286 C 343.058 91.160,342.751 90.907,342.751 91.194 M375.093 92.681 C 375.093 92.745,375.621 93.289,376.266
        93.890 C 377.403 94.949,377.412 94.534,376.280 93.216 C 375.795 92.651,375.093 92.335,375.093 92.681 M192.924 96.561 L 190.892 98.699 193.030
        96.667 C 195.016 94.779,195.338 94.424,195.062 94.424 C 195.004 94.424,194.042 95.386,192.924 96.561 M380.084 97.717 C 380.683 98.355,381.250
        98.799,381.346 98.704 C 381.598 98.452,380.668 97.421,379.770 96.958 C 379.184 96.655,379.260 96.838,380.084 97.717 M188.648 100.836 L 186.803
        102.788 188.755 100.943 C 190.568 99.229,190.877 98.885,190.599 98.885 C 190.541 98.885,189.662 99.763,188.648 100.836 M351.301 99.745 C
        351.301 99.809,351.845 100.352,352.509 100.953 L 353.717 102.045 352.626 100.836 C 351.608 99.710,351.301 99.457,351.301 99.745 M384.174
        101.807 C 384.772 102.444,385.340 102.888,385.435 102.793 C 385.687 102.541,384.757 101.510,383.860 101.047 C 383.273 100.744,383.349
        100.928,384.174 101.807 M184.758 104.647 C 183.855 105.567,183.200 106.320,183.302 106.320 C 183.405 106.320,184.227 105.567,185.130 104.647
        C 186.033 103.727,186.688 102.974,186.586 102.974 C 186.484 102.974,185.661 103.727,184.758 104.647 M355.390 103.834 C 355.390 103.898,355.934
        104.441,356.599 105.042 L 357.807 106.134 356.715 104.926 C 355.697 103.799,355.390 103.547,355.390 103.834 M388.272 105.888 C 389.081
        106.739,389.798 107.435,389.865 107.435 C 390.305 107.435,389.014 105.857,388.012 105.170 C 386.850 104.373,386.860 104.400,388.272 105.888
        M180.466 109.015 L 178.996 110.595 180.576 109.125 C 182.046 107.759,182.327 107.435,182.046 107.435 C 181.985 107.435,181.274 108.146,180.466
        109.015 M359.480 107.923 C 359.480 107.987,360.023 108.531,360.688 109.131 L 361.896 110.223 360.804 109.015 C 359.786 107.889,359.480
        107.636,359.480 107.923 M176.377 113.104 L 174.907 114.684 176.487 113.215 C 177.956 111.848,178.238 111.524,177.956 111.524 C 177.896
        111.524,177.185 112.235,176.377 113.104 M172.287 117.193 L 170.818 118.773 172.398 117.304 C 173.867 115.937,174.149 115.613,173.867 115.613
        C 173.807 115.613,173.096 116.324,172.287 117.193 M398.186 120.083 C 398.749 121.747,399.066 122.071,398.725 120.632 C 398.579 120.019,398.300
        119.353,398.105 119.153 C 397.909 118.954,397.946 119.372,398.186 120.083 M168.030 121.375 C 167.127 122.296,166.472 123.048,166.574 123.048 C
        166.676 123.048,167.499 122.296,168.401 121.375 C 169.304 120.455,169.960 119.703,169.857 119.703 C 169.755 119.703,168.933 120.455,168.030
        121.375 M163.941 125.465 C 163.038 126.385,162.382 127.138,162.485 127.138 C 162.587 127.138,163.409 126.385,164.312 125.465 C 165.215
        124.545,165.870 123.792,165.768 123.792 C 165.666 123.792,164.844 124.545,163.941 125.465 M375.904 125.534 C 376.561 127.077,377.039
        127.498,376.553 126.106 C 376.323 125.446,375.983 124.812,375.797 124.697 C 375.611 124.582,375.659 124.959,375.904 125.534 M159.648 129.833
        L 158.178 131.413 159.758 129.943 C 161.228 128.577,161.509 128.253,161.228 128.253 C 161.167 128.253,160.456 128.964,159.648 129.833 M155.559
        133.922 L 154.089 135.502 155.669 134.032 C 157.138 132.666,157.420 132.342,157.139 132.342 C 157.078 132.342,156.367 133.053,155.559 133.922
        M151.469 138.011 L 150.000 139.591 151.580 138.122 C 153.049 136.755,153.331 136.431,153.049 136.431 C 152.989 136.431,152.278 137.142,151.469
        138.011 M372.663 138.383 L 370.818 140.335 372.770 138.490 C 374.583 136.776,374.892 136.431,374.614 136.431 C 374.555 136.431,373.677
        137.309,372.663 138.383 M397.506 141.278 C 397.278 141.884,397.225 142.379,397.390 142.379 C 397.695 142.379,398.281 140.537,398.050 140.305
        C 397.979 140.235,397.735 140.673,397.506 141.278 M147.380 142.100 L 145.911 143.680 147.491 142.211 C 148.960 140.844,149.241 140.520,148.960
        140.520 C 148.899 140.520,148.189 141.231,147.380 142.100 M368.389 142.658 L 366.357 144.796 368.494 142.764 C 370.480 140.876,370.802
        140.520,370.526 140.520 C 370.468 140.520,369.506 141.482,368.389 142.658 M143.103 146.375 L 141.822 147.770 143.216 146.488 C 143.982
        145.784,144.610 145.156,144.610 145.094 C 144.610 144.810,144.295 145.078,143.103 146.375 M342.378 168.680 L 318.773 192.379 342.472 168.774
        C 364.502 146.832,366.338 144.981,366.077 144.981 C 366.025 144.981,355.361 155.646,342.378 168.680 M393.011 148.773 C 392.487 149.298,392.428
        149.814,392.893 149.814 C 393.074 149.814,393.309 149.480,393.416 149.071 C 393.643 148.203,393.608 148.177,393.011 148.773 M138.662 150.743
        C 137.759 151.664,137.104 152.416,137.206 152.416 C 137.308 152.416,138.130 151.664,139.033 150.743 C 139.936 149.823,140.592 149.071,140.489
        149.071 C 140.387 149.071,139.565 149.823,138.662 150.743 M128.621 160.874 L 120.632 168.959 128.717 160.970 C 136.230 153.546,136.971
        152.788,136.707 152.788 C 136.654 152.788,133.015 156.427,128.621 160.874 M118.388 171.097 L 116.543 173.048 118.494 171.204 C 119.568
        170.189,120.446 169.311,120.446 169.252 C 120.446 168.974,120.102 169.283,118.388 171.097 M48.099 209.865 C 26.347 231.648,8.550 249.641,8.550
        249.850 C 8.550 250.059,8.341 250.109,8.086 249.962 C 7.830 249.815,7.745 249.832,7.896 249.999 C 8.048 250.166,7.976 250.570,7.736 250.895
        C 7.496 251.221,25.480 233.298,47.702 211.066 C 69.923 188.835,88.104 170.559,88.104 170.453 C 88.104 169.864,84.372 173.540,48.099 209.865
        M114.126 175.279 C 113.223 176.199,112.568 176.952,112.670 176.952 C 112.773 176.952,113.595 176.199,114.498 175.279 C 115.401 174.359,116.056
        173.606,115.954 173.606 C 115.852 173.606,115.029 174.359,114.126 175.279 M110.037 179.368 C 109.134 180.288,108.479 181.041,108.581 181.041
        C 108.683 181.041,109.506 180.288,110.409 179.368 C 111.312 178.448,111.967 177.695,111.865 177.695 C 111.763 177.695,110.940 178.448,110.037
        179.368 M105.748 183.736 L 103.903 185.688 105.855 183.843 C 106.928 182.828,107.807 181.950,107.807 181.891 C 107.807 181.614,107.462
        181.923,105.748 183.736 M122.491 204.461 C 132.712 214.684,141.159 223.048,141.261 223.048 C 141.363 223.048,133.084 214.684,122.862 204.461
        C 112.641 194.238,104.194 185.874,104.092 185.874 C 103.990 185.874,112.269 194.238,122.491 204.461 M316.529 194.517 L 314.684 196.468 316.636
        194.624 C 318.449 192.909,318.758 192.565,318.480 192.565 C 318.422 192.565,317.543 193.443,316.529 194.517 M344.772 197.491 L 343.680 198.699
        344.888 197.607 C 345.553 197.007,346.097 196.463,346.097 196.399 C 346.097 196.112,345.790 196.365,344.772 197.491 M308.360 202.695 L 302.416
        208.736 308.457 202.792 C 314.069 197.271,314.666 196.654,314.401 196.654 C 314.347 196.654,311.629 199.373,308.360 202.695 M340.311 201.952 L
        339.219 203.160 340.428 202.068 C 341.092 201.467,341.636 200.924,341.636 200.860 C 341.636 200.573,341.329 200.825,340.311 201.952 M88.104
        201.619 C 88.104 202.062,198.080 312.081,198.302 311.859 C 198.401 311.760,173.647 286.844,143.293 256.490 C 112.939 226.137,88.104
        201.445,88.104 201.619 M85.110 204.368 L 83.829 205.762 85.223 204.481 C 86.521 203.289,86.788 202.974,86.504 202.974 C 86.442 202.974,85.815
        203.601,85.110 204.368 M336.222 206.041 L 335.130 207.249 336.338 206.157 C 337.003 205.557,337.546 205.013,337.546 204.949 C 337.546
        204.662,337.240 204.915,336.222 206.041 M80.832 208.643 L 79.740 209.851 80.948 208.759 C 82.074 207.742,82.327 207.435,82.040 207.435 C
        81.976 207.435,81.432 207.979,80.832 208.643 M332.322 209.944 L 331.041 211.338 332.435 210.057 C 333.732 208.865,334.000 208.550,333.716
        208.550 C 333.654 208.550,333.027 209.178,332.322 209.944 M300.172 210.874 L 298.327 212.825 300.279 210.981 C 301.352 209.966,302.230
        209.088,302.230 209.029 C 302.230 208.751,301.886 209.060,300.172 210.874 M328.233 214.033 L 326.952 215.428 328.346 214.146 C 329.643
        212.954,329.911 212.639,329.627 212.639 C 329.565 212.639,328.937 213.267,328.233 214.033 M295.911 215.056 C 295.008 215.976,294.353
        216.729,294.455 216.729 C 294.557 216.729,295.380 215.976,296.283 215.056 C 297.186 214.136,297.841 213.383,297.738 213.383 C 297.636
        213.383,296.814 214.136,295.911 215.056 M324.144 218.123 L 322.862 219.517 324.257 218.236 C 325.554 217.043,325.821 216.729,325.538 216.729
        C 325.475 216.729,324.848 217.356,324.144 218.123 M291.618 219.424 L 290.149 221.004 291.729 219.534 C 293.198 218.168,293.479 217.844,293.198
        217.844 C 293.137 217.844,292.426 218.555,291.618 219.424 M68.564 220.911 L 67.472 222.119 68.680 221.027 C 69.806 220.009,70.059 219.703,69.772
        219.703 C 69.708 219.703,69.164 220.246,68.564 220.911 M319.871 222.398 L 318.401 223.978 319.981 222.508 C 320.850 221.700,321.561
        220.989,321.561 220.928 C 321.561 220.647,321.238 220.928,319.871 222.398 M287.532 223.513 L 285.688 225.465 287.639 223.620 C 289.453
        221.906,289.762 221.561,289.484 221.561 C 289.425 221.561,288.547 222.440,287.532 223.513 M154.647 236.617 C 161.903 243.875,167.924
        249.814,168.026 249.814 C 168.128 249.814,162.275 243.875,155.019 236.617 C 147.762 229.359,141.742 223.420,141.640 223.420 C 141.537
        223.420,147.391 229.359,154.647 236.617 M64.475 225.000 L 63.383 226.208 64.591 225.116 C 65.717 224.099,65.970 223.792,65.683 223.792 C
        65.619 223.792,65.075 224.336,64.475 225.000 M315.593 226.673 L 314.312 228.067 315.706 226.786 C 317.004 225.593,317.271 225.279,316.987
        225.279 C 316.925 225.279,316.298 225.906,315.593 226.673 M283.434 227.602 L 282.342 228.810 283.550 227.719 C 284.676 226.701,284.929
        226.394,284.642 226.394 C 284.578 226.394,284.034 226.938,283.434 227.602 M311.321 230.948 L 309.851 232.528 311.431 231.058 C 312.901
        229.692,313.182 229.368,312.901 229.368 C 312.840 229.368,312.129 230.079,311.321 230.948 M279.345 231.691 L 278.253 232.900 279.461 231.808
        C 280.125 231.207,280.669 230.664,280.669 230.600 C 280.669 230.312,280.362 230.565,279.345 231.691 M307.063 235.130 C 306.160 236.050,305.505
        236.803,305.607 236.803 C 305.710 236.803,306.532 236.050,307.435 235.130 C 308.338 234.210,308.993 233.457,308.891 233.457 C 308.789
        233.457,307.966 234.210,307.063 235.130 M275.261 235.781 L 273.792 237.361 275.372 235.891 C 276.841 234.524,277.123 234.201,276.841 234.201
        C 276.781 234.201,276.070 234.912,275.261 235.781 M303.514 238.755 L 302.045 240.335 303.625 238.865 C 304.493 238.057,305.204 237.346,305.204
        237.285 C 305.204 237.004,304.881 237.285,303.514 238.755 M270.984 240.056 L 269.703 241.450 271.097 240.169 C 272.394 238.976,272.662
        238.662,272.378 238.662 C 272.316 238.662,271.688 239.289,270.984 240.056 M281.597 260.688 L 262.268 280.112 281.691 260.782 C 299.746
        242.815,301.282 241.264,301.021 241.264 C 300.969 241.264,292.228 250.005,281.597 260.688 M266.894 244.145 L 265.613 245.539 267.007
        244.258 C 268.305 243.066,268.572 242.751,268.289 242.751 C 268.226 242.751,267.599 243.378,266.894 244.145 M262.616 248.420 L 261.524
        249.628 262.732 248.536 C 263.397 247.936,263.941 247.392,263.941 247.328 C 263.941 247.041,263.634 247.294,262.616 248.420 M172.112
        253.996 C 174.256 256.194,176.054 257.993,176.109 257.993 C 176.377 257.993,175.924 257.516,172.212 253.897 L 168.216 250.000 172.112
        253.996 M182.714 264.684 C 185.980 267.955,188.737 270.632,188.839 270.632 C 188.941 270.632,186.352 267.955,183.086 264.684 C 179.819
        261.413,177.063 258.736,176.960 258.736 C 176.858 258.736,179.447 261.413,182.714 264.684 M1.466 261.209 C 1.259 261.751,1.145 262.358,1.212
        262.558 C 1.278 262.757,1.519 262.314,1.747 261.572 C 2.225 260.013,2.021 259.749,1.466 261.209 M22.924 269.393 C 22.585 269.733,22.610
        272.406,22.955 272.619 C 23.109 272.714,23.234 271.971,23.234 270.968 C 23.234 269.965,23.220 269.145,23.203 269.145 C 23.186 269.145,23.061
        269.257,22.924 269.393 M192.751 274.721 C 194.788 276.766,196.538 278.439,196.640 278.439 C 196.743 278.439,195.160 276.766,193.123 274.721
        C 191.086 272.677,189.336 271.004,189.233 271.004 C 189.131 271.004,190.714 272.677,192.751 274.721 M26.580 279.182 C 27.379 280.000,28.116
        280.669,28.218 280.669 C 28.320 280.669,27.750 280.000,26.952 279.182 C 26.153 278.364,25.416 277.695,25.314 277.695 C 25.212 277.695,25.781
        278.364,26.580 279.182 M199.071 281.041 C 199.869 281.859,200.606 282.528,200.709 282.528 C 200.811 282.528,200.241 281.859,199.442 281.041
        C 198.644 280.223,197.907 279.554,197.804 279.554 C 197.702 279.554,198.272 280.223,199.071 281.041 M257.800 284.480 L 253.717 288.662 257.900
        284.579 C 261.784 280.786,262.251 280.297,261.983 280.297 C 261.928 280.297,260.046 282.179,257.800 284.480 M30.839 283.364 C 31.751
        284.336,32.546 285.130,32.605 285.130 C 32.884 285.130,32.589 284.796,30.948 283.256 L 29.182 281.599 30.839 283.364 M2.108 282.812 C 2.225
        283.276,2.467 283.746,2.647 283.857 C 3.156 284.171,3.032 283.491,2.435 282.692 C 1.915 281.996,1.903 282.000,2.108 282.812 M203.532 285.502
        C 204.330 286.320,205.067 286.989,205.169 286.989 C 205.272 286.989,204.702 286.320,203.903 285.502 C 203.105 284.684,202.368 284.015,202.265
        284.015 C 202.163 284.015,202.733 284.684,203.532 285.502 M34.758 287.361 C 35.765 288.383,36.673 289.219,36.775 289.219 C 36.877 289.219,36.137
        288.383,35.130 287.361 C 34.123 286.338,33.216 285.502,33.113 285.502 C 33.011 285.502,33.751 286.338,34.758 287.361 M206.691 288.592 C 206.691
        288.656,207.235 289.200,207.900 289.800 L 209.108 290.892 208.016 289.684 C 206.998 288.558,206.691 288.305,206.691 288.592 M251.288 290.985 L
        249.257 293.123 251.394 291.091 C 253.380 289.203,253.702 288.848,253.426 288.848 C 253.368 288.848,252.406 289.809,251.288 290.985 M6.819
        290.517 C 7.056 291.027,7.380 291.445,7.541 291.447 C 7.883 291.450,7.215 290.100,6.724 289.797 C 6.540 289.683,6.583 290.007,6.819 290.517
        M38.848 291.450 C 39.855 292.472,40.762 293.309,40.864 293.309 C 40.966 293.309,40.226 292.472,39.219 291.450 C 38.212 290.428,37.305
        289.591,37.203 289.591 C 37.100 289.591,37.841 290.428,38.848 291.450 M211.896 293.866 C 212.590 294.582,213.241 295.167,213.343 295.167 C
        213.445 295.167,212.961 294.582,212.268 293.866 C 211.574 293.151,210.923 292.565,210.821 292.565 C 210.718 292.565,211.202 293.151,211.896
        293.866 M247.012 295.260 L 245.167 297.212 247.119 295.367 C 248.192 294.353,249.071 293.474,249.071 293.415 C 249.071 293.138,248.726
        293.447,247.012 295.260 M42.937 295.539 C 43.944 296.561,44.851 297.398,44.953 297.398 C 45.056 297.398,44.315 296.561,43.309 295.539 C
        42.302 294.517,41.394 293.680,41.292 293.680 C 41.190 293.680,41.930 294.517,42.937 295.539 M243.123 299.071 C 242.220 299.991,241.565
        300.743,241.667 300.743 C 241.769 300.743,242.591 299.991,243.494 299.071 C 244.397 298.151,245.053 297.398,244.950 297.398 C 244.848
        297.398,244.026 298.151,243.123 299.071 M60.409 313.011 C 68.790 321.394,75.731 328.253,75.833 328.253 C 75.935 328.253,69.162 321.394,60.781
        313.011 C 52.400 304.628,45.459 297.770,45.357 297.770 C 45.254 297.770,52.028 304.628,60.409 313.011 M239.033 303.160 C 238.130 304.080,237.475
        304.833,237.578 304.833 C 237.680 304.833,238.502 304.080,239.405 303.160 C 240.308 302.240,240.963 301.487,240.861 301.487 C 240.759
        301.487,239.936 302.240,239.033 303.160 M20.074 303.834 C 20.074 303.898,20.618 304.441,21.283 305.042 L 22.491 306.134 21.399 304.926 C
        20.381 303.799,20.074 303.547,20.074 303.834 M234.944 307.249 C 234.041 308.169,233.386 308.922,233.488 308.922 C 233.591 308.922,234.413
        308.169,235.316 307.249 C 236.219 306.329,236.874 305.576,236.772 305.576 C 236.670 305.576,235.847 306.329,234.944 307.249 M24.164 307.923
        C 24.164 307.987,24.707 308.531,25.372 309.131 L 26.580 310.223 25.488 309.015 C 24.470 307.889,24.164 307.636,24.164 307.923 M230.855 311.338
        C 229.952 312.258,229.297 313.011,229.399 313.011 C 229.501 313.011,230.324 312.258,231.227 311.338 C 232.130 310.418,232.785 309.665,232.683
        309.665 C 232.580 309.665,231.758 310.418,230.855 311.338 M28.625 312.384 C 28.625 312.448,29.168 312.992,29.833 313.592 L 31.041 314.684
        29.949 313.476 C 28.931 312.350,28.625 312.097,28.625 312.384 M196.445 314.591 L 195.353 315.799 196.561 314.707 C 197.226 314.107,197.770
        313.563,197.770 313.499 C 197.770 313.212,197.463 313.465,196.445 314.591 M226.562 315.706 L 225.093 317.286 226.673 315.817 C 228.142
        314.450,228.424 314.126,228.142 314.126 C 228.082 314.126,227.371 314.837,226.562 315.706 M32.714 316.473 C 32.714 316.537,33.257 317.081,33.922
        317.681 L 35.130 318.773 34.038 317.565 C 33.020 316.439,32.714 316.186,32.714 316.473 M222.305 319.888 C 221.402 320.809,220.747
        321.561,220.849 321.561 C 220.951 321.561,221.774 320.809,222.677 319.888 C 223.580 318.968,224.235 318.216,224.132 318.216 C 224.030
        318.216,223.208 318.968,222.305 319.888 M218.216 323.978 C 217.313 324.898,216.657 325.651,216.760 325.651 C 216.862 325.651,217.684
        324.898,218.587 323.978 C 219.490 323.058,220.146 322.305,220.043 322.305 C 219.941 322.305,219.119 323.058,218.216 323.978 M40.892 324.652
        C 40.892 324.716,41.436 325.259,42.100 325.860 L 43.309 326.952 42.217 325.743 C 41.199 324.617,40.892 324.364,40.892 324.652 M182.700 328.346
        L 180.855 330.297 182.807 328.453 C 183.880 327.438,184.758 326.560,184.758 326.501 C 184.758 326.224,184.414 326.532,182.700 328.346 M214.126
        328.067 C 213.223 328.987,212.568 329.740,212.670 329.740 C 212.773 329.740,213.595 328.987,214.498 328.067 C 215.401 327.147,216.056
        326.394,215.954 326.394 C 215.852 326.394,215.029 327.147,214.126 328.067 M44.981 328.741 C 44.981 328.805,45.525 329.349,46.190 329.949 L
        47.398 331.041 46.306 329.833 C 45.288 328.707,44.981 328.454,44.981 328.741 M78.253 330.855 C 79.051 331.673,79.788 332.342,79.891 332.342
        C 79.993 332.342,79.423 331.673,78.625 330.855 C 77.826 330.037,77.089 329.368,76.987 329.368 C 76.884 329.368,77.454 330.037,78.253 330.855
        M179.089 331.289 C 178.322 332.072,177.830 332.714,177.994 332.714 C 178.376 332.714,180.858 330.166,180.644 329.993 C 180.555 329.922,179.856
        330.505,179.089 331.289 M210.037 332.156 C 209.134 333.076,208.479 333.829,208.581 333.829 C 208.683 333.829,209.506 333.076,210.409 332.156
        C 211.312 331.236,211.967 330.483,211.865 330.483 C 211.763 330.483,210.940 331.236,210.037 332.156 M50.000 333.829 C 50.799 334.647,51.536
        335.316,51.638 335.316 C 51.740 335.316,51.170 334.647,50.372 333.829 C 49.573 333.011,48.836 332.342,48.734 332.342 C 48.632 332.342,49.201
        333.011,50.000 333.829 M205.745 336.524 L 204.275 338.104 205.855 336.635 C 207.324 335.268,207.606 334.944,207.325 334.944 C 207.264
        334.944,206.553 335.655,205.745 336.524 M84.015 335.494 C 84.015 335.573,84.517 335.994,85.130 336.431 C 85.743 336.868,86.244 337.088,86.243
        336.921 C 86.242 336.754,85.740 336.332,85.128 335.984 C 84.516 335.636,84.015 335.415,84.015 335.494 M54.461 338.290 C 55.260 339.108,55.997
        339.777,56.099 339.777 C 56.201 339.777,55.631 339.108,54.833 338.290 C 54.034 337.472,53.297 336.803,53.195 336.803 C 53.093 336.803,53.662
        337.472,54.461 338.290 M201.655 340.613 L 200.186 342.193 201.766 340.724 C 203.235 339.357,203.517 339.033,203.235 339.033 C 203.175
        339.033,202.464 339.744,201.655 340.613 M166.450 339.990 C 165.060 340.744,165.145 341.058,166.543 340.335 C 167.156 340.017,167.658
        339.679,167.658 339.582 C 167.658 339.342,167.618 339.356,166.450 339.990 M92.936 341.032 C 93.447 341.331,93.935 341.505,94.021 341.419 C
        94.251 341.189,93.972 341.013,92.937 340.737 L 92.007 340.489 92.936 341.032 M60.960 344.703 C 63.103 346.901,64.901 348.699,64.956 348.699 C
        65.225 348.699,64.771 348.222,61.059 344.603 L 57.063 340.706 60.960 344.703 M197.346 344.969 C 196.863 345.371,196.652 345.706,196.875 345.713
        C 197.099 345.719,197.585 345.390,197.955 344.981 C 198.816 344.030,198.481 344.023,197.346 344.969 M193.309 348.352 L 191.636 349.843 193.123
        348.872 C 193.941 348.337,194.743 347.662,194.907 347.370 C 195.343 346.592,195.155 346.708,193.309 348.352 M122.862 349.442 C 122.989
        349.647,123.542 349.812,124.093 349.808 C 124.875 349.804,124.971 349.724,124.535 349.442 C 123.801 348.968,122.569 348.968,122.862 349.442
        M133.829 349.442 C 133.376 349.735,133.424 349.804,134.086 349.808 C 134.534 349.812,135.004 349.647,135.130 349.442 C 135.423 348.969,134.561
        348.969,133.829 349.442 M84.390 360.878 C 84.394 361.167,87.404 362.534,87.601 362.337 C 87.664 362.274,86.967 361.858,86.051 361.413 C 85.136
        360.968,84.388 360.728,84.390 360.878 M171.840 361.882 C 170.767 362.382,169.888 362.875,169.888 362.977 C 169.888 363.180,173.506
        361.562,173.874 361.194 C 174.262 360.806,173.860 360.942,171.840 361.882" stroke="none" fill="currentColor" fill-rule="evenodd"></path>
        <path id="path3" d="M316.672 33.716 C 330.504 47.557,353.028 70.099,366.724 83.809 C 380.420 97.519,391.378 108.401,391.075 107.993 C 390.310
        106.961,292.003 8.550,291.737 8.550 C 291.619 8.550,302.839 19.875,316.672 33.716 M359.270 182.993 L 358.178 184.201 359.387 183.109 C 360.051
        182.508,360.595 181.965,360.595 181.901 C 360.595 181.614,360.288 181.866,359.270 182.993 M355.181 187.082 L 354.089 188.290 355.297 187.198 C
        355.962 186.598,356.506 186.054,356.506 185.990 C 356.506 185.703,356.199 185.956,355.181 187.082 M351.092 191.171 L 350.000 192.379 351.208
        191.287 C 351.873 190.687,352.416 190.143,352.416 190.079 C 352.416 189.792,352.110 190.045,351.092 191.171 M347.003 195.260 L 345.911 196.468
        347.119 195.377 C 348.245 194.359,348.498 194.052,348.211 194.052 C 348.147 194.052,347.603 194.596,347.003 195.260 M342.542 199.721 L 341.450
        200.929 342.658 199.838 C 343.784 198.820,344.037 198.513,343.750 198.513 C 343.686 198.513,343.142 199.057,342.542 199.721 M260.386 250.651 L
        259.294 251.859 260.502 250.767 C 261.166 250.166,261.710 249.623,261.710 249.559 C 261.710 249.272,261.403 249.524,260.386 250.651 M252.579
        258.457 L 251.487 259.665 252.695 258.574 C 253.360 257.973,253.903 257.429,253.903 257.365 C 253.903 257.078,253.597 257.331,252.579 258.457
        M248.307 262.732 L 247.026 264.126 248.420 262.845 C 249.718 261.653,249.985 261.338,249.701 261.338 C 249.639 261.338,249.012 261.966,248.307
        262.732 M244.029 267.007 L 242.937 268.216 244.145 267.124 C 245.271 266.106,245.524 265.799,245.237 265.799 C 245.173 265.799,244.629
        266.343,244.029 267.007 M239.757 271.283 L 238.476 272.677 239.870 271.395 C 241.167 270.203,241.435 269.888,241.151 269.888 C 241.089
        269.888,240.462 270.516,239.757 271.283 M235.484 275.558 L 234.015 277.138 235.595 275.668 C 237.064 274.301,237.346 273.978,237.064 273.978 C
        237.004 273.978,236.293 274.689,235.484 275.558 M10.781 294.610 C 11.474 295.325,12.125 295.911,12.228 295.911 C 12.330 295.911,11.846
        295.325,11.152 294.610 C 10.459 293.894,9.808 293.309,9.705 293.309 C 9.603 293.309,10.087 293.894,10.781 294.610 M14.870 298.699 C 15.563
        299.414,16.215 300.000,16.317 300.000 C 16.419 300.000,15.935 299.414,15.242 298.699 C 14.548 297.983,13.897 297.398,13.795 297.398 C 13.692
        297.398,14.176 297.983,14.870 298.699 M17.844 301.603 C 17.844 301.667,18.388 302.211,19.052 302.811 L 20.260 303.903 19.168 302.695 C 18.151
        301.569,17.844 301.316,17.844 301.603 M26.394 310.153 C 26.394 310.217,26.938 310.761,27.602 311.362 L 28.810 312.454 27.719 311.245 C 26.701
        310.119,26.394 309.866,26.394 310.153 M194.215 316.822 L 193.123 318.030 194.331 316.938 C 194.995 316.337,195.539 315.794,195.539 315.730 C
        195.539 315.443,195.232 315.695,194.215 316.822 M34.944 318.704 C 34.944 318.768,35.488 319.311,36.152 319.912 L 37.361 321.004 36.269 319.796
        C 35.251 318.669,34.944 318.416,34.944 318.704" stroke="none" fill="currentColor" fill-rule="evenodd"></path><path id="path4" d="M263.755 26.241 L
        262.639 27.199 263.848 26.399 C 264.512 25.960,265.056 25.528,265.056 25.439 C 265.056 25.161,264.898 25.258,263.755 26.241 M325.464 74.670 C
        352.249 101.483,374.299 123.420,374.466 123.420 C 374.893 123.420,278.417 26.797,277.509 26.315 C 277.100 26.098,298.680 47.858,325.464 74.670
        M259.460 30.390 L 258.178 31.784 259.572 30.503 C 260.870 29.311,261.137 28.996,260.854 28.996 C 260.791 28.996,260.164 29.624,259.460 30.390
        M255.370 34.480 L 254.089 35.874 255.483 34.593 C 256.781 33.400,257.048 33.086,256.764 33.086 C 256.702 33.086,256.075 33.713,255.370 34.480
        M251.092 38.755 L 250.000 39.963 251.208 38.871 C 251.873 38.270,252.416 37.727,252.416 37.663 C 252.416 37.376,252.110 37.628,251.092 38.755
        M247.003 42.844 L 245.911 44.052 247.119 42.960 C 247.783 42.360,248.327 41.816,248.327 41.752 C 248.327 41.465,248.020 41.718,247.003 42.844
        M242.913 46.933 L 241.822 48.141 243.030 47.049 C 243.694 46.449,244.238 45.905,244.238 45.841 C 244.238 45.554,243.931 45.807,242.913 46.933
        M238.824 51.022 L 237.732 52.230 238.941 51.139 C 239.605 50.538,240.149 49.994,240.149 49.930 C 240.149 49.643,239.842 49.896,238.824 51.022
        M234.552 55.297 L 233.271 56.691 234.665 55.410 C 235.963 54.218,236.230 53.903,235.947 53.903 C 235.884 53.903,235.257 54.531,234.552 55.297
        M230.463 59.387 L 229.182 60.781 230.576 59.500 C 231.874 58.307,232.141 57.993,231.857 57.993 C 231.795 57.993,231.168 58.620,230.463 59.387
        M226.374 63.476 L 225.093 64.870 226.487 63.589 C 227.785 62.396,228.052 62.082,227.768 62.082 C 227.706 62.082,227.079 62.709,226.374 63.476
        M222.285 67.565 L 221.004 68.959 222.398 67.678 C 223.695 66.486,223.963 66.171,223.679 66.171 C 223.617 66.171,222.989 66.798,222.285 67.565
        M218.006 71.840 L 216.914 73.048 218.123 71.956 C 218.787 71.356,219.331 70.812,219.331 70.748 C 219.331 70.461,219.024 70.714,218.006 71.840
        M213.735 76.115 L 212.454 77.509 213.848 76.228 C 215.145 75.036,215.413 74.721,215.129 74.721 C 215.067 74.721,214.439 75.349,213.735 76.115
        M209.645 80.204 L 208.364 81.599 209.758 80.317 C 211.056 79.125,211.323 78.810,211.039 78.810 C 210.977 78.810,210.350 79.438,209.645 80.204
        M205.556 84.294 L 204.275 85.688 205.669 84.407 C 206.967 83.214,207.234 82.900,206.950 82.900 C 206.888 82.900,206.261 83.527,205.556 84.294
        M201.467 88.383 L 200.186 89.777 201.580 88.496 C 202.877 87.303,203.145 86.989,202.861 86.989 C 202.799 86.989,202.172 87.616,201.467 88.383
        M197.194 92.658 L 195.725 94.238 197.305 92.768 C 198.774 91.402,199.056 91.078,198.774 91.078 C 198.714 91.078,198.003 91.789,197.194 92.658
        M193.105 96.747 L 191.636 98.327 193.216 96.858 C 194.685 95.491,194.966 95.167,194.685 95.167 C 194.624 95.167,193.913 95.878,193.105 96.747
        M189.016 100.836 L 187.546 102.416 189.126 100.947 C 190.596 99.580,190.877 99.257,190.596 99.257 C 190.535 99.257,189.824 99.967,189.016 100.836
        M184.738 105.112 L 183.457 106.506 184.851 105.224 C 185.618 104.520,186.245 103.893,186.245 103.830 C 186.245 103.547,185.931 103.814,184.738
        105.112 M180.649 109.201 L 179.368 110.595 180.762 109.314 C 181.529 108.609,182.156 107.982,182.156 107.920 C 182.156 107.636,181.842
        107.903,180.649 109.201 M392.165 109.429 C 392.661 109.968,393.141 110.409,393.233 110.409 C 393.600 110.409,392.280 108.766,391.790 108.613 C
        391.501 108.522,391.669 108.889,392.165 109.429 M176.560 113.290 L 175.279 114.684 176.673 113.403 C 177.440 112.698,178.067 112.071,178.067
        112.009 C 178.067 111.725,177.752 111.992,176.560 113.290 M172.281 117.565 L 171.190 118.773 172.398 117.681 C 173.062 117.081,173.606
        116.537,173.606 116.473 C 173.606 116.186,173.299 116.439,172.281 117.565 M168.192 121.654 L 167.100 122.862 168.309 121.771 C 168.973
        121.170,169.517 120.626,169.517 120.562 C 169.517 120.275,169.210 120.528,168.192 121.654 M164.103 125.743 L 163.011 126.952 164.219 125.860 C
        164.884 125.259,165.428 124.716,165.428 124.652 C 165.428 124.364,165.121 124.617,164.103 125.743 M159.831 130.019 L 158.550 131.413 159.944
        130.132 C 160.711 129.427,161.338 128.800,161.338 128.737 C 161.338 128.454,161.024 128.721,159.831 130.019 M155.742 134.108 L 154.461 135.502
        155.855 134.221 C 156.622 133.516,157.249 132.889,157.249 132.827 C 157.249 132.543,156.934 132.810,155.742 134.108 M105.924 151.963 C 96.609
        161.311,88.843 169.210,88.668 169.517 C 88.492 169.823,96.156 162.265,105.699 152.720 C 120.794 137.621,123.440 134.897,122.955 134.955 C
        122.904 134.962,115.240 142.615,105.924 151.963 M151.653 138.197 L 150.372 139.591 151.766 138.310 C 153.063 137.118,153.331 136.803,153.047
        136.803 C 152.985 136.803,152.357 137.430,151.653 138.197 M147.564 142.286 L 146.283 143.680 147.677 142.399 C 148.443 141.695,149.071
        141.067,149.071 141.005 C 149.071 140.721,148.756 140.989,147.564 142.286 M143.291 146.561 L 141.822 148.141 143.401 146.672 C 144.871
        145.305,145.152 144.981,144.871 144.981 C 144.810 144.981,144.099 145.692,143.291 146.561 M139.568 150.279 L 138.476 151.487 139.684 150.395 C
        140.810 149.377,141.063 149.071,140.776 149.071 C 140.712 149.071,140.168 149.614,139.568 150.279 M114.289 175.558 L 113.197 176.766 114.405
        175.674 C 115.070 175.073,115.613 174.530,115.613 174.466 C 115.613 174.179,115.307 174.431,114.289 175.558 M85.110 204.740 L 83.829 206.134
        85.223 204.853 C 86.521 203.660,86.788 203.346,86.504 203.346 C 86.442 203.346,85.815 203.973,85.110 204.740 M81.021 208.829 L 79.740 210.223
        81.134 208.942 C 82.431 207.750,82.699 207.435,82.415 207.435 C 82.353 207.435,81.725 208.062,81.021 208.829 M76.932 212.918 L 75.651 214.312
        77.045 213.031 C 78.342 211.839,78.610 211.524,78.326 211.524 C 78.264 211.524,77.636 212.151,76.932 212.918 M72.842 217.007 L 71.561 218.401
        72.955 217.120 C 74.253 215.928,74.520 215.613,74.236 215.613 C 74.174 215.613,73.547 216.241,72.842 217.007 M68.570 221.283 L 67.100 222.862
        68.680 221.393 C 70.150 220.026,70.431 219.703,70.150 219.703 C 70.089 219.703,69.378 220.414,68.570 221.283 M64.481 225.372 L 63.011 226.952
        64.591 225.482 C 66.060 224.116,66.342 223.792,66.061 223.792 C 66.000 223.792,65.289 224.503,64.481 225.372 M60.391 229.461 L 58.922 231.041
        60.502 229.571 C 61.971 228.205,62.253 227.881,61.971 227.881 C 61.911 227.881,61.200 228.592,60.391 229.461 M56.302 233.550 L 54.833 235.130
        56.413 233.661 C 57.882 232.294,58.163 231.970,57.882 231.970 C 57.821 231.970,57.110 232.681,56.302 233.550 M52.213 237.639 L 50.743 239.219
        52.323 237.750 C 53.793 236.383,54.074 236.059,53.793 236.059 C 53.732 236.059,53.021 236.770,52.213 237.639 M47.752 242.100 L 46.283 243.680
        47.862 242.211 C 49.332 240.844,49.613 240.520,49.332 240.520 C 49.271 240.520,48.560 241.231,47.752 242.100 M43.663 246.190 L 42.193 247.770
        43.773 246.300 C 44.642 245.492,45.353 244.781,45.353 244.720 C 45.353 244.439,45.029 244.720,43.663 246.190 M39.574 250.279 L 38.104 251.859
        39.684 250.389 C 40.553 249.581,41.264 248.870,41.264 248.809 C 41.264 248.528,40.940 248.809,39.574 250.279 M35.484 254.368 L 34.015 255.948
        35.595 254.478 C 36.464 253.670,37.175 252.959,37.175 252.899 C 37.175 252.617,36.851 252.899,35.484 254.368 M31.227 258.550 C 30.324
        259.470,29.669 260.223,29.771 260.223 C 29.873 260.223,30.696 259.470,31.599 258.550 C 32.501 257.630,33.157 256.877,33.054 256.877 C 32.952
        256.877,32.130 257.630,31.227 258.550 M27.008 262.798 C 24.873 265.036,24.616 265.428,25.279 265.428 C 25.483 265.428,25.651 265.224,25.651
        264.975 C 25.651 264.725,26.523 263.638,27.590 262.558 C 28.656 261.478,29.434 260.595,29.319 260.595 C 29.204 260.595,28.164 261.586,27.008
        262.798 M26.208 278.439 C 27.007 279.257,27.729 279.926,27.814 279.926 C 28.127 279.926,25.451 277.013,25.110 276.983 C 24.916 276.966,25.410
        277.621,26.208 278.439 M2.986 285.352 C 3.004 285.861,4.055 287.147,4.274 286.928 C 4.379 286.823,4.130 286.349,3.720 285.876 C 3.310
        285.403,2.979 285.167,2.986 285.352 M124.907 349.425 C 125.214 349.633,125.785 349.805,126.177 349.808 C 127.128 349.816,126.832 349.603,125.465
        349.298 C 124.650 349.115,124.500 349.150,124.907 349.425 M132.156 349.442 C 131.127 349.704,131.113 349.729,131.970 349.764 C 132.481
        349.786,133.151 349.641,133.457 349.442 C 134.090 349.034,133.763 349.034,132.156 349.442 M176.022 360.223 C 175.409 360.629,175.074
        360.959,175.279 360.956 C 175.652 360.950,177.673 359.501,177.323 359.490 C 177.221 359.487,176.636 359.817,176.022 360.223 M126.301 371.638 C
        126.761 371.726,127.514 371.726,127.974 371.638 C 128.434 371.549,128.058 371.477,127.138 371.477 C 126.217 371.477,125.841 371.549,126.301
        371.638 M130.948 371.632 C 131.306 371.725,131.891 371.725,132.249 371.632 C 132.607 371.538,132.314 371.462,131.599 371.462 C 130.883
        371.462,130.590 371.538,130.948 371.632 " stroke="none" fill="currentColor" fill-rule="evenodd"></path></g>
    </svg>`,

    left: `<svg height="512px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
        <polygon points="64.5,256.5 256.5,448.5 256.5,336.5 448.5,336.5 448.5,176.5 256.5,176.5 256.5,64.5 "/></svg>`,
    right: `<svg height="512px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
        <polygon points="448.5,256.5 256.5,64.5 256.5,176.5 64.5,176.5 64.5,336.5 256.5,336.5 256.5,448.5 "/></svg>`,
    up: `<svg height="512px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
        <polygon points="256.5,64.5 64.5,256.5 176.5,256.5 176.5,448.5 336.5,448.5 336.5,256.5 448.5,256.5 "/></svg>`,
    down: `<svg height="512px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
        <polygon points="256.5,448.5 448.5,256.5 336.5,256.5 336.5,64.5 176.5,64.5 176.5,256.5 64.5,256.5 "/></svg>`,

    data_base: `
       <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24">
       <path d="M12.967 21.893c-.703.07-1.377.107-1.959.107-3.412 0-8.008-1.002-8.008-2.614v-2.04c2.117 1.342 5.17 1.78 8.008 1.78.339
       0 .681-.007 1.022-.021-.06-.644-.036-1.28.129-2.019-.408.026-.797.04-1.151.04-3.412 0-8.008-1.001-8.008-2.613v-2.364c2.116 1.341
       5.17 1.78 8.008 1.78 1.021 0 2.068-.06 3.089-.196 1.91-1.766 4.603-2.193 6.903-1.231v-8.14c0-3.362-5.965-4.362-9.992-4.362-4.225
       0-10.008 1.001-10.008 4.361v15.277c0 3.362 6.209 4.362 10.008 4.362 1.081 0
       2.359-.086 3.635-.281-.669-.495-1.239-1.115-1.676-1.826zm-1.959-19.893c3.638 0 7.992.909 7.992 2.361 0 1.581-5.104 2.361-7.992
       2.361-3.412.001-8.008-.905-8.008-2.361 0-1.584 4.812-2.361 8.008-2.361zm-8.008 4.943c2.117 1.342 5.17 1.78 8.008 1.78 2.829 0
       5.876-.438 7.992-1.78v2.372c0 1.753-5.131 2.614-7.992 2.614-3.426-.001-8.008-1.007-8.008-2.615v-2.371zm15.5 7.057c-2.483 0-4.5
       2.015-4.5 4.5s2.017 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.017-4.5-4.5-4.5zm2.5 5h-2v2h-1v-2h-2v-1h2v-2h1v2h2v1z"/>
       </svg>`,

    flow_direction: `
        <svg xmlns="http://www.w3.org/2000/svg"
         width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
         preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="currentColor" stroke="none">
        <path d="M2343 4940 c-831 -81 -1562 -585 -1923 -1325 -117 -238 -188 -465
        -226 -720 -25 -167 -25 -504 0 -670 155 -1020 912 -1814 1916 -2010 761 -148
        1548 87 2109 630 429 415 677 948 723 1554 24 317 -27 678 -138 978 -304 825
        -1039 1422 -1904 1548 -137 19 -428 27 -557 15z m392 -340 c722 -66 1351 -498
        1666 -1145 540 -1109 -22 -2445 -1193 -2836 -973 -326 -2039 123 -2489 1046
        -410 843 -194 1859 525 2462 414 348 961 521 1491 473z"/>
        <path d="M2953 3277 c-386 -216 -707 -396 -712 -402 -14 -15 -792 -1418 -788
        -1422 5 -4 1409 775 1424 790 10 11 793 1415 793 1423 0 2 -3 4 -7 3 -5 0
        -324 -177 -710 -392z m-299 -481 c49 -20 112 -81 135 -130 25 -50 28 -149 7
        -200 -20 -49 -81 -112 -130 -135 -47 -23 -127 -28 -187 -11 -49 14 -122 80
        -148 133 -25 51 -28 150 -7 201 20 48 80 111 129 135 50 25 150 28 201 7z"/>
        </g>
        </svg>`,

    add_ot_param_set: `
        <svg xmlns="http://www.w3.org/2000/svg" width="77" height="77" viewBox="0 0 77 77" fill="none">
        <rect width="77" height="77"/>
        <path d="M42.8578 64.8758C47.179 64.8758 46.0717 62.6976 46.0717 58.7768V49.6282C46.0717 45.2718 44.9914 42.4401 49.7447
        43.0936" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M41.6425 52.7226H50.5549" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M65.1282 54.7311C65.5187 54.3406 65.5187 53.7074 65.1282 53.3169C64.7376
        52.9263 64.1045 52.9263 63.7139 53.3169L62.0599 54.9709L60.4059 53.3169C60.0154 52.9263 59.3822 52.9263 58.9917 53.3169C58.6011
        53.7074 58.6011 54.3406 58.9917 54.7311L60.6457 56.3851L58.9917 58.0391C58.6011 58.4297 58.6011 59.0628 58.9917 59.4533C59.3822
        59.8439 60.0154 59.8439 60.4059 59.4533L62.0599 57.7993L63.7139 59.4533C64.1045 59.8439 64.7376 59.8439 65.1282 59.4533C65.5187
        59.0628 65.5187 58.4297 65.1282 58.0391L63.4741 56.3851L65.1282 54.7311Z" fill="black"/>
        <path d="M58 49C55.5776 50.5638 54 53.1165 54 56C54 58.8835 55.5776 61.4362 58 63" stroke="black" stroke-width="2" stroke-linecap="round"/>
        <path d="M67 49C68.8168 50.5638 70 53.1165 70 56C70 58.8835 68.8168 61.4362 67 63" stroke="black" stroke-width="2" stroke-linecap="round"/>
        <path d="M32.625 13H18.375C17.0633 13 16 14.0074 16 15.25V19.75C16 20.9926 17.0633 22 18.375 22H32.625C33.9367 22 35 20.9926
        35 19.75V15.25C35 14.0074 33.9367 13 32.625 13Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M35.5 17H40.25C41.5098 17 42.718 17.5034 43.6088 18.3994C44.4996 19.2954 45 20.5106 45 21.7778V37.7586M15.0385
        17H11.75C10.4902 17 9.28204 17.5034 8.39124 18.3994C7.50044 19.2954 7 20.5106 7 21.7778V55.2222C7 56.4894 7.50044 57.7046 8.39124
        58.6006C9.28204 59.4966 10.4902 60 11.75 60H35.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`,
    save_ot_param_set: `
        <svg xmlns="http://www.w3.org/2000/svg" width="77" height="77" viewBox="0 0 77 77" fill="none">
        <rect width="77" height="77"/>
        <path d="M23.3866 43.9797H14.8951C14.2597 43.9797 13.433 44.0852 12.9565 43.6087C12.48 43.1322 12.3536 42.0736 12.3536
        41.4383V21.1067C12.3536 20.4713 12.3536 18.5652 12.3536 18.5652C12.3536 18.5652 14.2597 18.5652 14.8951 18.5652H33.0029V25.713V34.4493"
        stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M25.4783 13L8.16425 13C7.62319 13 7.08213 13.3173 6.67633 13.7933C6.27053 14.2693 6 14.9039 6 15.5385L6 31.087"
        stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M36.7081 64.8758C42.0083 64.8758 40.6501 62.204 40.6501 57.3949V46.1735C40.6501 40.83 39.3251 37.3567 45.1553 38.1583"
        stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M35.2174 49.9689H46.1491" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M63.8641 52.2723C64.2546 51.8818 64.2546 51.2486 63.8641 50.8581C63.4736 50.4676
        62.8404 50.4676 62.4499 50.8581L60.2609 53.0471L58.0719 50.8581C57.6813 50.4676 57.0482 50.4676 56.6577 50.8581C56.2671 51.2486
        56.2671 51.8818 56.6577 52.2723L58.8467 54.4613L56.6577 56.6503C56.2671 57.0409 56.2671 57.674 56.6577 58.0645C57.0482 58.4551
        57.6813 58.4551 58.0719 58.0645L60.2609 55.8755L62.4499 58.0645C62.8404 58.4551 63.4736 58.4551 63.8641 58.0645C64.2546 57.674
        64.2546 57.0409 63.8641 56.6503L61.6751 54.4613L63.8641 52.2723Z" fill="black"/>
        <path d="M54.6957 45C52.1679 47.0203 50.5217 50.3182 50.5217 54.0435C50.5217 57.7688 52.1679 61.0666 54.6957 63.087" stroke="black" stroke-width="2" stroke-linecap="round"/>
        <path d="M65.8261 45C68.3538 47.0203 70 50.3182 70 54.0435C70 57.7688 68.3538 61.0666 65.8261 63.087" stroke="black" stroke-width="2" stroke-linecap="round"/>
        </svg>`,
    show_details: `
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.7">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.60042 8.00086V7.99914C1.60238 7.988 1.61353 7.925 1.6759 7.80121C1.74454 7.66493 1.85586 7.49321
        2.01514 7.29557C2.33334 6.90079 2.80928 6.44434 3.41082 6.01153C4.6217 5.14026 6.24982 4.42857 8 4.42857C9.75016 4.42857 11.3783 5.14026 12.5892 6.01153C13.1907
        6.44434 13.6666 6.90079 13.9849 7.29557C14.1442 7.49321 14.2554 7.66493 14.3241 7.80121C14.3865 7.925 14.3976 7.988 14.3996 7.99914V8.00086C14.3976 8.012
        14.3865 8.075 14.3241 8.19879C14.2554 8.33507 14.1442 8.50679 13.9849 8.70443C13.6666 9.09922 13.1907 9.55564 12.5892 9.9885C11.3783 10.8597 9.75016 11.5714 8
        11.5714C6.24982 11.5714 4.6217 10.8597 3.41082 9.9885C2.80928 9.55564 2.33334 9.09922 2.01514 8.70443C1.85586 8.50679 1.74454 8.33507 1.6759 8.19879C1.61353
        8.075 1.60238 8.012 1.60042 8.00086ZM8 3C5.77372 3 3.80184 3.89546 2.40685 4.89919C1.70543 5.40387 1.12697 5.95186 0.716488 6.46111C0.511424 6.7155 0.33984
        6.97071 0.216656 7.21529C0.0987839 7.44929 0 7.72279 0 8C0 8.27721 0.0987839 8.55071 0.216656 8.78471C0.33984 9.02929 0.511424 9.2845 0.716488 9.53886C1.12697
        10.0481 1.70543 10.5961 2.40685 11.1008C3.80184 12.1046 5.77372 13 8 13C10.2262 13 12.1982 12.1046 13.5931 11.1008C14.2946 10.5961 14.873 10.0481 15.2835
         9.53886C15.4886 9.2845 15.6602 9.02929 15.7834 8.78471C15.9012 8.55071 16 8.27721 16 8C16 7.72279 15.9012 7.44929 15.7834 7.21529C15.6602 6.97071 15.4886
         6.7155 15.2835 6.46111C14.873 5.95186 14.2946 5.40387 13.5931 4.89919C12.1982 3.89546 10.2262 3 8 3Z" fill="currentColor"/>
        <circle cx="8" cy="8" r="2" fill="currentColor"/>
        </g>
        </svg>
        `,
    hide_details: `
        <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.90294 3.96589C9.19749 4.16986 8.43361 4.25542 7.66529 4.25391V5.52638C8 5.52694 8.95432 5.52479 9.57304
        5.40969L9.95789 7.29786C10.0312 7.65773 10.3824 7.89 10.7423 7.81665C11.1022 7.74331 11.3344 7.39211 11.2611 7.03224L10.8569 5.04931C11.5483 4.78965 12.1956 4.44006
        12.7884 4.04076L14.3037 5.62475C14.5576 5.89013 14.9786 5.89946 15.244 5.64558C15.5093 5.39169 15.5187 4.97074 15.2648 4.70535L13.8515 3.22809C14.6616 2.53171
        15.3394 1.76271 15.8473 1.05151C16.0607 0.752626 15.9914 0.337308 15.6926 0.123873C15.3937 -0.0895625 14.9784 -0.0202919 14.7649 0.278593C14.2103 1.05533 13.4206
        1.9169 12.4703 2.63431C11.6943 3.22011 10.8254 3.69917 9.90294 3.96589ZM6.09752 3.96592C6.59903 4.11093 7.12322 4.25284 7.66529 4.25391V5.52638C7.24565 5.52567 6.83231
        5.48504 6.42741 5.40972L6.04257 7.29789C5.96922 7.65776 5.61802 7.89003 5.25815 7.81668C4.89828 7.74334 4.66601 7.39214 4.73936 7.03227L5.14352 5.04934C4.45212 4.78968
        3.8048 4.44008 3.21205 4.04079L1.69672 5.62478C1.44284 5.89016 1.02188 5.89949 0.756498 5.64561C0.491111 5.39172 0.481787 4.97077 0.73567 4.70538L2.14891 3.22811C1.33882
        2.53174 0.661044 1.76274 0.15317 1.05154C-0.0602656 0.752656 0.00900501 0.337338 0.30789 0.123903C0.606775 -0.0895325 1.02209 -0.0202619 1.23553 0.278623C1.7902 1.05536
        2.57988 1.91693 3.53018 2.63434C4.30614 3.22014 5.17502 3.6992 6.09752 3.96592Z" fill="currentColor"/>
        </svg>
        `,
    progress_bar_logo_left:`
        <svg width="39" height="90" viewBox="0 0 39 90" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 45.0029C0 67.8388 16.9136 86.788 38.8672 90V76.5695C24.243 73.5132 13.2229 60.5209 13.2229 45.0029C13.2229 29.4848 24.243 16.4926 38.8672 13.4363V0C16.9078 3.21202 0 22.1612 0 44.9971" fill="currentColor"/>
        </svg>
        `,
    progress_bar_logo_right:`
        <svg width="50" height="90" viewBox="0 0 50 90" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M49.3509 44.9971C49.3509 22.1612 32.4374 3.21202 10.478 0V13.4247C25.1022 16.4811 36.1222 29.4733 36.1222 44.9971C36.1222 52.0959 33.7983 58.6525 29.8943 63.9867L16.2792 45.0029H0L19.9987 72.8904C17.075 74.5858 13.8803 75.8602 10.4722 76.5695V90C16.729 89.0831 22.5764 86.886 27.7376 83.6913L31.953 89.5733H48.2322L37.8868 75.1451C45.0086 67.118 49.3452 56.5592 49.3452 45.0086" fill="currentColor"/>
        </svg>
        `,
    error: `
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" rx="8" fill="#EC3900"/>
        <path d="M10.2426 6L6 10.2426" stroke="white" stroke-width="1.33" stroke-linecap="round"/>
        <path d="M10.2426 10.2427L6.00001 6.00003" stroke="white" stroke-width="1.33" stroke-linecap="round"/>
        </svg>`,
    info: `
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" rx="8" fill="#09658E"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.165 4C8.53227 4 8.83 4.29773 8.83 4.665V9.665C8.83 10.0323 8.53227 10.33 8.165 10.33C7.79773 10.33 7.5 10.0323 7.5 9.665V4.665C7.5 4.29773 7.79773 4 8.165 4Z" fill="white"/>
        <circle cx="8.165" cy="11.665" r="0.665" fill="white"/>
        </svg>`,
    done: `
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" rx="8" fill="#098E4F"/>
        <path d="M5 8.00325L7.33131 10.5L11.5 6" stroke="white" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`,
    done_35: `
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="35" height="35" rx="17.5" fill="#13B737"/>
        <path d="M10.9375 17.0202L16.4295 21.875L26.25 13.125" stroke="white" stroke-width="2.90938" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`,
    checked: `
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_241_412)">
        <path d="M13.3332 1L5.99984 8.33333L2.6665 5" stroke="black" stroke-width="1.40" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"/>
        </g>
        <defs>
        <filter id="filter0_d_241_412" x="-1.99854" y="0.335022" width="19.9966" height="16.6633" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_241_412"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_241_412" result="shape"/>
        </filter>
        </defs>
        </svg>
        `,
    transparent_box: `<svg id="transparentBox" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M1 15L15 1" stroke="#ED8C8C"/>
        </svg>`,
    stripes_filter: `<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 2.5H15" stroke="#003A54" stroke-width="1.33" stroke-linecap="round"/>
        <path d="M3 7.5H13" stroke="#003A54" stroke-width="1.33" stroke-linecap="round"/>
        <path d="M6 12.5H10" stroke="#003A54" stroke-width="1.33" stroke-linecap="round"/>
        </svg>
        `,
    check_active: `<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.112 5.88281L6.77865 13.2161L3.44531 9.88281" stroke="#003A54" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`
};
