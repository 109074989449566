import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { ResetPassword, UserStorage, UserDataOld, UserEmail, User, UserForm } from '@app/modules/core/interfaces/user';
import { LoginForm } from '@app/modules/core/interfaces/login-form';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ProjectPermissions } from '@app/modules/dashboard/interfaces/project';

@Injectable()
export class UserService {
    private api = environment.api + 'users/';
    private version = environment.api + 'version/getServerVersion';
    private user: UserStorage;

    constructor(private http: HttpClient, private router: Router) {
        this.user = this.getUserFromStorage();
    }

    public get isAuthenticated(): boolean {
        return !!(this.user && this.user.auth_token);
    }

    public get token(): string {
        return this.user && this.user.access_token;
    }

    public get username(): string {
        return this.user ? this.user.username : '';
    }

    // userStorage
    public get userId(): string {
        return this.user.userId;
    }

    public get roleUser(): number {
        return Number(this.user.roleId);
    }

    public setUserName(name: string) {
        const user = JSON.parse(localStorage.getItem('user'));
        user['username'] = name;
        this.saveUserToStorage(user);
    }

    register(user: UserForm): Observable<any> {
        return this.http.post<any>(this.api + 'registration', user);
    }

    login(credentials: LoginForm): Observable<any> {
        sessionStorage.removeItem('projectId');
        return this.http.post<UserStorage>(this.api + 'login', credentials).pipe(
            map((data: UserStorage) => {
                this.saveUserToStorage(data);
            }),
            catchError(err => {
                return throwError(err);
            })
        );
    }

    logout() {
        this.user = null;
        localStorage.removeItem('user');
        this.router.navigate(['login']);
        sessionStorage.removeItem('projectId');
    }

    saveUserToStorage(user) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('saveHMI', 'false');
    }

    getUserFromStorage() {
        return JSON.parse(localStorage.getItem('user'));
    }

    getAuthTokenFromStorage() {
        return JSON.parse(localStorage.getItem('user'))?.auth_token;
    }

    getAllUsers(): Observable<User[]> {
        return this.http.get<User[]>(this.api).pipe(map(data => data));
    }

    getSingleUser(id?: string): Observable<User> {
        const userId = id || this.userId;
        return this.http.get<User>(this.api + userId).pipe(map(data => data));
    }

    updateUser(user: User) {
        return this.http.put<User>(this.api + 'update', user);
    }

    updateUserEmail(user: UserEmail) {
        return this.http.post<UserEmail>(this.api + 'updateEmail', user);
    }

    deleteUser(id: string) {
        return this.http.delete<UserDataOld>(this.api + id);
    }

    // addProjectPermission
    putProjectPermition(idUser: string, idProject: string, idPermission: number): Observable<any> {
        let body = {
            userId: idUser,
            projectId: idProject,
            permissionId: idPermission,
        };
        return this.http.post<any>(this.api + 'addProjectPermission/', body);
    }

    getUserProject(userId?: string): Observable<any[]> {
        const user = userId || this.userId;
        sessionStorage.removeItem('projectId');
        return this.http.get<any[]>(this.api + 'getUserProjectPermissions/' + user).pipe(map(data => data));
    }

    getProjectPermissions(idProject: string): Observable<ProjectPermissions[]> {
        return this.http
            .get<ProjectPermissions[]>(this.api + 'getProjectPermissions/' + idProject)
            .pipe(map(data => data));
    }

    deleteProjectPermission(id: string) {
        return this.http.delete<any>(this.api + 'deleteProjectPermission/' + id);
    }

    getUserPassword(email: string): Observable<any[]> {
        return this.http.get<any[]>(this.api + 'getUserPassword/' + email).pipe(map(data => data));
    }

    updateUserPassword(user: ResetPassword) {
        sessionStorage.removeItem('projectId');
        return this.http.post<ResetPassword>(this.api + 'updatePassword', user);
    }

    getUIVersionData() {
        return this.http.get(`${window.location.origin}/assets/uiVersion/uiVersion.json`, { responseType: 'text' });
    }

    getServerVersionData() {
        return this.http.get(this.version, { responseType: 'text' });
    }
}
