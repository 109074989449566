import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { UserService } from '@app/modules/core/services/user.service';
import { NotificationService } from '@app/modules/core/services/notification.service';

@NgModule({
    imports: [],
    exports: [
        BrowserAnimationsModule,
        HttpClientModule,
    ],
    declarations: [],
    providers: [
        UserService,
        NotificationService
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(`Core module has already been loaded. Import Core modules in the AppModule only.`);
        }
    }
}
