export const SVG_GRAPHIC_PROPERTY_PANEL_ICONS = {
    line_width: { url: 'assets/image/icons/graphic-side-panel/properties-panel/stroke-property/border-width.svg', name: 'line-width' },
    arrow_down: { url: 'assets/image/icons/graphic-side-panel/properties-panel/arrow-down.svg', name: 'properties_arrow-down' },
    arrow_up: { url: 'assets/image/icons/graphic-side-panel/properties-panel/arrow-up.svg', name: 'properties_arrow-up'},
    arrow_box_down: { url: 'assets/image/icons/graphic-side-panel/properties-panel/line-direction-property/down-direction.svg', name: 'arrow-box-down' },
    arrow_box_up: { url: 'assets/image/icons/graphic-side-panel/properties-panel/line-direction-property/up-direction.svg', name: 'arrow-box-up' },
    arrow_box_left: { url: 'assets/image/icons/graphic-side-panel/properties-panel/line-direction-property/left-direction.svg', name: 'arrow-box-left' },
    arrow_box_right: { url: 'assets/image/icons/graphic-side-panel/properties-panel/line-direction-property/right-direction.svg', name: 'arrow-box-right' },
    arrow_box_bi: { url: 'assets/image/icons/graphic-side-panel/properties-panel/line-direction-property/bi-direction.svg', name: 'arrow-box-bi' },
    bold_font: { url: 'assets/image/icons/graphic-side-panel/properties-panel/font-property/bold-icon.svg', name: 'bold' },
    italic_font: { url: 'assets/image/icons/graphic-side-panel/properties-panel/font-property/italic-icon.svg', name: 'italic' },
    underline_font: { url: 'assets/image/icons/graphic-side-panel/properties-panel/font-property/underline-icon.svg', name: 'underline' },
    reset_font: { url: 'assets/image/icons/graphic-side-panel/properties-panel/font-property/reset-icon.svg', name: 'reset' },
    rotation: { url: 'assets/image/icons/graphic-side-panel/properties-panel/rotation.svg', name: 'properties_rotation' },
    link: { url: 'assets/image/icons/graphic-side-panel/properties-panel/linked.svg', name: 'properties_link' },
    unlink: { url: 'assets/image/icons/graphic-side-panel/properties-panel/unlinked.svg', name: 'properties_unlink' },
}