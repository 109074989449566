import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, take, tap } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import {
    FeatureFlag,
    FeatureFlagHide,
    FeatureFlagState,
} from '@app/modules/shared/feature-flag/feature-flag.interface';

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagService {
    private api = environment.api + 'features';
    private projectId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private featureFlags$: BehaviorSubject<FeatureFlag[]> = new BehaviorSubject<FeatureFlag[]>(null);
    featureFlagsData$: Observable<FeatureFlag[]> = this.featureFlags$.pipe(filter(Boolean));

    constructor(private http: HttpClient) {}

    getFeatureFlags(projectId: string = null): Observable<FeatureFlag[]> {
        if (projectId === this.projectId$.value && this.featureFlags$.value !== null) {
            return this.featureFlagsData$;
        }

        return this.http.get<FeatureFlag[]>(this.api).pipe(
            tap((result: FeatureFlag[]) => {
                this.featureFlags$.next(result);
                this.projectId$.next(projectId);

                return this.featureFlagsData$;
            }),
            catchError(err => {
                return of(err);
            }),
            take(1)
        );
    }

    isFeatureFlagEnabled(flag: string): boolean {
        const currentFlag: FeatureFlag = this.featureFlags$.value?.filter(
            (featureFlag: FeatureFlag): boolean => featureFlag.name === flag
        )[0];

        return currentFlag?.isEnabled ?? true;
    }

    featureFlagState(flag: string, role = '', isDisabledInComponent = false): FeatureFlagState {
        let state: FeatureFlagState = FeatureFlagState.show;
        const currentFlag: FeatureFlag = this.featureFlags$.value?.find(
            (featureFlag: FeatureFlag): boolean => featureFlag.name === flag
        );
        const isShowByRole: boolean =
            role !== ''
                ? this.featureFlags$.value?.filter((featureFlag: FeatureFlag): boolean => featureFlag.name === role)[0]
                      ?.isEnabled
                : true;

        if (currentFlag) {
            if (
                (!currentFlag.isEnabled && !isShowByRole) ||
                (FeatureFlagHide.includes(currentFlag.name) && !currentFlag.isEnabled)
            ) {
                state = FeatureFlagState.hide;
            } else if ((!currentFlag.isEnabled && isShowByRole) || isDisabledInComponent) {
                state = FeatureFlagState.disabled;
            } else {
                state = FeatureFlagState.show;
            }
        }

        return state;
    }
}
